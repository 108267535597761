export const targetMap = {
  'knob-tl': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    let newElementX = Math.round(translation.x + position.x);
    let newElementY = Math.round(translation.y + position.y);
    let newWidth = Math.round(width - translation.x);
    let newHeight = Math.round(height - translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || shiftkey;
    if (isOnlyHomotheticResize) {
      if (translation.x < translation.y) {
        const ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
        newElementY = Math.trunc(position.y - (ratio - 1) * height);
      } else {
        const ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
        newElementX = Math.trunc(position.x - (ratio - 1) * width);
      }
    }

    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-tr': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    const newElementX = position.x;
    let newElementY = Math.round(translation.y + position.y);
    let newWidth = Math.round(width + translation.x);
    let newHeight = Math.round(height - translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || (!onlyHomotheticResize && shiftkey);
    if (isOnlyHomotheticResize) {
      if (translation.x > translation.y) {
        const ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
        newElementY = Math.trunc(position.y - (ratio - 1) * height);
      } else {
        const ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
      }
    }
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-bl': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    let newElementX = Math.round(translation.x + position.x);
    const newElementY = position.y;
    let newWidth = Math.round(width - translation.x);
    let newHeight = Math.round(height + translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || (!onlyHomotheticResize && shiftkey);
    if (isOnlyHomotheticResize) {
      if (translation.x < translation.y) {
        const ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
      } else {
        const ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
        newElementX = Math.trunc(position.x - (ratio - 1) * width);
      }
    }
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-br': (translation, position, height, width, onlyHomotheticResize) => (shiftkey) => {
    const newElementX = position.x;
    const newElementY = position.y;
    let newWidth = Math.round(width + translation.x);
    let newHeight = Math.round(height + translation.y);

    const isOnlyHomotheticResize = onlyHomotheticResize || (!onlyHomotheticResize && shiftkey);
    if (isOnlyHomotheticResize) {
      if (translation.x > translation.y) {
        const ratio = newWidth / width;
        newHeight = Math.trunc(height * ratio);
      } else {
        const ratio = newHeight / height;
        newWidth = Math.trunc(width * ratio);
      }
    }
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-tm': (translation, position, height, width) => () => {
    const newElementX = position.x;
    const newElementY = Math.round(translation.y + position.y);
    const newWidth = width;
    const newHeight = Math.round(height - translation.y);
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-lm': (translation, position, height, width) => () => {
    const newElementX = Math.round(translation.x + position.x);
    const newElementY = position.y;
    const newWidth = Math.round(width - translation.x);
    const newHeight = height;
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-bm': (translation, position, height, width) => () => {
    const newElementX = position.x;
    const newElementY = position.y;
    const newWidth = width;
    const newHeight = Math.round(height + translation.y);
    return { newElementX, newElementY, newWidth, newHeight };
  },
  'knob-rm': (translation, position, height, width) => () => {
    const newElementX = position.x;
    const newElementY = position.y;
    const newWidth = Math.round(width + translation.x);
    const newHeight = height;
    return { newElementX, newElementY, newWidth, newHeight };
  }
};
