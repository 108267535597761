<template>
  <iob-sidebar
    size="medium"
    :elevation="3"
    class="BoardSettings-container"
  >
    <iob-sidebar-header>
      <div class="BoardSettings-header">
        <h1 class="BoardSettings-header-title">
          Board Settings
        </h1>
        <iob-action-button
          icon="close"
          size="default"
          color="secondary"
          type="ghost"
          @click="closeSettings"
        />
      </div>
    </iob-sidebar-header>
    <iob-sidebar-content
      class="BoardSettings-content"
      style="overflow: auto;"
    >
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-name">Name</label>
          <span class="form-group-label-characterCount">
            {{ formData.name?.length || 0 }}
          </span>
        </div>
        <iob-label-field
          :model-value="formData.name"
          placeholder="Board Name"
          has-clear-button
          @update:modelValue="(value) => handleFormChange('name', value.trim())"
        />
      </div>
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-level">Level</label>
        </div>
        <iob-input-dropdown
          class="form-group-dropdown"
          type="badge"
          :status="getLevelName(formData.level)"
          :items="levelsList"
          @dropdown-element-click="(value) => handleFormChange('level', value.id)"
        />
      </div>
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-bgImage">Board Background</label>
        </div>
        <div class="form-background-image">
          <iob-tabs
            :tabs="backgroundTabs"
            :selected-tab="selectedTab"
            @tab-select="(index) => selectedTab = index"
          >
            <template #color>
              Board Colors will be displayed here
            </template>
            <template #image>
              <div
                v-if="bgImage.url"
                class="imagePreview"
                :style="{ backgroundImage: `url(${bgImage.url})` }"
              />
              <iob-image-upload
                :value="bgImage"
                :can-edit-file="!!bgImage.name"
                @change="(event) => loadImage(event.target.files[0])"
                @reset="removeBackgroundImage"
              />
            </template>
          </iob-tabs>
        </div>
      </div>
    </iob-sidebar-content>
    <iob-sidebar-footer class="BoardSettings-footer">
      <div class="BoardSettings-footer-buttons">
        <iob-button
          type="filled"
          color="alert"
          label="Delete Board"
          show-left-icon
          left-icon="Trash"
          @click="deleteBoard"
        />
      </div>
    </iob-sidebar-footer>
  </iob-sidebar>
</template>

<script setup>
import { computed, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import IobImageUpload from 'SRC/components/IobImageUpload/IobImageUpload.vue';
import IobTabs from 'SRC/components/IobTabs/IobTabs.vue';

const store = useStore();
const boardData = computed(() => store.state.board?.boardData);
const bgImage = computed(() => store.state.board?.backgroundImage);
const levels = computed(() => store.state.app.levels);
const updateBoard = (value) => store.dispatch('board/updateBoard', value);
const uploadBackgroundImage = (value) => store.dispatch('board/uploadBackgroundImage', value);
const removeBackgroundImage = () => store.dispatch('board/removeBackgroundImage');
const closeComponent = (value) => store.dispatch('board/closeComponent', value);
const deleteBoard = () => store.dispatch('board/deleteBoard');
const getLevelName = (id) => store.getters['app/getLevelName'](id);
const levelsList = computed(() => levels.value.map((level) => ({text: level.attributes?.['level-name'], id: level.id}) || []));

const formData = reactive({
  name: boardData.value?.name,
  level: boardData.value?.level
});

const backgroundTabs = [
  { label: 'Color', icon: 'Droplets' },
  { label: 'Image', icon: 'Image' }
];

const saveTimeout = ref(null);
const selectedTab = ref(1);

watchEffect(() => {
  formData.name = boardData.value?.name;
  formData.level = boardData.value?.level;
});

function closeSettings() {
  closeComponent('BoardSettings');
}

async function loadImage(imageFile) {
  await uploadBackgroundImage({
    image: imageFile
  });
  const backgroundImageId = bgImage.value?.id;
  const payload = {...boardData.value, backgroundImageId};
  updateBoard(payload);
}

function validateForm() {
  return formData.name.length > 0 && formData.name.length <= 300;
}

function handleFormChange(key, value) {
  formData[key] = value;
  if (saveTimeout.value) {
    clearTimeout(saveTimeout.value);
  }
  saveTimeout.value = setTimeout(() => {
    if (validateForm()) {
      const payload = {...boardData.value, ...formData, displayLinks: false};
      updateBoard(payload);
    }
  }, 500);
}
</script>

<style lang="scss" scoped>
.BoardSettings {
  padding: 24px;
    &-container {
        z-index: 1;
    }
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 24px 0 24px;

        &-title {
            padding: 8px 0;
            margin-right: auto;
            color: var(--gray-900);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: -0.16px;
        }
    }

    &-content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &-footer {
        &-buttons {
            padding: 32px;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    color: var(--gray-900, #242230);
    /* text-s/font-semibold */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */

    &-label {
        &-characterCount {
            float: right;
            color: #B1AEC6;
            text-align: right;
            /* text-xs/font-regular */
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 166.667% */
            letter-spacing: 0.14px;
        }
    }
}
.form-group-dropdown {
    .IobInputDropdown--dropdown {
      width: 100%;
    }
}

.imagePreview {
  border-radius: var(--app-form-group-upload-radius-default, 8px);
  border: 1px solid var(--gray-200, #CCCBD6);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: var(--illustration-cover-size-default-height, 200px);
}

</style>
<style>
.BoardSettings-content .form-group .IobInputDropdown--dropdown {
  max-height: 500px;
  &::-webkit-scrollbar {
    display: none;
  }
}

</style>
