<template>
  <div
    class="IobBadge"
    :class="classList"
    :style="badgeStyle"
    :tabindex="!props.disabled && props.selectable ? 0 : undefined"
    :role="props.selectable ? 'button' : undefined"
    @click="handleClick"
  >
    <div class="IobBadge-content">
      <icon-loader
        v-if="icon && showIcon"
        class="IobBadge-icon"
        :size="iconSize"
        :name="icon"
      />
      <span
        v-if="label"
        class="IobBadge-content-label"
        :style="labelStyle"
      >
        {{ label }}
      </span>
    </div>
    <div
      v-if="showCloseButton"
      class="IobBadge-close"
      @mouseover.stop="() => unhover = true"
      @mouseleave.stop="() => unhover = false"
    >
      <iob-action-button
        :size="iconSize"
        :color="color"
        class="IobBadge-icon"
        :type="type"
        :rounded="rounded"
        name="x"
        @click.stop="() => emit('close')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, ref, watch } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobActionButton from '../../Molecules/IobActionButton/IobActionButton.vue';

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'alert', 'warning', 'success', 'info', 'other'].includes(value)
  },
  type: {
    type: String,
    default: 'soft',
    validator: (value) => ['soft', 'outlined'].includes(value)
  },
  size: {
    type: String,
    default: 'default',
    validator: (value) => ['xs', 's', 'default'].includes(value)
  },
  rounded: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: ''
  },
  showIcon: {
    type: Boolean,
    default: true
  },
  selectable: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showCloseButton: {
    type: Boolean,
    default: false
  },
  labelStyle: {
    type: String,
    default: ''
  },
  customHeight: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['click', 'close']);

const unhover = ref(false);
const selectedState = ref(props.selected && props.selectable && !props.disabled);

const handleClick = () => {
  if (props.selectable && !props.disabled) {
    emit('click');
    selectedState.value = !selectedState.value;
  }
}

watch(() => props.selected, (value) => {
  selectedState.value = value;
});

const badgeStyle = computed(() => {
  if (props.customHeight) {
    return `height: ${props.customHeight}px; `;
  }
  return '';
});

const classList = computed(() => {
  return [
    `IobBadge--${props.color}`,
    `IobBadge--${props.type}`,
    `IobBadge--${props.size}`,
    {
      'IobBadge--rounded': props.rounded,
      'IobBadge--with-icons': props.showIcon,
      'IobBadge--noSelect': !props.selectable,
      'IobBadge--selected': selectedState.value,
      'IobBadge--unhover': !unhover.value && props.selectable && !props.disabled,
      'IobBadge--disabled': props.disabled,
      'IobBadge--noPadding': props.label === '',
      'focus-outside': !props.disabled && props.selectable
    }
  ]
});

const iconSize = computed(() => {
  return {
    'xs': 'xsmall',
    's': 'small',
    'default': 'default'
  }[props.size] || 'default'
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-badge.scss" />
<style lang="scss" src="./IobBadge.scss" scoped />
