import { INTERACTION_MODES } from 'GLOBALS/constants.js';
import { undo, redo } from 'SRC/utils/collab';

export const switchToPanMode = async (context, event = {}) => {
  if (context.isQuickPanning === false && context.selectedInteractionMode !== INTERACTION_MODES.PANNING) {
    context.setPreviousMode(context.selectedInteractionMode);
    context.setPreviousCursor(document.body.style.cursor);
    context.setCursor({value: 'grab'});
    await context.setSelectedInteractionMode({value:
      INTERACTION_MODES.PANNING,
    isQuickPanning: false
    });
    context.send('pressOnBoard', {board: context, event});
    context.isQuickPanning = true;
  }
};
const switchBackToSelect = async (context) => {
  if (
    context.selectedInteractionMode === INTERACTION_MODES.PANNING
    && context.isQuickPanning
  ) {
    await context.unlockCursor();
    context.setCursor({ value: context.previousCursor, lock: false });
    context.isQuickPanning = false;
    context.setSelectedInteractionMode({
      value: context.previousMode,
      isQuickPanning: true
    });
  }
};
const rightMiddleMouseClick = async (context) => {
  if (!context.isQuickPanning && context.selectedInteractionMode !== INTERACTION_MODES.PANNING) {
    context.setPreviousMode(context.selectedInteractionMode);
    context.setPreviousCursor(document.body.style.cursor);
    await context.setSelectedInteractionMode({
      value: INTERACTION_MODES.PANNING,
      isQuickPanning: false
    });
    context.isQuickPanning = true;
  }
  context.send('mouseDownPress', { board: context, event });
};
const selectAll = (context, event) => {
  if (event) {
    event.preventDefault();
  }
  context.send('selectAll', {board: context});
};
const escape = (context) => {
  const svg = document.getElementById('mouseFollower');
  if (svg) {
    context.resetLink();
    svg.remove();
  } else {
    context.closeEditor();
  }
  context.send('escape', { board: context, event });
};

const hideContextualMenu = (context) => {
  context.hideContextualMenu();
};
const showContextualMenu = (context) => {
  context.showContextualMenu();
};

export const keyDownActions = {
  ' ': switchToPanMode,
  rightMouse: rightMiddleMouseClick,
  'ctrl+a': selectAll,
  middleClick: rightMiddleMouseClick,
  'ctrl+z': undo,
  'ctrl+shift+Z': redo,
  'Shift': hideContextualMenu,
  'Control': hideContextualMenu
};

export const keyUpActions = {
  ' ': switchBackToSelect,
  'ctrl+a': selectAll,
  rightMouse: switchBackToSelect,
  middleClick: switchBackToSelect,
  Escape: escape,
  'Shift': showContextualMenu,
  'Control': showContextualMenu
};

export const rollbackActions = {
  ' ': () => { },
  'ctrl+a': selectAll,
  rightMouse: () => { },
  middleClick: () => { },
  'ctrl+z': () => { },
  'ctrl+shift+Z': () => { },
  Escape: escape,
  'Shift': showContextualMenu,
  'Control': showContextualMenu
};

