import { VERSION_DATETIME_OPTIONS, VERSION_CATEGORIES, MINIMUM_HOURS,
  MAXIMUM_DAYS_UNMARKED_VERSION, UNKNOWN_USER, LINKS_TYPES } from 'GLOBALS/constants.js';
import usersGetters from 'SRC/store/users/getters';
import UsersModule from 'SRC/store/users/index';
import boardGetters from './getters';

const utils = {
  formatVersion: (version, selected, today, creationDate) => {
    version.category = creationDate === today ? VERSION_CATEGORIES[0] : VERSION_CATEGORIES[1];
    const shortDateOptions = { month: 'short', year: 'numeric' };
    version.dateTime = new Intl.DateTimeFormat('en-EU', VERSION_DATETIME_OPTIONS).format(new Date(version.creationTimestamp));
    version.date = new Intl.DateTimeFormat('en-EU', shortDateOptions).format(new Date(version.creationTimestamp));
    version.isSelected = selected;
    version.isMarked = version.marked;
    version.daysLeft =
      utils.computeHoursPassed(version) > MINIMUM_HOURS && utils.computeDaysLeft(version) > 0 ? utils.computeDaysLeft(version) : null;
    version.numberOfDaysSinceCreation = utils.computeDaysSinceCreation(version);
  },
  computeDaysLeft: (version) => {
    const now = new Date();
    const creationDate = new Date(version.creationTimestamp);
    const msBetweenDates = Math.abs(creationDate.getTime() - now.getTime());
    return MAXIMUM_DAYS_UNMARKED_VERSION - Math.ceil(msBetweenDates / (1000 * 3600 * MINIMUM_HOURS)) + 1;
  },
  computeHoursPassed: (version) => {
    const now = new Date();
    const creationDate = new Date(version.creationTimestamp);
    return Math.abs(creationDate.getTime() - now.getTime()) / (60 * 60 * 1000);
  },
  computeDaysSinceCreation: (version) => {
    const now = new Date();
    const creationDate = new Date(version.creationTimestamp);
    const msBetweenDates = Math.abs(creationDate.getTime() - now.getTime());
    return Math.ceil(msBetweenDates / (1000 * 3600 * MINIMUM_HOURS));
  },
  addUserToHistoryData(history) {
    history.forEach((element) => {
      const user = usersGetters.getUserById(UsersModule.state)(element.userId);
      element.user = user || UNKNOWN_USER;
    });
    return history;
  },
  addUserPictureCheck(history) {
    for (let i = 1; i < history.length; i++) {
      if (history[i].user.id && history[i].user.id === history[i - 1].user.id) {
        history[i].displayPicture = false;
      }
    }
    return history;
  },
  fillDataObject(state, boardElementsIds, userObject, currentUserId) {
    const data = {};
    boardElementsIds.forEach((id) => {
      const isBoardElementSelected = !!state.boardElementsBeingSelected[id];
      if (!isBoardElementSelected) {
        data[id] = [userObject];
      } else {
        const isSelectedByThisUser = state.boardElementsBeingSelected[id].find((e) => e.userId === currentUserId);
        if (isSelectedByThisUser) {
          data[id] = state.boardElementsBeingSelected[id];
        } else {
          data[id] = [...state.boardElementsBeingSelected[id], userObject];
        }
      }
    });
    return data;
  },
  getWarningMessage(state, error, relationKind, sourceElement, targetElement) {
    if (relationKind === LINKS_TYPES.DependencyType) {
      return error.status === 500 ? error.detail : 'Relation error';
    }
    const sourceElementAttributes = boardGetters.getFullAttributes(state)(sourceElement.datasetElement.id);
    const targetElementAttributes = boardGetters.getFullAttributes(state)(targetElement.datasetElement.id);
    if (sourceElementAttributes && targetElementAttributes) {
      return `${sourceElementAttributes['friendly-id']} cannot be defined as the parent or child of ${targetElementAttributes['friendly-id']}`;
    }
  }
};

export default utils;
