<template>
  <div
    class="IobSidebar level-container"
    :class="classList"
  >
    <slot />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'small'
  },
  elevation: {
    type: [Number, String],
    default: 0
  }
})

const classList = computed(() => {
  return [
    {
      'IobSidebar--small': props.size === 'small',
      'IobSidebar--medium': props.size === 'medium',
      'IobSidebar--large': props.size === 'large'
    },
    `level-${props.elevation}`
  ]
})
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-sidebar.scss" />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-sidebar.scss" />
<style lang="scss" src="./IobSidebar.scss" scoped />
