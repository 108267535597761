<template>
  <div>
    <iob-editor
      v-if="datasetElement"
      :id="datasetElementId"
      :dataset-element="datasetElement"
      :data-type-id="dataType.id"
      :data-type-name="dataType.name"
      :eligible-relations-datatypes="eligibleRelationsDatatypes"
      :selected-data-type-types="selectedDataTypeTypes"
      :friendly-id="friendlyId"
      :type="datasetElement.attributes.type"
      :owner="getOwner(datasetElement.attributes.owner)"
      :users="allUsers"
      :list-attributes="listAttributes"
      :main-panel-attributes="getMainPanelAttributes"
      :central-attributes-panel="getCentralPanelAttributes"
      :data-type-attributes="dataType.attributes"
      :selected-type="selectedType"
      :parent="getParent"
      :children="getChildren"
      :dependencies="getDependencies"
      :eligible-children="getEligibleChildren"
      :eligible-parents="getEligibleParents"
      :eligible-dependencies="getEligibleDependencies"
      :eligible-child-data-types="getEligibleChildDataTypes"
      :eligible-parent-data-types="getEligibleParentDataTypes"
      :relation-config-attributes="relationConfigAttributes"
      :has-summary="hasSummary"
      :summary-config="getSummaryConfig"
      :levels="levelsTree"
      :previous-dataset-element="getPreviousDatasetElement"
      @closeEditor="closeEditorWrapper"
      @handleAttributeChange="
        ({ attribute, value }) => handleAttributesChange({ attribute, value })
      "
      @onClickTypeOption="(data) => fetchEligibleDatasetElements(data)"
      @onClickAddRelations="(data) => fetchEligibleDatasetElements(data)"
      @onClickCreateAsNewTypeName="
        (data) => createRelationWithNewDatasetElement(data)
      "
      @onClickAddRelationWithExistingDatasetElement="
        (data) => createRelationWithExistingDatasetElement(data)
      "
      @onClickDetailsMenu="executeAction"
      @search="(data) => handleSearch(data)"
      @onClickDetailsItem="openNewElementDetails"
      @onClickPreviousElement="goBackToPreviousElementDetails"
    />
    <div
      v-if="isDialogOpened"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        title="Your element won’t be saved"
        content="We won’t save your element if you don’t specify a title"
        submit-action="Discard"
        cancel-action="Go back"
        @submitAction="discardElement"
        @cancelAction="closeDialogBox"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { RELATION_TYPES } from 'GLOBALS/constants.js';
import { debounce } from 'SRC/modals/modals-utils.js';

export default {
  name: 'BoardElementEditor',
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'valueChanging', 'valueChange'],
  data() {
    return {
      selectedRelation: null,
      selectedType: null,
      selectedDataTypeName: '',
      isDialogOpened: false,
      currentSelectorInputValue: '',
      currentMultipleSelectvalue: '',
      previousTitle: '',
      debouncedSearch: null,
      previousDatasetElement: null
    };
  },
  computed: {
    ...mapState('editor', [
      'dataType',
      'datasetElementId',
      'hierarchicalCount',
      'previousDatasetElementIds'
    ]),
    ...mapState('app', ['dataTypes', 'levelsTree']),
    ...mapState('users', ['users', 'currentUser']),
    ...mapState('dock', ['isCreating']),
    ...mapState('board', [
      'datasetElements',
      'boardElementsInEdition',
      'dialogBoxFlags',
      'selectedDatasetElement'
    ]),
    ...mapState('hierarchy', [
      'eligibleParentDataTypes',
      'eligibleChildDataTypes',
      'eligibleParents',
      'eligibleChildren',
      'eligibleDependencies',
      'parent',
      'children',
      'dependencies'
    ]),
    ...mapGetters('app', ['getTitleAttribute', 'getDataTypeByName']),
    ...mapGetters('board', ['getDatasetElementById']),
    selectedDataTypeTypes() {
      const dataTypeName = this.selectedDataTypeName;
      const dataTypeAttributes =
        this.checkIfDatatypeHasTypeAttribute(dataTypeName);
      return dataTypeAttributes && dataTypeAttributes.enum && dataTypeName
        ? dataTypeAttributes.enum
        : [];
    },
    getPreviousDatasetElement() {
      const id = this.previousDatasetElementIds.length ? this.previousDatasetElementIds[this.previousDatasetElementIds.length - 1] : null;
      return id ? this.getDatasetElementById(id) : null;
    },
    relationConfigAttributes() {
      return this.dataType.editorConfig.relationConfig.attributes;
    },
    getParent() {
      return this.parent;
    },
    getChildren() {
      return this.children;
    },
    getDependencies() {
      return this.dependencies;
    },
    getEligibleChildren() {
      return this.eligibleChildren;
    },
    getEligibleParents() {
      return this.eligibleParents;
    },
    getEligibleDependencies() {
      return this.eligibleDependencies;
    },
    getEligibleChildDataTypes() {
      return this.eligibleChildDataTypes;
    },
    getEligibleParentDataTypes() {
      return this.eligibleParentDataTypes;
    },
    attributes() {
      return this.datasetElements[this.datasetElementId].attributes;
    },
    allUsers() {
      return this.users || [];
    },
    listAttributes() {
      if (this.dataType && this.dataType.attributes) {
        return this.dataType.attributes.map((e) =>
          this.dataType.attributes.find(({ name }) => e.name === name)
        );
      }
      return [];
    },
    getMainPanelAttributes() {
      return this.dataTypes[this.dataType.id].editorConfig.general
        .mainAttributesPanel;
    },
    getCentralPanelAttributes() {
      return this.dataTypes[this.dataType.id].editorConfig.general
        .centralAttributesPanel;
    },
    hasSummary() {
      return this.dataTypes[this.dataType.id].editorConfig.general.hasSummary;
    },
    getSummaryConfig() {
      return this.dataTypes[this.dataType.id].editorConfig.general
        .summaryConfig;
    },
    friendlyId() {
      return this.attributes['friendly-id'] || '';
    },
    datasetElement() {
      return this.getDatasetElementById(this.datasetElementId);
    },
    eligibleRelationsDatatypes() {
      return this.dataTypes[this.dataType.id].editorConfig.general
        .eligibleRelationsDatatypes;
    }
  },
  watch: {
    datasetElementId() {
      if (!this.datasetElements[this.datasetElementId]) {
        this.close();
      }
    }
  },
  async mounted() {
    this.previousTitle = this.datasetElement.attributes?.title;
    await this.fetchEligibleDataTypes();
    await this.fetchHierarchicalRelations();
    await this.fetchDatasetElementDependencies();
    await this.fetchRelationTypes();

    this.debouncedSearch = debounce((searchValue) => {
      let attributes = {};
      if (this.selectedType) {
        attributes = { type: this.selectedType };
      }
      this.fetchEligibleDatasetElements({
        relation: this.selectedRelation,
        attributes,
        dataTypeName: this.selectedDataTypeName,
        searchValue
      });
    }, 500);
  },
  methods: {
    ...mapActions('board', ['updateDatasetElementAttributes', 'deleteSelectedDatasetElement', 'updateDatasetElements',
      'updateAttributeInRelatedDatasetElements', 'fetchDatasetElementById'
    ]),
    ...mapActions('hierarchy', [
      'createDependencyWithExistingDatasetElement',
      'createRelationByTypeWithExistingElement',
      'fetchEligibleDataTypes',
      'fetchHierarchicalRelations',
      'fetchDatasetElementDependencies',
      'fetchEligibleElementsForRelations',
      'resetRelationsData',
      'createDependencyWithNewDatasetElement',
      'createRelationFromNewElement',
      'deleteRelations',
      'unlinkRelationalElement'
    ]),
    ...mapActions('editor', ['closeEditor', 'openEditor', 'addPreviousDatasetElement',
      'openPreviousDetailsEditor']),
    ...mapMutations('editor', ['setPreviousDatasetElementIds']),
    ...mapMutations('board', ['setDialogBoxFlags', 'setBoardElementsInEdition', 'addDatasetElement']),
    ...mapMutations('dock', ['setIsCreating']),
    ...mapActions('app', [
      'addFormulasResultsToElements',
      'updateAttributeInParent',
      'fetchRelationTypes'
    ]),
    closeDialogBox() {
      this.isDialogOpened = false;
    },
    async discardElement() {
      const isDeleted = await this.deleteSelectedDatasetElement(this.datasetElementId);
      if (!isDeleted) {
        return;
      }
      this.closeDialogBox();
      this.resetEditorWrapperData();
    },
    async closeEditorWrapper() {
      const title = this.datasetElement.attributes?.title;
      if (!title || !title.length) {
        if (this.isCreating) {
          this.isDialogOpened = true;
          return;
        } else {
          const attributes = {
            ...this.datasetElement.attributes,
            title: this.previousTitle
          };
          this.updateAttributeInParent(this.datasetElementId, attributes);
          this.updateAttributeInRelatedDatasetElements(
            this.datasetElementId,
            attributes
          );
          this.updateDatasetElementAttributes({
            datasetElementId: this.datasetElementId,
            attributes
          });
        }
      }
      // await this.addFormulasResultsToElements();
      this.resetEditorWrapperData();
      this.setPreviousDatasetElementIds([]);
      this.setIsCreating(false);
      this.close();
    },
    checkIfDatatypeHasTypeAttribute(dataTypeName) {
      const datatype = this.getDataTypeByName(dataTypeName);
      return (
        datatype &&
        datatype.attributes.find((attribute) => attribute.name === 'type')
      );
    },
    async fetchEligibleDatasetElements({
      relation,
      attributes = {},
      dataTypeName,
      searchValue = ''
    }) {
      this.selectedType = null;
      this.selectedDataTypeName = dataTypeName;
      this.selectedRelation = relation;
      const typeAttribute = this.checkIfDatatypeHasTypeAttribute(dataTypeName);
      if (typeAttribute?.enum?.length) {
        this.selectedType = typeAttribute.enum[0].value;
      }

      if (!attributes.type && this.selectedType) {
        attributes.type = this.selectedType;
      }

      if (attributes.type) {
        this.selectedType = attributes.type;
      }

      const datatype = this.getDataTypeByName(dataTypeName);
      let searchQuery =
        relation === RELATION_TYPES[2] ? `dataTypeId=${datatype.id}` : '';
      searchQuery += searchValue ? `&search=${searchValue}` : '';
      await this.fetchEligibleElementsForRelations(
        {type: relation,
          attributes,
          searchQuery}
      );
    },
    resetEditorWrapperData() {
      this.resetRelationsData();
      this.closeEditor();
    },
    getOwner(ownerId) {
      const owner = this.users.find((user) => user.id === ownerId);
      return owner ? owner : this.currentUser;
    },
    isObject(obj) {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    },
    async handleAttributesChange({ attribute, value }) {
      const foundAttribute = this.listAttributes.find(
        (attr) => attr.name === attribute
      );
      const previousAttrValue = this.datasetElement.attributes[attribute];
      const emptyIntAttrValue =
        foundAttribute.type === 'int' && value.length === 0;
      const isTimestampedValue =
        foundAttribute.type === 'timestamped_indicator';
      if (
        !this.isObject(value) &&
        (previousAttrValue === value || emptyIntAttrValue)
      ) {
        return;
      }
      let updatedValue = value;
      if (foundAttribute.type === 'level') {
        updatedValue = value.id;
      } else if (isTimestampedValue) {
        updatedValue = {
          value: Number(value),
          timestamp: String(new Date().toISOString()).slice(0, -5)
        };
      }
      const attributes = {
        ...this.datasetElement.attributes,
        [attribute]: updatedValue
      };
      this.updateAttributeInParent(this.datasetElementId, attributes);
      this.updateAttributeInRelatedDatasetElements(
        this.datasetElementId,
        attributes
      );
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes
      });
      this.$emit('valueChanging');
      this.$emit('valueChange');
      // await this.addFormulasResultsToElements();
    },
    close() {
      if (
        this.currentSelectorInputValue.length ||
        this.currentMultipleSelectvalue.length
      ) {
        this.setDialogBoxFlags({
          ...this.dialogBoxFlags,
          isCancelElementRelationsEditing: true
        });
        return;
      }
      this.$emit('close');
    },
    escKeyAction(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    getDatasetElement(datasetElement) {
      this.currentSelectorInputValue = datasetElement
        ? datasetElement.inputText
        : '';
    },
    getChildrenToLink(childrenToLink) {
      this.currentMultipleSelectvalue = childrenToLink;
    },
    handleSearch(searchValue) {
      this.debouncedSearch(searchValue);
    },
    refreshDatasetElement(newDatasetElement) {
      if (newDatasetElement) {
        this.updateDatasetElements(newDatasetElement);
      }
    },
    async createRelationWithNewDatasetElement({ relation, body }) {
      const dataTypeId = this.getDataTypeByName(body.dataTypeName).id;
      delete body.dataTypeName;
      body = {
        ...body,
        dataTypeId
      };
      let newDatasetElement;
      if (relation === RELATION_TYPES[0]) {
        return;
      }
      if (relation === RELATION_TYPES[2]) {
        newDatasetElement = await this.createDependencyWithNewDatasetElement(body);
        this.addDatasetElement({datasetElementId: newDatasetElement.id, datasetElement: newDatasetElement});        return;
      }
      newDatasetElement = await this.createRelationFromNewElement(body);
      this.addDatasetElement({datasetElementId: newDatasetElement.id, datasetElement: newDatasetElement});
    },
    async createRelationWithExistingDatasetElement({
      relation,
      eligibleDatasetElementId
    }) {
      if (relation === RELATION_TYPES[2]) {
        await this.createDependencyWithExistingDatasetElement({
          eligibleDatasetElementId
        });
        return;
      }
      await this.createRelationByTypeWithExistingElement({
        relation,
        eligibleDatasetElementId
      });
    },
    async executeAction({ action, relatedDatasetElementId, relationId, relationType }) {
      if (action === 'deleteAction') {
        await this.deleteRelations({ relationId, relatedDatasetElementId, relationType });
        return;
      }
      if (action === 'unlinkAction') {
        await this.unlinkRelationalElement({ relationId, relatedDatasetElementId, relationType });
      }
    },
    openNewElementDetails({id}) {
      this.addPreviousDatasetElement(this.datasetElementId);
      this.resetEditorWrapperData();
      setTimeout(() => this.openEditor({datasetElementId: id, boardElementId: this.elementId}));
    },
    goBackToPreviousElementDetails() {
      this.resetEditorWrapperData();
      setTimeout(() => this.openPreviousDetailsEditor());
    }
  }
};
</script>

<style scoped src="./BoardElementEditor.css" />
