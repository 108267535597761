<template>
  <movable
    :id="elementId"
    ref="movable"
    :position="position"
    :element-id="elementId"
    :no-element-drag="noElementDrag || locked"
    :is-selected="isSelected"
    :no-interactions="isColorsPopup"
    :height="size.height"
    :width="size.width"
    :set-is-resizing="setIsResizing"
    :homothetic-resize="resizeConfig?.homotheticResize"
    @dblclick="onDoubleClick"
    @mousedown="() => send('pressElements')"
    @drag="onDrag"
  >
    <div
      :id="elementId+'insider'"
      :class="['BoardElement', { 'BoardElement--ghost': isGhost }]"
    >
      <div>
        <ResizeKnobs
          v-if="isSelected && resizeConfig?.isFreelyResizable"
          :zoom-level="zoomLevel"
          :feedback-color="feedbackColor"
          :corner-knobs="resizeConfig?.cornerKnobs"
          :middle-knobs="resizeConfig?.middleKnobs"
        />
        <contextual-menu
          v-if="
            isSelected &&
              !(formulaInEditionBoardElementId === elementId) &&
              !isBeingMovedByThisUser &&
              userSelectedElements &&
              userSelectedElements.length === 1 &&
              contextualMenuShown
          "
          :on-edit="onEditClick"
          :on-delete="deleteUserSelectedElements"
          :on-size-change="onSizeChange"
          :on-layout-change="updateLayout"
          :on-lock-toggle="toggleElementsLockStatus"
          :locked="locked"
          :style="'z-index: 1'"
          :is-in-edition="isInEditionByCurrentUser"
          :has-color="hasColor"
          :bg-color="bgColor"
          :layout="selectedLayout"
          :has-layout="hasLayout"
          :set-color="(value) => updateElementProprety({color: value})"
          :close-colors-popup="closeColorsPopup"
          :toggle-colors-popup="toggleColorsPopup"
          :is-colors-popup="isColorsPopup"
          :contextual-menu-items="contextualMenuItems"
          :size-layout-map="sizeLayoutItems"
          :size="size"
        />
        <board-element-feedback
          v-if="
            !isUnHighlighted &&
              (isInEdition ||
                isMoving ||
                isHighlighted ||
                isOnlySelectedByOthers ||
                isSelected)
          "
          :text="String(tooltipText)"
          :is-edition="isInEdition"
          :is-selected="isSelected"
          :color="String(feedbackColor)"
          :is-highlighted="isHighlighted"
          :is-widget="isWidget"
          :is-in-edition-only-by-others="isInEditionOnlyByOthers"
        />
        <formula-widget
          v-if="attributes && isWidget"
          class="BoardElement-content"
          :attributes="attributes"
          :dataset-element-id="datasetElementId"
          :board-element-id="elementId"
          :is-edition="isInEditionByCurrentUser"
          :outline-color="String(feedbackColor)"
          :has-outline="hasOutline"
        />
        <indicator-chart
          v-else-if="isIndicator"
          :key="elementId + width + height"
          :is-resizing="isResizing"
          :style="style"
          class="BoardElement-content"
          :attributes="attributes"
          :element-id="elementId"
          :dataset-element-id="datasetElementId"
          :height="size.height"
          :width="size.width"
          :has-outline="hasOutline"
          :outline-color="String(feedbackColor)"
        />
        <PowerBI
          v-else-if="attributes && isPowerBI"
          :style="style"
          :url="attributes.url"
          :height="size.height"
          :width="size.width"
        />
        <BoardNote
          v-else-if="attributes && isBoardNote"
          :style="style"
          :title="attributes.title"
          :size="size"
          :color="bgColor"
          :layout="selectedLayout"
          :size-layout-map="sizeLayoutItems"
        />
        <card
          v-else-if="isCard"
          :style="style"
          class="BoardElement-content"
          :fields="elementFields"
          :bg-color="bgColor"
          :has-color="hasColor"
          :can-be-highlighted="selectedInteractionMode === INTERACTION_MODES.LINKS"
          :additional-dots="additionalDots"
          :element-id="elementId"
          :height="size.height"
          :width="size.width"
          :data-type-id="dataTypeId"
          :attributes="attributes"
        />
        <list-widget
          v-else-if="isBoardget"
          :key="elementId+size.height+size.width"
          :element-id="elementId"
          :style="style"
          class="BoardElement-content"
          :can-be-highlighted="selectedInteractionMode === INTERACTION_MODES.LINKS"
          :list="widgetDatasetElements"
          :title="attributes.title"
          :height="size.height"
          :width="size.width"
        />
        <board-image
          v-else-if="isImage && attributes?.asset"
          :key="elementId"
          :attributes="attributes"
          :height="size.height"
          :width="size.width"
          :style="style"
          class="BoardElement-content"
        />
      </div>
    </div>
  </movable>
</template>

<script>
import Movable from 'BOARD/components/Movable/Movable.vue';
import Card from 'BOARD/components/Card/Card.vue';
import BoardNote from 'SRC/note/BoardNote.vue';
import BoardElementFeedback from 'BOARD/components/BoardElementFeedback/BoardElementFeedback';
import ContextualMenu from 'BOARD/components/ContextualMenu/ContextualMenu';
import ResizeKnobs from 'BOARD/components/BoardElement/ResizeKnobs/ResizeKnobs.vue';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import {
  getFeedbackColorForEdition,
  getFeedbackColorForMove,
  getToolTipTextForEdition,
  getToolTipTextForMove,
  DEFAULT_EDITION_FEEDBACK_COLOR,
  getSizeFromDimensions,
  hasProprety,
  getPropertyValue
} from 'BOARD/components/BoardElement/boardElementUtils';
import {
  INTERACTION_MODES,
  DEFAULT_BOARD_ELEMENT_SIZE,
  ToolTypes
} from 'GLOBALS/constants';
import FormulaWidget from 'BOARD/components/widgets/formula/FormulaWidget.vue';
import PowerBI from 'SRC/powerbi/PowerBI.vue';
import ListWidget from 'BOARD/components/widgets/list/ListWidget.vue';
import IndicatorChart from '../IndicatorChart/IndicatorChart.vue';
import BoardImage from 'BOARD/components/BoardImage/BoardImage.vue';
import {getDimensionFromSize} from 'BOARD/utils/utils';
export default {
  name: 'BoardElement',
  components: {
    BoardElementFeedback,
    Movable,
    Card,
    BoardNote,
    ContextualMenu,
    FormulaWidget,
    PowerBI,
    ListWidget,
    IndicatorChart,
    ResizeKnobs,
    BoardImage
  },
  props: {
    layoutTiles: {
      type: Array,
      //required: true,
      default: () => []
    },
    attributes: {
      type: Object,
      default: () => {}
    },
    state: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    selectedInteractionMode: {
      type: String,
      required: true
    },
    dataTypeId: {
      type: String,
      required: true
    },
    send: {
      type: Function,
      default: () => {}
    },
    datasetElementId: {
      type: String,
      required: true
    }
  },
  emits: ['element-drag', 'close'],
  data: () => ({
    INTERACTION_MODES,
    noElementDrag: false,
    DEFAULT_BOARD_ELEMENT_SIZE,
    isColorsPopup: false,
    isResizing: false
  }),
  computed: {
    ...mapState('users', ['usersOnBoard', 'currentUser']),
    ...mapState('dock', ['displayComponent']),
    ...mapState('board', [
      'userSelectedElements',
      'highlightedBoardElements',
      'unHighlightedBoardElements',
      'contextualMenuShown',
      'formulaInEditionBoardElementId',
      'clickedElementId',
      'multipleRelations',
      'elements',
      'datasetElements',
      'filters',
      'zoomLevel'
    ]),
    ...mapGetters('board', ['getSelectedBoardElements']),
    ...mapGetters('app', ['getDataTypeIcon', 'getDataTypeColor', 'getDataTypeName', 'getViewTypeSize']),
    additionalDots() {
      const multiRelationsExist = this.multipleRelations && Object.values(this.multipleRelations).length > 0;
      if (!multiRelationsExist) {
        return [];
      }
      const hasMultipleRelations = Object.entries(this.multipleRelations).filter(([relationId]) => relationId.includes(this.elementId));
      if (hasMultipleRelations.length === 0) {
        return [];
      }
      const maxDots = Math.max(...hasMultipleRelations.map((el) => el[1].length));
      return [...Array(maxDots).keys()];
    },
    ...mapState('app', ['viewTypes', 'dataTypes']),
    viewTypeId() {
      return this.elements[this.elementId]?.viewTypeId;
    },
    size() {
      if (!this.viewTypeId) {
        return { height: 100, width: 100 };
      }
      if (this.height && this.width) {
        return { height: this.height, width: this.width };
      }
      return this.getViewTypeSize(this.viewTypeId);
    },
    elementFields() {
      const dataType = this.dataTypes[this.dataTypeId];
      return this.isGhost
        ? []
        : this.layoutTiles.map((tile) => {
          const fieldName = tile.fieldName;
          const fieldValue = this.attributes[fieldName];
          const dataTypeAttr = dataType.attributes.find((e) => e.name === fieldName);
          const originRef = dataTypeAttr ? dataTypeAttr.originRef : '';
          return { ...tile, value: fieldValue, originRef };
        });
    },
    hasColor() {
      return hasProprety('color', this.dataTypes, this.dataTypeId);
    },
    hasLayout() {
      return hasProprety('layout', this.dataTypes, this.dataTypeId);
    },
    hasOutline() {
      if (this.isUnHighlighted) {
        return false;
      }
      const hasOutlineFlags = [
        this.isInEditionByCurrentUser,
        this.isMoving,
        this.isHighlighted,
        this.isSelected,
        this.isOnlySelectedByOthers
      ];
      return hasOutlineFlags.includes(true);
    },
    style() {
      const initialStyle = {
        height: `${this.size.height}px`,
        width: `${this.size.width}px`
      };
      if (this.hasOutline) {
        return {
          ...initialStyle,
          outline: `${Math.max(2, 2 * (1 / this.zoomLevel))}px ${String(this.feedbackColor)} solid`
        };
      }
      return { ...initialStyle };
    },
    bgColor() {
      return getPropertyValue('color', this.dataTypes, this.attributes, this.dataTypeId);
    },
    selectedLayout() {
      return getPropertyValue('layout', this.dataTypes, this.attributes, this.dataTypeId);
    },
    contextualMenuItems() {
      return this.viewTypes[this.viewTypeId].contextualMenuItems;
    },
    resizeConfig() {
      return this.viewTypes[this.viewTypeId]?.resize;
    },
    sizeLayoutItems() {
      const viewTypeId = this.elements[this.elementId].viewTypeId;
      return this.viewTypes[viewTypeId]?.sizeMapLayout;
    },
    isWidget() {
      return !!this.dataTypes[this.dataTypeId]?.namespace.includes(
        '/element-data-types/widgets'
      );
    },
    isPowerBI() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.POWERBI;
    },
    isBoardNote() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.NOTE;
    },
    isBoardget() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.LIST;
    },
    isIndicator() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.INDICATOR;
    },
    isCard() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.CARD;
    },
    isImage() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.IMAGE;
    },
    isGhost() {
      return this.attributes === null;
    },
    isInEdition() {
      return (
        this.getBoardElementModifiers()(this.elementId).length > 0 &&
        this.getBoardElementModifiers()(this.elementId).some(
          (eachUserId) =>
            eachUserId === this.currentUser.id ||
            this.usersOnBoard.map((each) => each.id).includes(eachUserId)
        )
      );
    },
    isInEditionByCurrentUser() {
      if (this.getBoardElementModifiers()(this.elementId)) {
        return (
          this.getBoardElementModifiers()(this.elementId).length > 0 &&
        this.getBoardElementModifiers()(this.elementId).some(
          (eachUserId) => eachUserId === this.currentUser.id
        )
        );
      } else {
        return false;
      }
    },
    isInEditionOnlyByOthers() {
      return (
        this.getBoardElementModifiers()(this.elementId).length > 0 &&
        this.getBoardElementModifiers()(this.elementId).some(
          (eachUserId) => eachUserId !== this.currentUser.id
        )
      );
    },
    isHighlighted() {
      return this.highlightedBoardElements.includes(this.elementId);
    },
    isUnHighlighted() {
      return this.unHighlightedBoardElements.includes(this.elementId);
    },
    isSelected() {
      const selectors = this.getBoardElementSelectors()(this.elementId);
      if (selectors.length > 0) {
        return !!selectors.find(
          (eachUserId) => eachUserId === this.currentUser.id
        );
      }
      return false;
    },
    isOnlySelectedByOthers() {
      const selectors = this.getBoardElementSelectors()(this.elementId);
      if (selectors.length > 0) {
        return !selectors.find(
          (eachUserId) => eachUserId === this.currentUser.id
        );
      }
      return false;
    },
    tooltipText() {
      if (this.isInEdition) {
        const boardElementModifiersIds = this.getBoardElementModifiers()(
          this.elementId
        );
        if (boardElementModifiersIds.length) {
          const currentUserIsAModifier = boardElementModifiersIds.some(
            (each) => each === this.getCurrentUser().id
          );
          const boardElementModifiers = this.getAllUsers()
            .filter((each) => each.id !== this.getCurrentUser().id)
            .filter((each) => boardElementModifiersIds.includes(each.id));
          return getToolTipTextForEdition(
            boardElementModifiers,
            currentUserIsAModifier
          );
        } else {
          return '';
        }
      } else {
        const boardElementMoversIds = this.getBoardElementMovers()(
          this.elementId
        );
        if (boardElementMoversIds?.length) {
          return getToolTipTextForMove(
            boardElementMoversIds[0],
            this.getAllUsers(),
            this.getCurrentUser().id
          );
        } else if (this.isOnlySelectedByOthers) {
          return '';
        } else {
          return '';
        }
      }
    },
    feedbackColor() {
      if (this.isSelected || this.isInEditionByCurrentUser) {
        return DEFAULT_EDITION_FEEDBACK_COLOR;
      }
      if (this.isInEdition) {
        const boardElementModifiersIds = this.getBoardElementModifiers()(
          this.elementId
        );
        return getFeedbackColorForEdition(
          boardElementModifiersIds,
          this.getUsersOnBoard(),
          this.getCurrentUser().id
        );
      }
      const boardElementMoversIds = this.getBoardElementMovers()(
        this.elementId
      );
      if (
        boardElementMoversIds &&
        boardElementMoversIds.length > 0 &&
        !this.isBeingMovedByThisUser
      ) {
        return getFeedbackColorForMove(
          boardElementMoversIds,
          this.getUsersOnBoard()
        );
      }
      const boardElementSelectorsIds = this.getBoardElementSelectors()(
        this.elementId
      );
      return getFeedbackColorForEdition(
        boardElementSelectorsIds,
        this.getUsersOnBoard(),
        this.getCurrentUser().id
      );
    },
    isBeingMovedByThisUser() {
      const boardElementsBeingMoved = this.getBoardElementsBeingMoved();
      if (boardElementsBeingMoved && boardElementsBeingMoved[this.elementId]) {
        return (
          boardElementsBeingMoved[this.elementId].filter(
            (eachUserId) => eachUserId === this.getCurrentUser().id
          ).length > 0
        );
      }
      return false;
    },
    isMoving() {
      return (
        Object.keys(this.getBoardElementsBeingMoved()).indexOf(
          this.elementId
        ) !== -1 &&
        (this.getBoardElementsBeingMoved()[this.elementId] || []).filter(
          (eachUserId) => eachUserId !== this.getCurrentUser().id
        ).length > 0
      );
    },
    widgetDatasetElements() {
      if (this.attributes && this.attributes.filter) {
        const datasetElements = [];
        const filter = this.filters[this.attributes.filter];
        if (filter && filter.length) {
          filter.forEach((datasetElement) => {
            const element = this.datasetElements[datasetElement.id];
            if (element) {
              const user = this.getAllUsers().find((user) => user.id === element.attributes.owner);
              const dataTypeName = this.getDataTypeName(element.typeId, element);
              datasetElements.push({
                id: element.id,
                type: {
                  text: dataTypeName,
                  iconName: this.getDataTypeIcon(element.typeId),
                  color: this.getDataTypeColor(element.typeId)
                },
                title: element.attributes.title,
                status: element.attributes.status,
                friendlyId: element.attributes['friendly-id'],
                user: {
                  picture: user?.picture,
                  altText: user?.firstname[0] + user?.lastname[0] || ''
                },
                initialValue: element.attributes.initialValue || '',
                targetValue: element.attributes.targetValue || '',
                bestInClass: element.attributes.bestInClass || '',
                unit: element.attributes.unit || ''
              });
            }
          });
        }
        return datasetElements;
      } else {
        return [];
      }
    }
  },
  watch: {
    selectedInteractionMode: {
      immediate: true,
      handler(value) {
        this.noElementDrag = INTERACTION_MODES.SELECT !== value;
      }
    },
    state: {
      deep: true,
      immediate: true,
      handler() {}
    },
    attributes: {
      deep: true,
      immediate: true,
      handler() {
        if (this.attributes.filter && !this.filters[this.attributes.filter]) {
          this.fetchFilterResults({id: this.attributes.filter});
        }
      }
    }
  },
  methods: {
    setIsResizing(value) {
      this.isResizing = value;
    },
    onEditClick() {
      this.$emit('edit-click');
    },
    onSizeChange(dimension, layout = null) {
      const sizeListOfLayout = this.sizeLayoutItems?.find((item) => item[layout || this.selectedLayout]);
      const size = getSizeFromDimensions(dimension, sizeListOfLayout && sizeListOfLayout[layout || this.selectedLayout]);
      this.updateBoardElementSize({
        elementId: this.elementId,
        size
      });
    },
    onDoubleClick() {
      if (this.isInEditionByCurrentUser) {
        this.$parent.$parent.$parent.$emit('close');
        this.removeSelection([this.elementId]);
        return;
      }
      if (this.clickedElementId === this.elementId) {
        this.setClickedElementId(null);
        return;
      }
      this.$emit('edit-click');
    },
    closeColorsPopup() {
      this.isColorsPopup = false;
    },
    toggleColorsPopup() {
      this.isColorsPopup = !this.isColorsPopup;
    },
    emitElementDrag(position) {
      this.$emit('element-drag', position);
    },
    onDrag(position) {
      this.emitElementDrag(position);
    },
    ...mapGetters('board', [
      'getBoardElementsBeingMoved',
      'getBoardElementMovers',
      'getBoardElementModifiers',
      'getBoardElementSelectors'
    ]),
    ...mapGetters('users', [
      'getCurrentUser',
      'getAllUsers',
      'getUsersOnBoard'
    ]),
    ...mapMutations('board', ['deleteBoardElement', 'setClickedElementId', 'setFilter']),
    ...mapActions('board', [
      'removeGlobalSelection',
      'removeSelection',
      'toggleSelectedElementsLockState',
      'deleteUserSelectedElements',
      'addDatasetElementsToStore',
      'updateDatasetElementAttributes',
      'fetchFilterResults',
      'updateBoardElementSize'
    ]),
    updateLayout(value) {
      this.updateElementProprety({layout: value});
      const layoutItem = this.sizeLayoutItems?.find((item) => item[this.selectedLayout]);
      const list = layoutItem && layoutItem[this.selectedLayout];
      const dimension = getDimensionFromSize(this.size.width, this.size.height, list);
      this.onSizeChange(dimension, value);
    },
    updateElementProprety(value) {
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes: {
          ...this.attributes,
          ...value
        }
      });
      if (value.color) {
        this.closeColorsPopup();
      }
    },
    toggleElementsLockStatus() {
      this.toggleSelectedElementsLockState();
    }
  }
};
</script>

<style>
.BoardElement {
  user-select: none;
  position: absolute;
}

.BoardElement--ghost .BoardElement-content {
  background-color: #dcdcdc;
  opacity: 0.5;
}
</style>
