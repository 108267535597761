<template>
  <div>
    <list-boardget-modal
      v-if="isBoardgetCreation"
      v-bind="componentMap[creationComponent].props"
    />
    <card-creation-modal
      v-if="isCardCreation"
      v-bind="componentMap[creationComponent].props"
    />
    <PBICreationModal v-if="isPowerBICreation" />
    <ImageCreation v-if="isImageCreation" />
  </div>
</template>

<script setup>
import ListBoardgetModal from '../modals/ListBoardgetModal/ListBoardgetModal.vue';
import CardCreationModal from '../modals/CardCreationModal/CardCreationModal.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import PBICreationModal from '../powerbi/modals/PBICreationModal.vue';
import ImageCreation from '../modals/ImageCreation/ImageCreation.vue';

const store = useStore();
const componentMap = ref({
  CardCreationModal: {
    componentName: 'CardCreationModal',
    props: {}
  },
  ListBoardgetModal: {
    componentName: 'ListBoardgetModal',
    props: {}
  },
  PBICreationModal: {
    componentName: 'PBICreationModal',
    props: {}
  },
  ImageCreation: {
    componentName: 'ImageCreation',
    props: {}
  }
});

const isBoardgetCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'ListBoardgetModal');
const isCardCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'CardCreationModal');
const isPowerBICreation = computed(() => componentMap.value[creationComponent.value].componentName === 'PBICreationModal');
const isImageCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'ImageCreation');
const creationComponent = computed(() => store.state.dock.creationComponent);
</script>
