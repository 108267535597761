<template>
  <table
    class="IobTable"
    :class="{'IobTable--fixed': fixed}"
  >
    <iob-table-header
      :columns="tableHeaders"
      :sort-field="sortField"
      :fixed="fixed"
      :sort-direction="sortDirection"
      :check-all="checkAll"
      @on-sort-table="handelSortTable"
      @on-select-cell="handelOnColumnHeaderClick"
      @toggle-check-all="handleToggleChecktAll"
    />
    <tbody>
      <iob-table-row
        v-for="(row, index) in mappedRows"
        :key="index"
        :columns="row"
        :has-vertical-separator="hasVerticalSeparator"
        :search-data="searchData"
        :enable-greyed-out="enableGreyedOut"
        :row-index="index"
        @on-click-cell-item="(item) => handelCellItemClick(item, index)"
        @onClickId="(data) => emit('onClickId', data)"
        @on-click-table-row="() => emit('onClickTableRow', index)"
      />
      <template v-if="props.isLoading">
        <iob-table-row
          v-for="index in skeletonRowsCount"
          :key="index"
          :columns="tableHeaders"
          :is-loading="true"
        />
      </template>
    </tbody>
    <outside-click-listener
      v-if="isOptionsMenuVisible && moreOptionsMenu.length"
      @outside-click="handleClickOutisdeDetailsMenu"
    >
      <iob-dropdown
        ref="moreOptionsMenuRef"
        class="IobTable-detailsDropdown"
        :items="moreOptionsMenu"
        @dropdown-element-item="handleActionClick"
      />
    </outside-click-listener>
  </table>
</template>

<script setup>
import IobTableHeader from './IobTableHeader/IobTableHeader.vue';
import IobTableRow from './IobTableRow/IobTableRow.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';
import { defineProps, defineEmits, computed, ref, nextTick } from 'vue';

const isOptionsMenuVisible = ref(false);
const moreOptionsIconId = ref(null);
const moreOptionsMenuRef = ref(null);
const rowIndex = ref(null);

const props = defineProps({
  tableHeaders: { type: Array, default: () => ([]) },
  rows: { type: Array, default: () => ([]) },
  sortField: { type: String, default: '' },
  hasVerticalSeparator: { type: Boolean, default: false },
  sortDirection: { String, default: 'asc', validator: (value) => ['asc', 'desc'].includes(value) },
  isLoading: { type: Boolean, default: false },
  skeletonRowsCount: { type: Number, default: 10 },
  checkAll: { type: Boolean, default: false },
  searchData: { type: String, default: '' },
  enableGreyedOut: { type: Boolean, default: false },
  moreOptionsMenu: { type: Array, default: () => ([]) },
  fixed: { type: Boolean, default: false },
});

const checkAll = computed(() => props.checkAll);
const mappedRows = computed(() => {
  return props.rows.map(getMapedRowColumns);
});

const getMapedRowColumns = (row) => props.tableHeaders.map((column) => {
  if (column.isChecked && props.checkAll) {
    row[column.name].checked = true;
  }
  return { data: row[column.name], class: column.class, width: column.width, type: column.type, name: column.name, minWidth: column.minWidth, maxWidth: column.maxWidth };

});
const emit = defineEmits(['onClickColumnHeader', 'onClickCellItem', 'onClickId', 'onClickTableRow', 'onDetailsActionClick']);

const handelOnColumnHeaderClick = (id) => {
  emit('onClickColumnHeader', id);
}

const handelCellItemClick = (item, index) => {
  emit('onClickCellItem', { ...item, index });
  if (item.columnName !== 'more') {
    isOptionsMenuVisible.value = false;
    removeOptionsDropdownElement(moreOptionsIconId.value);
    return;
  }
  rowIndex.value = index;
  const moreOptionsButtonId = `${item.item}-${index}`;
  manageOptionsDropdown(moreOptionsButtonId);
}

const manageOptionsDropdown = (moreOptionsButtonId) => {
  if (moreOptionsIconId.value !== moreOptionsButtonId) {
    moreOptionsIconId.value = moreOptionsButtonId;
    isOptionsMenuVisible.value = true;
    appendOptionsDropdownElement(moreOptionsButtonId);
  } else {
    moreOptionsIconId.value = null;
    isOptionsMenuVisible.value = false;
    removeOptionsDropdownElement(moreOptionsButtonId);
  }
};

const appendOptionsDropdownElement = async (elementId) => {
  await nextTick();
  const itemElement = document.getElementById(elementId);
  if (itemElement && moreOptionsMenuRef.value && moreOptionsMenuRef.value.$el) {
    itemElement.appendChild(moreOptionsMenuRef.value.$el);
  }
};

const removeOptionsDropdownElement = (elementId) => {
  const itemElement = document.getElementById(elementId);
  if (itemElement && moreOptionsMenuRef.value && moreOptionsMenuRef.value.$el) {
    itemElement.removeChild(moreOptionsMenuRef.value.$el);
  }
};

const handleClickOutisdeDetailsMenu = (e) => {
  e.stopPropagation();
  e.preventDefault();
  removeOptionsDropdownElement(moreOptionsIconId.value);
  moreOptionsIconId.value = null;
  isOptionsMenuVisible.value = false;
};

const handleActionClick = ({item}) => {
  emit('onDetailsActionClick', {actionId: item.id, index: rowIndex.value});
};

const handleToggleChecktAll = (item) => {
  checkAll.value = item.isChecked;
  emit('toggle-check-all', item);
}
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-tables.scss" />
<style lang="scss" scoped src="./IobTable.scss" />
