<template>
  <div v-if="exceptions.length">
    <dialog-box
      class="z-50"
      :dialog-box-specific-style="dialogBoxStyle"
      :no-padding="true"
    >
      <pop-up
        :details-object="exceptions[0]"
        title="Something went wrong"
        @popup-button-click="handleException"
      />
    </dialog-box>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { API_HOST } from 'SRC/config/api';

export default {
  name: 'ExceptionHandler',
  data() {
    return {
      dialogBoxStyle: 'position: fixed; left: 35%; top: 35%; width: 35%;',
      API_HOST
    };
  },
  computed: {
    ...mapState('app', [
      'exceptions'
    ])
  },
  methods: {
    ...mapMutations('app', [
      'setExceptions'
    ]),
    handleException() {
      const exceptions = this.exceptions;
      exceptions.shift();
      this.setExceptions(exceptions);
    }
  }
};
</script>
