import { getSyncedStore } from 'SRC/utils/collab';
import { getYjsValue } from '@syncedstore/core';

/**
 * Synchronizes the common data between the Vuex and SyncStore stores.
 * @param {*} store The Vuex store
 */
const removeDeleted = (acc, [key, value]) => {
  if (value && value.deleted) {
    return acc;
  } return { ...acc, [key]: value };
};
let didFetchIndicators = false;
const isUserEditedElementBeingDeleted = (userEditedElements, parsedYBoardElements) => (userEditedElements && userEditedElements.length > 0) ?
  !Object.keys(parsedYBoardElements).find((e) => e === userEditedElements[0]) : false;

export const synchronizeVuexBoardElements = (store) => (yBoardElements) => () => {
  const vueXStore = store._state.data;
  let parsedYBoardElements = JSON.parse(JSON.stringify(yBoardElements));
  const boardElementsEntries = Object.entries(parsedYBoardElements);
  if (boardElementsEntries.length) {
    parsedYBoardElements = boardElementsEntries.reduce(removeDeleted, {});
  }
  if (Object.keys(parsedYBoardElements).length < Object.keys(vueXStore.board.elements).length && vueXStore.users.currentUser) {
    const currentUserId = vueXStore.users.currentUser.id;
    const userEditedElements = Object.entries(vueXStore.board.boardElementsInEdition)
      .find(([, value]) => value.find((e) => e.userId === currentUserId));
    const isLastBoardElementInEdAFormula = vueXStore.board.formulaInEditionBoardElementId === vueXStore.board.lastBoardElementIdInEdition;
    if (isUserEditedElementBeingDeleted(userEditedElements, parsedYBoardElements)) {
      if (isLastBoardElementInEdAFormula) {
        store.dispatch('board/removeFormulaFromSelection', vueXStore.board.formulaInEditionBoardElementId);
        store.commit('board/setFormulaInEditionBoardElementId', null);
        store.dispatch('board/removeSelection', [vueXStore.board.formulaInEditionBoardElementId]);
      } else {
        store.dispatch('editor/closeEditor');
      }
    }
  }
  store.commit('board/setBoardElements', parsedYBoardElements);
};
export const synchronizeVuexDatasetElements = (store) => (yDatasetElements) => () => {
  const parsedyDataSetElements = JSON.parse(JSON.stringify(yDatasetElements));
  store.commit('board/setDatasetElements', parsedyDataSetElements);
};

function synchronizeStore(store) {
  const boardStore = getSyncedStore();
  const yBoardElements = getYjsValue(boardStore.boardElements);
  const yDatasetElements = getYjsValue(boardStore.datasetElements);
  const yBoardElementsInEdition = getYjsValue(boardStore.boardElementsInEdition);
  const yBoardElementsBeingMoved = getYjsValue(boardStore.boardElementsBeingMoved);
  const yBoardElementsSelected = getYjsValue(boardStore.boardElementsBeingSelected);
  const yVisualLinks = getYjsValue(boardStore.visualLinks);
  const yDependencies = getYjsValue(boardStore.dependencies);
  const yhierarchicalRelations = getYjsValue(boardStore.hierarchicalRelations);
  yhierarchicalRelations.observeDeep(() => {
    store.commit('relations/setHierarchicalRelations', JSON.parse(JSON.stringify(yhierarchicalRelations)));
  });
  yDependencies.observeDeep(() => {
    store.commit('relations/setDependencies', JSON.parse(JSON.stringify(yDependencies)));
  });

  yVisualLinks.observeDeep(() => {
    store.commit('relations/setVisualLinks', JSON.parse(JSON.stringify(yVisualLinks)));
  });
  yBoardElements.observeDeep(synchronizeVuexBoardElements(store)(yBoardElements));

  yDatasetElements.observeDeep(() => {
    synchronizeVuexDatasetElements(store)(yDatasetElements)();
    if (!didFetchIndicators) {
      store.dispatch('board/fetchIndicatorsRelations');
      didFetchIndicators = true;
    }
  });

  yBoardElementsInEdition.observeDeep(() => {
    store.commit('board/setBoardElementsInEdition', JSON.parse(JSON.stringify(yBoardElementsInEdition)));
  });

  yBoardElementsBeingMoved.observeDeep(() => {
    store.commit('board/setBoardElementsBeingMoved', JSON.parse(JSON.stringify(yBoardElementsBeingMoved)));
  });

  yBoardElementsSelected.observeDeep(() => {
    const parsedSelectedElements = JSON.parse(JSON.stringify(yBoardElementsSelected));
    store.dispatch('board/setUserSelectedElements', parsedSelectedElements);
    store.commit('board/setBoardElementsBeingSelected', parsedSelectedElements);
  });
  const unsubscribe = store.subscribe((mutation) => {
    const handleMutation = ({ type, payload }) => {

      switch (type) {
      // Mutate SyncedStore variables according the corresponding vuex mutations
      case 'board/addBoardElement':
        boardStore.boardElements[payload.elementId] = payload.element;
        break;
      case 'board/addDatasetElement':
        boardStore.datasetElements[payload.datasetElementId] = payload.datasetElement;
        break;
      case 'board/updateBoardElement':
        Object.assign(boardStore.boardElements[payload.elementId], payload.updates);
        break;
      case 'board/updateDatasetElement':
        Object.assign(boardStore.datasetElements[payload.datasetElementId], payload.updates);
        break;
      case 'board/setUsersForBoardElementInEdition':
        boardStore.boardElementsInEdition[payload.boardElementId] = payload.users;
        break;
      case 'board/setUsersForBoardElementBeingMoved':
        boardStore.boardElementsBeingMoved[payload.boardElementId] = payload.users;
        break;
      case 'board/setBackgroundImage':
        if (payload.id !== boardStore.backgroundImage.id) {
          Object.assign(boardStore.backgroundImage, payload);
        }
        break;
      case 'board/setUsersForBoardElementBeingSelected':
        Object.entries(payload).forEach(([key, value]) => {
          boardStore.boardElementsBeingSelected[key] = value;
        });
        break;
      case 'board/deleteBoardElementInEdition':
        delete boardStore.boardElementsInEdition[payload.boardElementId];
        break;
      case 'relations/addVisualLink':
        boardStore.visualLinks[payload.visualLinkId] = payload.visualLink;
        break;
      case 'relations/addDependency':
        boardStore.dependencies[payload.dependencyId] = payload.dependency;
        break;
      case 'relations/addHierarchicalRelation':
        boardStore.hierarchicalRelations[payload.hierarchicalRelationId] = payload.hierarchicalRelation;
        break;
      case 'relations/updateVisualLink':
        Object.assign(boardStore.visualLinks[payload.visualLinkId], payload.updates);
        break;
      case 'relations/updateDependency':
        Object.assign(boardStore.dependencies[payload.dependencyId], payload.updates);
        break;
      case 'relations/updateHierarchicalRelation':
        Object.assign(boardStore.hierarchicalRelations[payload.hierarchicalRelationId], payload.updates);
        break;
      default:
        break;
      }
    };

    handleMutation(mutation);
  });
  return unsubscribe;
}

export default synchronizeStore;
