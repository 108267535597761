<template>
  <div
    class="IobImageTextElement"
    :class="classList"
    @click="handleElementClick"
  >
    <div
      :tabindex="isClickable ? 0 : -1"
      class="IobImageTextElement-image focus-inside"
    >
      <div
        v-if="imgSrc === ''"
        style="background-color: #fff"
      />
      <iob-image
        v-else
        class="IobImageTextElement-image-main"
        :src="imgSrc"
        :render-type="imageRatio"
      />
      <icon-loader
        v-if="!isCheckable"
        class="IobImageTextElement-image-veil"
        color="#ffffff"
        size="large"
        name="Eye"
      />
    </div>
    <div class="IobImageTextElement-content">
      <div class="IobImageTextElement-content-text">
        {{ text }}
      </div>
      <iob-checkbox
        v-if="isCheckable"
        id="checkbox"
        :checked="imageChecked"
        type="rounded"
      />
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed, ref, watchEffect} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import IobCheckbox from '../../Atoms/IobCheckbox/IobCheckbox.vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  imgSrc: {
    type: String,
    default: ''
  },
  imageRatio: {
    type: String,
    default: 'fit-to-box'
  },
  isClickable: {
    type: Boolean,
    default: false
  },
  isCheckable: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  }
});

const imageChecked = ref(props.checked);
watchEffect(() => {
  imageChecked.value = props.checked;
});

const classList = computed(() => ({
  'IobImageTextElement--clickable': props.isClickable
}));

const emits = defineEmits(['click']);

const handleElementClick = () => {
  if (props.isClickable) {
    emits('click');
  }
  if (props.isCheckable) {
    imageChecked.value = !imageChecked.value;
    emits('check',imageChecked.value);
  }
}

</script>

<style lang="scss" scoped>
.IobImageTextElement {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--imageText-gap-image-title, 4px);
  width: 100%;

  &--clickable:hover {
    outline: none;
    .IobImageTextElement-image {
      border: 1px solid var(--focus-color-focus);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--imageText-color-veil-hover, rgba(93, 88, 123, 0.50));
      }
        &-veil {
          display: inline;
          z-index: 1;
      }

    }

    .IobImageTextElement-text {
      color: var(--imageText-color-text-title-hover, #1D51CE);
    }

  }

  &--clickable {
    cursor: pointer;
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    &-text {
      color: var(--imageText-color-text-title-default);
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.12px;
    }
  }
  &-image {
    height: var(--imageText-sizing-image-height, 96px);
    width: 100%;
    border-radius: var(--imageText-radius-image, 8px);
    border: var(--imageText-sizing-border-width, 1px) solid var(--imageText-color-border-default, rgba(20, 19, 27, 0.16));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: relative;
    overflow: hidden;

    &-main {
      position: absolute;
      height: 100%;
      width: auto;
    }

    &:focus {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: var(--imageText-color-veil-hover, rgba(93, 88, 123, 0.50));
        }

        .IobImageTextElement-image-veil {
          display: inline;
          z-index: 1;
        }

        &+.IobImageTextElement-text {
          color: var(--imageText-color-text-title-hover, #1D51CE);
        }
      }

    &-veil {
      display: none;
    }
  }

}
</style>
