<template>
  <div
    ref="widget"
    data-target="board-element"
    class="ListWidget level-container level-2"
  >
    <div
      v-show="isHighlighted"
      data-target="board-element"
      class="ListWidget-highlighted"
    />
    <div
      data-target="board-element"
      class="ListWidget-indicator"
    />
    <div
      data-target="board-element"
      class="ListWidget-body"
    >
      <div
        data-target="board-element"
        class="ListWidget-body-header"
      >
        <div
          data-target="board-element"
          class="ListWidget-body-header-info"
        >
          <icon-loader
            data-target="board-element"
            name="List"
          />
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-title"
          >
            {{ title }}
          </span>
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-subtext"
          >
            •
          </span>
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-subtext"
          >
            {{ list.length }}
          </span>
        </div>
      </div>
      <div
        data-target="board-element"
        class="ListWidget-body-content"
      >
        <iob-list-item
          v-for="(item, index) in paginatedArray"
          :key="index"
          data-target="board-element"
          :data-list-item-id="item.id"
          :left-items="item.leftItems"
          :right-items="item.rightItems"
          :has-details="false"
        />
      </div>
    </div>
    <div
      v-if="numberOfPages > 1"
      data-target="board-element"
      class="ListWidget-footer"
    >
      <span
        data-target="board-element"
        class="ListWidget-footer-results"
      >
        {{ currentPageText }}
      </span>
      <div
        data-target="board-element"
        class="ListWidget-footer-pages"
      >
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronsLeft"
          :disabled="currentPage === 1"
          @click="first"
        />
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronLeft"
          :disabled="currentPage === 1"
          @click="previous"
        />
        <button
          v-for="index in start > end ? [] : range(start, end)"
          :key="index"
          data-target="board-element"
          class="ListWidget-footer-pages-button"
          :class="{'ListWidget-footer-pages-button--selected': currentPage === index}"
          :disabled="numberOfPages === 0"
          @click="goToPage(index)"
        >
          {{ index }}
        </button>
        <span
          v-if="hasReachedEnd"

          data-target="board-element"
        >...</span>
        <button
          data-target="board-element"

          class="ListWidget-footer-pages-button"
          :class="{'ListWidget-footer-pages-button--selected': currentPage === numberOfPages}"
          :disabled="numberOfPages <= 1"
          @click="goToPage(numberOfPages)"
        >
          {{ numberOfPages }}
        </button>
        <iob-action-button
          data-target="board-element"

          type="ghost"
          color="secondary"
          icon-name="ChevronRight"
          :disabled="currentPage >= numberOfPages"
          @click="next"
        />
        <iob-action-button
          data-target="board-element"

          type="ghost"
          color="secondary"
          icon-name="ChevronsRight"
          :disabled="currentPage >= numberOfPages"
          @click="last"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePagination } from '../../../../hooks/usePagination';
import { range } from 'lodash';

const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  canBeHighlighted: {
    type: Boolean,
    default: true
  },
  elementId: {
    type: String,
    required: true
  }
});

const displayedField = computed(() => props.list.map((item) => {
  const leftItems = [
    {
      fieldType: 'label',
      value: {
        text: item.type.text,
        color: item.type.color,
        icon: item.type.iconName
      }
    },
    {
      fieldType: 'title',
      value: item.friendlyId
    },
    {
      fieldType: 'text',
      value: item.title
    }
  ];
  let rightItems = [];
  if (item.status) {
    rightItems = [{
      fieldType: 'status',
      value: item.status
    }, {
      fieldType: 'user',
      value: item.user
    }];
  } else {
    rightItems = [{
      fieldType: 'title',
      value: item.initialValue ? item.initialValue + item.unit : ''
    }, {
      fieldType: 'title',
      value: item.targetValue ? item.targetValue + item.unit : ''
    }, {
      fieldType: 'title',
      value: item.bestInClass
    }];
  }
  return {
    leftItems,
    rightItems,
    id: item.id
  };
}));

const {
  paginatedArray,
  first,
  last,
  next,
  previous,
  currentPage,
  rowsPerPage,
  numberOfPages,
  goToPage
} = usePagination(displayedField, {
  rowsPerPage: 5
});

const start = computed(() => {
  if (numberOfPages.value <= 5) {
    return 1;
  }

  if (currentPage.value <= 3) {
    return 1;
  }

  if (currentPage.value >= numberOfPages.value - 3) {
    return numberOfPages.value - 5;
  }

  return currentPage.value - 2;
});
const end = computed(() => {
  if (numberOfPages.value <= 5) {
    return numberOfPages.value;
  }

  if (currentPage.value <= 3) {
    return 5;
  }

  if (currentPage.value >= numberOfPages.value - 3) {
    return numberOfPages.value;
  }

  return currentPage.value + 2;
});

const currentPageText = computed(() => {
  const start = numberOfPages.value > 0 ? (currentPage.value - 1) * rowsPerPage.value + 1 : 0;
  const end = Math.min(currentPage.value * rowsPerPage.value, props.list.length);
  return `${start} - ${end} of ${props.list.length}`;
});

const hasReachedEnd = computed(() => numberOfPages.value > 5 && currentPage.value < numberOfPages.value - 3);

const isHighlighted = computed(() => props.canBeHighlighted);

</script>

<style lang="scss" src="./ListWidget.scss" scoped />

