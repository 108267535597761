<template>
  <div
    :class="{
      'Board': true
    }"
    @pointerup="handleBoardEvents"
  >
    <warning-box
      v-if="warning"
      class="Board-warning"
      @close-warning-click="setWarning('')"
    >
      <span v-html="warning" />
    </warning-box>
    <div
      v-if="isAuthenticated"
      id="boardViewRef"
      data-target="board"
      class="Board"
      tabindex="0"
      @click="handleBoardEvents"
      @dblclick="handleBoardEvents"
      @pointerdown="handleBoardEvents"
      @pointermove="handleBoardEvents"
      @pointerup="handleBoardEvents"
      @mouseleave="handleBoardEvents"
      @wheel="onWheel"
      @keydown="keyPressAction"
      @keyup="keyUpAction"
      @contextmenu="(e)=> e.preventDefault()"
    >
      <board-view
        v-if="currentUser && collabSessionReady"
        class="Board-boardView"
        data-target="board"
        :class="{
          'Board-boardView--3A0': boardSize === boardSizes.BOARD_SIZE_3A0,
        }"
        :style="boardStyle"
        :state="{state}"
        :selection-area="isSelectionArea"
        :selection-position="selectionPosition"
        @close="closeEditor"
      />
    </div>
    <visual-link-contextual-menu
      v-if="isVisualLinkContextualMenuOpen"
    />
    <copy-paste-image
      v-if="canPasteImage"
    />
    <interaction-mode-selector
      :on-double-click-select="() => send('selectAll', {board: this})"
      :rollback-hot-keys="rollbackHotKeys"
    />
    <toolbar
      class="Board-toolbar"
      @pointermove.stop
      @wheel.stop
    />
    <main-menu
      class="Board-mainMenu"
      @pointermove.stop
      @wheel.stop
    />
    <board-element-editor-map
      v-if="isEditorOpen"
      :element-id="elementId"
      class="Board-boardElementEditor"
      @close="closeEditor"
      @pointermove.stop
      @wheel.stop
    />
    <div
      id="selection-area"
      ref="selectionArea"
      data-target="board"
      hidden
      @pointermove="handleBoardEvents"
      @dblclick="handleBoardEvents"
    />
    <version-history
      v-if="openedComponents.includes('VersionHistory')"
      class="Board-versionHistory"
    />
    <board-settings
      v-if="openedComponents.includes('BoardSettings')"
      class="Board-settings"
    />
    <dock
      class="Board-dock"
      @pointermove.stop
      @wheel.stop
    />
    <loading-page-layer
      v-if="!collabSessionReady"
      size="extraLarge"
      spinner-color="blue"
    />
    <creation-component-map
      v-if="openedComponents.includes(creationComponent)"
      class="Board-creationComponent"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BoardView from 'BOARD/components/BoardView/BoardView.vue';
import BoardElementEditorMap from 'BOARD_ELEMENT_EDITOR/BoardElementEditorMap.vue';
import Dock from 'DOCK/Dock.vue';
import InteractionModeSelector from 'BOARD/components/InteractionModeSelector/InteractionModeSelector';
import VisualLinkContextualMenu from 'BOARD/components/VisualLinkContextualMenu/VisualLinkContextualMenu.vue';
import MainMenu from 'MAIN_MENU/MainMenu.vue';
import Toolbar from 'TOOLBAR/Toolbar.vue';
import {
  BOARD_SIZE,
  BOARD_SIZES,
  MAIN_MENU_HEIGHT,
  INTERACTION_MODES,
  LINKS_TYPES
} from 'GLOBALS/constants.js';
import { removeCollabAwareness } from 'SRC/utils/collab';
import { keyUpActions, keyDownActions, rollbackActions} from 'BOARD/hot-keys-reducer';
import { buildHotKeyCombinationCode } from 'BOARD/utils/utils';
import eventsHandlers from 'BOARD/eventsHandlers';
import VersionHistory from 'BOARD/components/VersionHistory/VersionHistory.vue';
import BoardSettings from 'BOARD/components/BoardSettings/BoardSettings.vue';
import CreationComponentMap from 'BOARD/CreationComponentMap.vue';
import CopyPasteImage from 'BOARD/components/CopyPaste/CopyPasteImage.vue';

export default {
  name: 'IOBBoard',
  components: { CreationComponentMap, BoardView, BoardElementEditorMap, Dock, MainMenu, Toolbar,
    InteractionModeSelector, VersionHistory, VisualLinkContextualMenu, BoardSettings, CopyPasteImage },
  props: {
    send: {
      type: Function,
      default: () => {}
    },
    state: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      boardSize: BOARD_SIZE,
      boardSizes: BOARD_SIZES,
      panning: false,
      previousPanX: 0,
      previousPanY: 0,
      undoManager: {},
      elementId: '',
      isComponentOpen: false,
      isQuickPanning: false,
      x1: 0, y1: 0, x2: 0, y2: 0,
      isSelectionRectangle: false,
      isSelectionArea: false,
      selectionArea: {},
      selectionPosition: {},
      dataTarget: 'data-target',
      previousMode: null,
      previousCursor: null
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated'
    ]),
    ...mapGetters('app', ['getViewTypeSize']),
    ...mapGetters('board', ['getBoardElementDataType', 'getBoardElementViewType', 'getElementInitialPosition']),
    ...mapState('board', [
      'viewportReferencePoint',
      'dragging',
      'zoomLevel',
      'componentName',
      'selectedInteractionMode',
      'elements',
      'openedComponents',
      'lastBoardElementIdInEdition',
      'canSelect',
      'hotKeys',
      'userSelectedElements',
      'formulaInEditionBoardElementId',
      'boardElementsInEdition',
      'clickedElementId',
      'datasetElements',
      'warning',
      'isCursorLocked',
      'collabSessionReady',
      'link'
    ]),
    ...mapState('editor', ['isEditorOpen']),
    ...mapState('users', [
      'currentUser',
      'currentUserColor'
    ]),
    ...mapState('app', [
      'viewTypes',
      'dataTypes',
      'relationTypes'
    ]),
    ...mapState('relations', [
      'isVisualLinkContextualMenuOpen',
      'visualLinkBeingEdited',
      'dependencies',
      'visualLinks',
      'selectedVisualLink',
      'isSelectedVisualLink'
    ]),
    ...mapState('dock', [
      'creationComponent'
    ]),
    ...mapState({
      datasetElements: (state) => state.board.datasetElements,
      boardId: (state) => state.board.id,
      panX: (state) => state.board.panX,
      panY: (state) => state.board.panY,
      zoomLevel: (state) => state.board.zoomLevel,
      zoomLevelMin: (state) => state.board.zoomLevelMin,
      collabSessionReady: (state) => state.board.collabSessionReady
    }),
    boardStyle() {
      const scale = this.zoomLevel;
      const translateX = this.panX;
      const translateY = this.panY + (0.5 * MAIN_MENU_HEIGHT / this.zoomLevel);
      return `transform: scale(${scale}) translate(${translateX}px, ${translateY}px);`;
    },
    canPasteImage() {
      return !this.isEditorOpen && !this.openedComponents.length;
    }
  },
  watch: {
    isEditorOpen() {
      if (this.isEditorOpen) {
        this.setOpenedComponents([]);
      }
    },
    selectedInteractionMode: {
      handler(value) {
        if (value === INTERACTION_MODES.SELECT) {
          this.isQuickPanning = false;
        }
      },
      immediate: true
    },
    isSelectedVisualLink: {
      handler() {
        if (this.selectedVisualLink && this.isSelectedVisualLink) {
          const visualLink = document.getElementById(this.selectedVisualLink.id);
          this.setIsVisualLinkBeingEditedPosition(visualLink);
          this.setVisualLinkBeingEditedType(visualLink.id);
          this.setIsVisualLinkContextualMenuOpen(true);
          this.closeEditor();
          this.setSelectedVisualLink(null);
        }
      }
    }
  },
  created() {
    document.addEventListener('userClickedTool', this.onUserClickedTool);
    document.addEventListener('userClickedElement', this.onUserClickedElement);
    window.addEventListener('beforeunload', this.removeAwareness);
    window.addEventListener('blur', this.rollbackHotKeys);
  },
  beforeUnmount() {
    document.removeEventListener('userClickedTool', this.onUserClickedTool);
    document.removeEventListener('userClickedElement', this.onUserClickedElement);
    document.removeEventListener('beforeunload', this.removeAwareness);
    document.removeEventListener('blur', this.rollbackHotKeys);
    this.removeAwareness();
    this.resetEditor();
  },
  methods: {
    setPreviousMode(value) {
      this.previousMode = value;
    },
    setPreviousCursor(value) {
      this.previousCursor = value;
    },
    ...mapActions('app', [
      'fetchViewTypes',
      'fetchDataTypes',
      'fetchFormulas'
    ]),
    ...mapActions('users', [
      'fetchAllUsers',
      'fetchCurrentUser'
    ]),
    ...mapActions('board', [
      'addDatasetElementToStore',
      'initWebSocketProvider',
      'zoomInWithWheel',
      'zoomOutWithWheel',
      'notifyEditorOpen',
      'notifyEditorClosed',
      'updateViewportReferencePoint',
      'resetSelectedElements',
      'selectBoardElements',
      'removeSelection',
      'deleteUserSelectedElements',
      'selectAllBoardElements',
      'createBoardElement',
      'openFormulaEditor',
      'removeFormulaFromSelection',
      'setCursor',
      'processRelation'
    ]),
    ...mapActions('editor', [
      'initEditor',
      'resetEditor',
      'closeEditor',
      'openEditor'
    ]),
    ...mapMutations('editor', ['setIsEditorOpen']),
    ...mapMutations('board', [
      'addBoardElement',
      'setBoardId',
      'setPanX',
      'setPanY',
      'setCanUndo',
      'setCanRedo',
      'setOpenedComponents',
      'setSelectedInteractionMode',
      'addHotKey',
      'removeHotKey',
      'setHighlightedBoardElements',
      'resetHighlightedBoardElements',
      'setUnHighlightedBoardElements',
      'resetUnHighlightedBoardElements',
      'showContextualMenu',
      'hideContextualMenu',
      'setLastBoardElementIdInEdition',
      'setCanSelect',
      'resetCanSelect',
      'setFormulaInEditionBoardElementId',
      'unlockCursor',
      'setClickedElementId',
      'setLink',
      'resetLink',
      'setLinkSourceCoordinates',
      'setWarning',
      'resetBoardModule'
    ]),
    ...mapMutations('relations', [
      'setIsVisualLinkContextualMenuOpen',
      'setIsVisualLinkBeingEdited',
      'setSelectedVisualLinkType',
      'setIsSelectedVisualLink',
      'setSelectedVisualLink'
    ]),
    ...mapMutations('users', [
      'setUsersOnBoard',
      'setCurrentUserColor'
    ]),
    rollbackHotKeys() {
      this.hotKeys.forEach((e) => {
        rollbackActions[e](this);
        this.removeHotKey(e);
      });
    },
    removeAwareness() {
      this.resetSelectedElements();
      if (this.isEditorOpen) {
        this.closeEditor();
      } else if (this.formulaInEditionBoardElementId) {
        this.removeFormulaFromSelection(this.formulaInEditionBoardElementId);
      }
      removeCollabAwareness();
      this.rollbackHotKeys();
      this.resetBoardModule();
    },
    findAncestor(el, cls) {
      while (el && !el.classList.contains(cls)) {
        el = el.parentElement;
      }
      return el;
    },
    handleBoardEvents(event) {
      const target = event.target.getAttribute(this.dataTarget)
      || this.findAncestor(event.target, 'vue-apexcharts')?.getAttribute(this.dataTarget)
      || event.target?.closest('[data-list-item-id]')?.getAttribute(this.dataTarget);
      if (target && event.type && eventsHandlers[target] && eventsHandlers[target][this.selectedInteractionMode]
        && eventsHandlers[target][this.selectedInteractionMode][event.type]) {
        eventsHandlers[target][this.selectedInteractionMode][event.type](this, { event });
        return;
      }
      if (!target && event.type === 'pointerup') {
        const svg = document.getElementById('mouseFollower');
        if (svg) {
          this.resetLink();
          svg.remove();
        }
        eventsHandlers.board[this.selectedInteractionMode][event.type](this, {event});
      }
    },
    async onUserClickedTool(e) {
      const { viewType, datasetElementId, attributes } = e.detail;
      const size = this.getViewTypeSize(viewType.id);
      const requestBody = {
        boardId: this.boardId,
        viewTypeId: viewType.id,
        position: this.getElementInitialPosition(size),
        width: size.width,
        height: size.height
      };
      const { dataTypeName } = viewType;
      this.elementId  = await this.createBoardElement({requestBody, viewType, datasetElementId, dataTypeName, attributes});
      if (this.selectedInteractionMode === INTERACTION_MODES.PANNING) {
        this.setSelectedInteractionMode({
          value: INTERACTION_MODES.SELECT
        });
      }
    },
    async onUserClickedElement({detail}) {
      const element = this.elements[detail.elementId];
      this.elementId = element.id;
      if (this.dataTypes[this.datasetElements[element.datasetElement.id].typeId].namespace.includes('/element-data-types/widgets')) {
        if (this.formulaInEditionBoardElementId) {
          await this.removeFormulaFromSelection(this.formulaInEditionBoardElementId);
        }
        this.openFormulaEditor({boardElementId: element.id});
      } else {
        this.openEditor({datasetElementId: element.datasetElement.id, boardElementId: element.id});
      }
    },
    setIsVisualLinkBeingEditedPosition(visualLink) {
      const linkAttrs = {
        id: visualLink.id,
        top: visualLink.getBoundingClientRect().top,
        left: visualLink.getBoundingClientRect().left,
        width: visualLink.clientWidth, height: visualLink.clientHeight
      };
      let visualLinkBeingEdited = this.visualLinkBeingEdited;
      visualLinkBeingEdited = { ...visualLinkBeingEdited, ...linkAttrs };
      this.setIsVisualLinkBeingEdited(visualLinkBeingEdited);
    },
    setVisualLinkBeingEditedType(id) {
      let linkTypeData = this.relationTypes.find((relationType) => relationType.type === LINKS_TYPES.HierarchicalRelationType);
      const relationId = this.visualLinks[id]?.relationId ;
      const relationTypeId = this.dependencies[relationId]?.relationTypeId;
      if (relationTypeId) {
        linkTypeData = this.relationTypes.find((relationType) => relationType.id === relationTypeId);
      }
      this.setSelectedVisualLinkType(linkTypeData);
    },
    onWheel(event) {
      if (this.isVisualLinkContextualMenuOpen) {
        const visualLinkBeingEdited = this.visualLinkBeingEdited;
        const visualLink = document.getElementById(visualLinkBeingEdited.id);
        this.setIsVisualLinkBeingEditedPosition(visualLink);
      }
      if (event.deltaY < 0) {
        this.zoomInWithWheel(event);
      } else {
        this.zoomOutWithWheel(event);
      }
    },
    getEventExpression: (event) => ({ '3': 'rightMouse', '2': 'middleClick'}[event.which]),
    keyPressAction(event) {
      const target = event.target?.getAttribute(this.dataTarget);
      if (!['board', 'board-element'].includes(target)) {
        return;
      }
      const hotkeys = event.composed ? buildHotKeyCombinationCode(event) : event.key;
      if (keyDownActions[hotkeys]) {
        this.addHotKey(hotkeys);
        keyDownActions[hotkeys](this, event);
      }
    },
    keyUpAction(event) {
      const target = event.target?.getAttribute(this.dataTarget);
      if (!['board', 'board-element'].includes(target)) {
        return;
      }
      const hotkeys = event.composed ? buildHotKeyCombinationCode(event) : event.key;
      if (keyUpActions[hotkeys]) {
        this.removeHotKey(hotkeys);
        keyUpActions[hotkeys](this);
      }
    }

  }
};
</script>
<style src='./Board.css' />
