import { toRaw } from 'vue';

/**
 * Adds a title element to the dropdown data based on the type name.
 * @param {array} elements
 * @param {string} typeName
 * @returns {array}
 */
export const addTitleToDropdownData = (elements, typeName) => {
  elements = elements.filter((item) => item.type !== 'title');
  elements.unshift({
    title: `Recent ${typeName}s`,
    type: 'title',
    color: 'var(--gray-500, #787391)'
  });
  return elements;
};

/**
 * Returns the data with the required format.
 * @param {array} data
 * @returns {array}
 */
export const formatDatasetElements = (data) =>
  Object.values(data).map((item) => ({
    title: item.attributes['friendly-id'],
    text: item.attributes.title || '',
    id: item.id,
    attributes: item.attributes,
    typeId: item.typeId,
    shapeColor: item.attributes.color
  }));

export const checkIfStringStartsWithVowal = (value) => /^[aeiou]/i.test(value) ? 'an' : 'a';

export const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

/**
 * Transforms the value of a field based on its component type.
 * @param {Object} field - The field to transform.
 * @returns {*} The transformed field value.
 */
export const transformFieldValue = (field) => {
  let fieldValue = toRaw(field.value);

  if (['timeframe', 'dateRange'].includes(field.componentType)) {
    fieldValue = mapFieldValue(fieldValue, (item) => item.date);
  } else if (field.componentType === 'avatarList' || field.id) {
    fieldValue = mapFieldValue(fieldValue, (item) => item.id);
  }
  if (Array.isArray(fieldValue)) {
    fieldValue = mapFieldValue(fieldValue, (item) => item.id || item.text || item);
  }
  return fieldValue;
};

/**
 * Maps a field value using a transform function.
 * @param {*} fieldValue - The field value to map.
 * @param {Function} transformFunc - The function to use to transform the field value.
 * @returns {*} The mapped field value.
 */
export const mapFieldValue = (fieldValue, transformFunc) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue.map(transformFunc);
  }
  return fieldValue;
};

/**
 * Checks if a field value is empty.
 * @param {*} fieldValue - The field value to check.
 * @returns {boolean} True if the field value is empty, false otherwise.
 */
export const isFieldValueEmpty = (fieldValue) => (Array.isArray(fieldValue) && fieldValue.length === 0) ||
 (!Array.isArray(fieldValue) && !fieldValue);

/**
 * Adds a new filter to the form fields and removes it from the dropdown filter.
 * @param {Object} item - The filter to add.
 * @param {Array} formFields - The form fields to add the filter to.
 * @param {Array} dropdownToFilter - The dropdown filter to remove the filter from.
 */
export const addOtherFilter = (item, formFields, dropdownToFilter) => {
  formFields.push({
    name: item.name,
    label: item.label,
    componentType: item.componentType,
    options: item.options,
    value: []
  });
  const index = dropdownToFilter.indexOf(item.name);
  if (index > -1) {
    dropdownToFilter.splice(index, 1);
  }
};

/**
 * Updates the value of a date tag in the form data.
 * @param {string} fieldName - The name of the field to update.
 * @param {Array} attributes - The new attributes for the date tag.
 * @param {Object} formData - The form data to update.
 */
export const removeDateTag = (fieldName, attributes, formData) => {
  const index = formData.fields.findIndex((field) => field.name === fieldName);
  if (index !== -1) {
    formData.fields[index].value = attributes.map((attribute) => ({
      date: attribute.date,
      text: attribute.text
    }));
  }
};

/**
 * Adds a new date tag to the form data if it doesn't already exist.
 * @param {string} fieldName - The name of the field to add the date tag to.
 * @param {Object} attributes - The attributes for the date tag.
 * @param {Object} formData - The form data to add the date tag to.
 */
export const getDateTag = (fieldName, attributes, formData) => {
  const index = formData.fields.findIndex((field) => field.name === fieldName);
  if (index !== -1) {
    const doesValueExist = formData.fields[index].value.some(
      (item) => item.text === attributes.label
    );
    if (!doesValueExist) {
      formData.fields[index].value.unshift({
        date: attributes.date,
        text: attributes.label
      });
    }
  }
};
