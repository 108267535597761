import { get, post, del } from 'SRC/api';
import editorGetters from 'SRC/store/editor/getters';
import { PAGE_SIZE, DATASET_ELEMENTS_PAGE_SIZE } from 'GLOBALS/constants';
import apiUtils from 'SRC/store/utils';

const actions = {
  async fetchHierarchicalRelations({ rootState }, datasetElementsIds) {
    const queryString = datasetElementsIds.reduce((acc, id, index) => {
      if (index === datasetElementsIds.length - 1) {
        return `${acc}datasetElementIds=${id}`;
      }
      return `${acc}datasetElementIds=${id}&`;
    }, '?');
    const allElementsHierarchies = await get(`/hierarchical-relations${queryString}`);
    if (!(allElementsHierarchies && allElementsHierarchies.data)) {
      return;
    }

    const boardElements = Object.values(rootState.board.elements);
    if (boardElements.length === 0) {
      return null;
    }
  },
  async fetchDatasetElementDependencies({ rootState, commit, rootGetters }, datasetElementId) {
    const response = await get(`/dataset-elements/${datasetElementId}/relations`);
    if (response && response.data) {
      const dependencies = response.data;
      const newDatasetElements = dependencies.map(({relatedDatasetElementId}) => {
        if (!rootState.board.datasetElements[relatedDatasetElementId]) {
          return relatedDatasetElementId;
        }
      }).filter((id) => id);
      let datasetElementsData = [];
      let relatedDatasetElements = [];
      if (newDatasetElements.length) {
        let datasetElementsInfo = [];
        const query = newDatasetElements.map((id) => `ids=${id}`).join('&');
        datasetElementsInfo = await get(
          `/dataset-elements?page=1&size=${PAGE_SIZE}&${query}`
        );
        if (datasetElementsInfo && datasetElementsInfo.data) {
          datasetElementsData = datasetElementsInfo.data;
          datasetElementsData = await apiUtils.getNextPage(
            datasetElementsInfo,
            datasetElementsData,
            get
          );
        }
      }
      relatedDatasetElements = datasetElementsData.map((id) => editorGetters.getRelationObject(rootGetters)(id));
      const datasetElement = editorGetters.getRelationObject(rootGetters)(rootState.board.datasetElements[datasetElementId]);
      const fromattedDependencies = dependencies.map((dependency) => {
        const relatedDatasetElement = rootState.board.datasetElements[dependency.relatedDatasetElementId] ?
          editorGetters.getRelationObject(rootGetters)(rootState.board.datasetElements[dependency.relatedDatasetElementId]) :
          relatedDatasetElements.find((el) => el.id === dependency.relatedDatasetElementId);
        const dependencyAttrs = {datasetElement, relatedDatasetElement, checked: false};
        return {...dependency, ...dependencyAttrs};
      });
      commit('setCreatedDependencies', fromattedDependencies);
    }
  },
  async searchIndicatorDependencies({ commit }, { query, dataTypeId }) {
    try {
      const body = {
        attributes: {}
      };
      query += `&dataTypeIds=${dataTypeId}`;
      const response = await post(`/dataset-elements/search-requests?search=${query}&page=1&size=${DATASET_ELEMENTS_PAGE_SIZE}`, body);
      const datasetElements = response.data.reduce((acc, datasetElement) => {
        acc[datasetElement.id] = datasetElement;
        return acc;
      }, {});

      commit('setSearchResults', datasetElements);
      return datasetElements;
    } catch (error) {
      console.error('Error occured in searchDatasetElements', error);
      return { error: 'Error occured in searchDatasetElements' };
    }
  },
  async createDependenciesWithExistingElements(
    { state, rootState, commit, rootGetters },
    { elements, relationTypeId }
  ) {
    const datasetElementId = rootState.editor.datasetElementId;
    const requestBody = elements.map((element) => ({
      sourceDatasetElementId: datasetElementId,
      targetDatasetElementId: element.id,
      relationTypeId
    }));

    const creations = await post('/relations', requestBody);
    if (creations && creations.data) {
      const createdDependencies = creations.data.filter(({ status }) => status === 200);
      const fromattedCreatedDependencies = createdDependencies.map((dependency) => {
        commit('addDependency', {dependencyId: dependency.id, dependency});
        if (dependency.visualLinks && dependency.visualLinks.length) {
          dependency.visualLinks.forEach((link) => {
            commit('addVisualLink', {visualLinkId: link.id, visualLink: link});
          });
        }
        const datasetElement =  editorGetters.getRelationObject(rootGetters)(rootState.board.datasetElements[dependency.sourceDatasetElementId]);
        const isDependencyInBoard = !!rootState.board.datasetElements[dependency.targetDatasetElementId];
        let relatedDatasetElement = null;
        if (isDependencyInBoard) {
          relatedDatasetElement = editorGetters
            .getRelationObject(rootGetters)(rootState.board.datasetElements[dependency.targetDatasetElementId]);
        } else {
          relatedDatasetElement = state.searchResults[dependency.targetDatasetElementId];
        }
        return { ...dependency, datasetElement, relatedDatasetElement, checked: false };
      });
      commit('setCreatedDependencies', state.createdDependencies.concat(fromattedCreatedDependencies));
      return true;
    }
    return false;
  },
  async createDependencyWithNewDatasetElement(
    { state, rootState, commit, rootGetters },
    { title, relationTypeId, dataTypeId }
  ) {
    const datasetElementId = rootState.editor.datasetElementId;
    const requestBody = {
      title,
      dataTypeId,
      relationTypeId
    };

    const result = await post(
      `/dataset-elements/${datasetElementId}/relations`,
      requestBody
    );
    if (result) {
      const { id, dependency } = result;
      const relatedDatasetElementInfo = await get(`/dataset-elements/${id}`);
      const relatedDatasetElement = editorGetters.getRelationObject(rootGetters)(relatedDatasetElementInfo);
      const datasetElement = editorGetters.getRelationObject(rootGetters)(rootState.board.datasetElements[datasetElementId]);
      const newDependency = { ...dependency, datasetElement, relatedDatasetElement, checked: false };
      commit('setCreatedDependencies', state.createdDependencies.concat(newDependency));
      return datasetElement;
    }
    return null;
  },
  async deleteDependencyAndRelatedLinks({ state, commit, dispatch, rootState }, { id }) {
    await del(`/relations/${id}`);
    const { datasetElementId } = rootState.editor;
    dispatch('fetchDatasetElementDependencies', datasetElementId);
    commit('updateDependency', {
      dependencyId: id,
      updates: { deleted: true }
    });
    Object.values(state.visualLinks).forEach((link) => {
      if (link.relationId === id && link.id) {
        commit('updateVisualLink', {
          visualLinkId: link.id,
          updates: { deleted: true }
        });
      }
    });
  },
  async deleteDependenciesAndRelatedLinks({ state, commit }, elements) {
    const result = await del('/relations', elements);
    if (result && result.data) {
      const deletedDependencies = result.data.filter(({ status }) => status === 204);
      if (!deletedDependencies.length) {
        return false;
      }
      deletedDependencies.forEach(({id}) => {
        commit('updateDependency', {
          dependencyId: id,
          updates: { deleted: true }
        });
        const visualLinks = Object.values(state.visualLinks);
        visualLinks.forEach((link) => {
          if (link.relationId === id) {
            commit('updateVisualLink', {
              visualLinkId: link.id,
              updates: { deleted: true }
            });
          }
        });
      });
      const dependencies = state.createdDependencies;
      const filteredDependencies = dependencies.filter((dependency) =>
        !deletedDependencies.find((deletedDependency) => deletedDependency.id === dependency.id));
      commit('setCreatedDependencies', filteredDependencies);
      return true;
    }
    return false;
  }
};

export default actions;
