<template>
  <div
    :style="moveStyle"
    class="Movable"
    tabindex="0"
    data-target="board-element"
    @pointerdown="onPointerDown"
    @focusout="handleFocusOut"
  >
    <slot />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import { INTERACTION_MODES } from 'GLOBALS/constants';
import { targetMap } from './resizeUtils';

export default {
  name: 'MovableElement',
  props: {
    position: {
      type: Object,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    noElementDrag: {
      type: Boolean,
      required: false,
      default: false
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    noInteractions: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    setIsResizing: {
      type: Function,
      required: true
    },
    homotheticResize: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click', 'drag'],
  data() {
    return {
      DRAG_THRESHOLD: 3,
      pointerDown: false,
      firstClick: {
        x: null,
        y: null
      },
      lastClick: {
        x: null,
        y: null
      },
      offset: {
        x: 0,
        y: 0
      },
      currentPointerPosition: {
        x: null,
        y: null
      },
      isMoveElement: false,
      ispointerMoveWhilePointerDown: false,
      target: null
    };
  },
  computed: {
    ...mapState('board', [
      'dragging',
      'zoomLevel',
      'isMultipleMove',
      'selectedInteractionMode',
      'translation'
    ]),
    moveStyle() {
      let transform = '';
      if (this.isSelected && this.isMultipleMove) {
        transform = `translate(${this.translation.x}px, ${this.translation.y}px)`;
      }
      return {
        top: `${this.position.y}px`,
        left: `${this.position.x}px`,
        transform
      };
    }
  },
  beforeUnmount() {
    document.removeEventListener('pointermove', this.onPointerMove);
    document.removeEventListener('pointerup', this.onPointerUp);
    document.removeEventListener('pointerleave', this.onPointerUp);
  },
  methods: {
    ...mapMutations('board', [
      'startDragging',
      'stopDragging',
      'setIsMultipleMove',
      'setTranslation',
      'updateBoardElement'
    ]),
    ...mapActions('board', [
      'notifyBoardElementMoveStarted',
      'notifyBoardElementMoveFinished',
      'resetSelectedElements',
      'updateBoardElementPosition'
    ]),
    onPointerDown(event) {
      const target = event.target?.getAttribute('data-target');
      const isQuickPanning = [3, 2].includes(event.which);
      if (this.noInteractions || isQuickPanning) {
        return;
      }
      if (!this.noElementDrag && !isQuickPanning) {
        event.stopPropagation();
      }
      if (
        this.isSelected &&
        this.selectedInteractionMode === INTERACTION_MODES.SELECT
      ) {
        this.setIsMultipleMove(true);
      }
      this.pointerDown = true;
      this.firstClick.x = event.clientX;
      this.firstClick.y = event.clientY;
      this.lastClick.x = event.clientX;
      this.lastClick.y = event.clientY;
      this.initPosition = {
        x: this.position.x,
        y: this.position.y
      };
      if (this.noElementDrag || isQuickPanning) {
        document.addEventListener('pointermove', this.pointerMoveOnPan);
      }
      if (target && target.includes('knob')) {
        this.target = target;
        if (!this.noElementDrag && !isQuickPanning) {
          event.stopPropagation();
          this.setIsResizing(true);
          document.addEventListener('pointermove', this.knobOnPointerMove);
        }
      } else if (!this.noElementDrag && !isQuickPanning) {
        document.addEventListener('pointermove', this.onPointerMove);
      }
      const pointerUpAction =
        target && target.includes('knob')
          ? (event) => this.onPointerUp(event, 'knob')
          : this.onPointerUp;
      document.addEventListener('pointerup', pointerUpAction);
      document.addEventListener('pointerleave', pointerUpAction);
    },
    onPointerUp(event, target = 'card') {
      this.setIsResizing(false);
      this.setTranslation({ x: 0, y: 0 });
      if (this.isMultipleMove) {
        this.setIsMultipleMove(false);
      }
      if (this.pointerDown && !this.ispointerMoveWhilePointerDown) {
        this.pointerDown = false;
        if (this.dragging) {
          this.stopDragging();
          this.notifyBoardElementMoveFinished(this.elementId);
          this.lastClick.x = null;
          this.lastClick.y = null;
          this.firstClick.x = null;
          this.firstClick.y = null;
          // Reset the offset to avoid adding it to next movement
          this.offset.x = 0;
          this.offset.y = 0;
        } else {
          this.$emit('click', {
            shiftKey: event.shiftKey,
            ctrlKey: event.ctrlKey,
            x: event.clientX,
            y: event.clientY
          });
        }
      }
      this.isMoveElement = false;
      this.ispointerMoveWhilePointerDown = false;
      if (target && target.includes('knob')) {
        document.removeEventListener('pointermove', this.knobOnPointerMove);
        document.removeEventListener('pointerup', this.knobOnPointerUp);
        document.removeEventListener('pointerleave', this.knobOnPointerUp);
        this.target = null;
        return;
      }
      document.removeEventListener('pointermove', this.onPointerMove);
      document.removeEventListener('pointerup', this.onPointerUp);
      document.removeEventListener('pointerleave', this.onPointerUp);
      document.removeEventListener('pointermove', this.pointerMoveOnPan);
    },
    pointerMoveOnPan() {
      this.ispointerMoveWhilePointerDown = true;
    },
    onPointerMove(event) {
      if (this.noElementDrag) {
        return;
      }
      if (!this.isMoveElement) {
        if (!this.isSelected) {
          this.resetSelectedElements();
        }
        this.isMoveElement = true;
      }
      event.preventDefault();
      if (this.pointerDown) {
        this.currentPointerPosition.x = event.clientX;
        this.currentPointerPosition.y = event.clientY;
        if (this.dragging) {
          this.move(event);
          this.$emit('drag', this.getNewPosition());
        } else if (this.detectDragging()) {
          this.startDragging();
          this.notifyBoardElementMoveStarted(this.elementId);
          this.move(event);
        }
      }
    },
    detectDragging() {
      const initOffsetX = this.currentPointerPosition.x - this.firstClick.x;
      const initOffsetY = this.currentPointerPosition.y - this.firstClick.y;
      return (
        Math.abs(initOffsetX) > this.DRAG_THRESHOLD ||
        Math.abs(initOffsetY) > this.DRAG_THRESHOLD
      );
    },
    move(event) {
      const offsetX =
        (this.currentPointerPosition.x - this.lastClick.x) / this.zoomLevel;
      const offsetY =
        (this.currentPointerPosition.y - this.lastClick.y) / this.zoomLevel;

      this.offset.x += offsetX;
      this.offset.y += offsetY;
      const tr = {
        x: this.translation.x + offsetX,
        y: this.translation.y + offsetY
      };
      this.setTranslation(tr);
      this.lastClick.x = event.clientX;
      this.lastClick.y = event.clientY;
    },
    getNewPosition() {
      return {
        top: this.initPosition.y + this.offset.y,
        left: this.initPosition.x + this.offset.x,
        translation: this.translation
      };
    },
    knobOnPointerMove(event) {
      if (this.noElementDrag) {
        return;
      }
      event.preventDefault();
      if (this.pointerDown) {
        this.currentPointerPosition.x = event.clientX;
        this.currentPointerPosition.y = event.clientY;
        if (this.dragging) {
          this.resize(event);
          this.$emit('drag', this.getNewPosition());
        } else if (this.detectDragging()) {
          this.startDragging();
          this.resize(event);
        }
      }
    },
    resize(event) {
      const offsetX =
        (this.currentPointerPosition.x - this.lastClick.x) / this.zoomLevel;
      const offsetY =
        (this.currentPointerPosition.y - this.lastClick.y) / this.zoomLevel;

      this.offset.x += offsetX;
      this.offset.y += offsetY;
      const tr = {
        x: this.translation.x + offsetX,
        y: this.translation.y + offsetY
      };

      const { newElementX, newElementY, newWidth, newHeight } = targetMap[
        this.target
      ](
        tr,
        this.position,
        this.height,
        this.width,
        this.homotheticResize
      )(event.shiftKey);
      this.updateBoardElement({
        elementId: this.elementId,
        updates: {
          position: {
            x: newElementX,
            y: newElementY
          },
          height: newHeight,
          width: newWidth
        }
      });
      this.lastClick.x = event.clientX;
      this.lastClick.y = event.clientY;
    }
  }
};
</script>

<style scoped>
.Movable {
  position: relative;
  outline: none;
}
</style>
