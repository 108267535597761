const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setToolsets(state, toolsets) {
    state.toolsets = toolsets;
  },
  setCreationComponent(state, creationComponent) {
    state.creationComponent = creationComponent;
  },
  setDisplayComponent(state, displayComponent) {
    state.displayComponent = displayComponent;
  },
  setDataTypeId(state, dataTypeId) {
    state.dataTypeId = dataTypeId;
  },
  setViewTypeId(state, viewTypeId) {
    state.viewTypeId = viewTypeId;
  },
  setDatasetElements(state, datasetElements) {
    state.datasetElements = datasetElements;
  },
  setIsCreating(state, isCreating) {
    state.isCreating = isCreating;
  }
};

export default mutations;
