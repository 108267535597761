<template>
  <div
    data-modal-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    class="IobDialogBox"
  >
    <div class="IobDialogBox-container">
      <div class="IobDialogBox-header" />
      <div class="IobDialogBox-body">
        <p class="IobDialogBox-body-title">
          {{ title }}
        </p>
        <p class="IobDialogBox-body-content">
          {{ content }}
        </p>
      </div>
      <div class="IobDialogBox-footer">
        <iob-button
          rounded="false"
          size="medium"
          color="secondarySoft"
          type="filled"
          :disabled="false"
          :label="cancelAction"
          @click="$emit('cancelAction')"
        />
        <iob-button
          size="medium"
          color="alert"
          type="filled"
          rounded="false"
          :label="submitAction"
          @click="$emit('submitAction')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import IobButton from '../IobButton/IobButton.vue';
defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  content: {
    type: String,
    default: 'Content',
  },
  submitAction: {
    type: String,
    default: 'cancel',
  },
  cancelAction: {
    type: String,
    default: 'submit',
  },
});

</script>

<style lang="scss" scoped src="./IobDialogBox.scss" />