import { INTERACTION_MODES } from 'GLOBALS/constants';
import { keyUpActions, keyDownActions } from 'BOARD/hot-keys-reducer';

export const pointerup = async (board, { event }) => {
  if (board.isSelectionRectangle && board.selectedInteractionMode === INTERACTION_MODES.SELECT) {
    board.send('mouseUp', { board, event });
    return;
  } else if ([3, 2].includes(event.which) && board.isQuickPanning) {
    const eventExpression = board.getEventExpression(event);
    await board.removeHotKey(eventExpression);
    keyUpActions[eventExpression](board);
  }
  board.send('mouseUp', { board, event });
};

const pointermove = (board, { event }) => {
  board.send('mouseDrag', { event, board });
};

const eventsHandlers = {
  [INTERACTION_MODES.SELECT]: {
    click: () => { },
    dblclick: (board) => {
      board.closeEditor();
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    },
    pointerdown: async (board, { event }) => {
      if ([3, 2].includes(event.which)) {
        const eventExpression = board.getEventExpression(event);
        board.addHotKey(eventExpression);
        await keyDownActions[eventExpression](board);
        board.send('pressOnBoard', { board, event });
      } else if (event.composedPath()
        && event.composedPath().find((el) => el && el.id === 'deleteElements')) {
        return board.deleteUserSelectedElements();
      } else {
        board.isSelectionRectangle = true;
        board.x1 = event.clientX; //Set the initial X
        board.y1 = event.clientY; //Set the initial Y
        board.x2 = event.clientX; //Set the initial X
        board.y2 = event.clientY; //Set the initial Y
        board.$refs.selectionArea.style.backgroundColor = 'rgba(51, 104, 246, 0.6)';
        board.send('pressOnBoard', { board, event });
      }
    },
    pointermove: (board, { event }) => {
      board.setCursor({ value: 'default', lock: false });
      pointermove(board, { event });
    },
    pointerup
  },
  [INTERACTION_MODES.PANNING]: {
    click: (board) => {
      board.unlockCursor();
      board.setCursor({ value: 'grab', lock: false });
      board.send('escape', { board });
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    },
    dblclick: (board) => {
      board.closeEditor();
    },
    pointerdown: async (board, { event }) => {
      board.send('pressOnBoard', { board, event });
    },
    pointermove: (board, { event }) => {
      board.setCursor({ value: 'grab', lock: false });
      pointermove(board, { event });
      board.setIsVisualLinkContextualMenuOpen(false);
    },
    pointerup
  },
  [INTERACTION_MODES.LINKS]: {
    click: () => {
    },
    dblclick: () => { },
    pointerdown: async (board, { event }) => {
      if ([3, 2].includes(event.which)) {
        const eventExpression = board.getEventExpression(event);
        board.addHotKey(eventExpression);
        await keyDownActions[eventExpression](board);
        board.send('pressOnBoard', { board, event });
      }
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    },
    pointermove: (board, { event }) => {
      board.unlockCursor();
      board.setCursor({ value: 'crosshair', lock: false });
      pointermove(board, { event });
    },
    pointerup: async () => {
    }
  }
};
export default eventsHandlers;
