<template>
  <div
    class="Card"
    @mouseleave="setIsHovered(false)"
    @mouseover="setIsHovered(true)"
  >
    <div
      v-if="isHighlighted"
      data-target="board-element"
      class="Card-highlighted"
    />
    <card-compartment
      id="upperPart"
      :attributes="attributes"
      :fields="upperFields"
      :friendly-id-text-style="friendlyIdTextStyle"
      :bg-color="bgColor"
      :has-color="hasColor"
      :grid-size="upperGridSize"
      :style="upperPartStyle"
      :text-style="upperPartTextStyle"
      part="upper"
    />
    <card-compartment
      v-if="lowerFields && lowerFields.length > 0"
      id="lowerPart"
      :attributes="attributes"
      :fields="lowerFields"
      :height="height"
      :width="width"
      :bg-color="bgColor"
      :has-color="hasColor"
      :grid-size="lowerGridSize"
      :text-style="lowerPartTextStyle"
      :field-style="lowerPartFieldStyle"
      :lower-part-fields-size="lowerPartFieldsSize"
      :lower-part-icon-size="lowerPartIconSize"
      part="lower"
      :style="lowerPartStyle"
    />
    <div
      v-for="index in additionalDots"
      :id="`${elementId}-p-${index}insider`"
      :key="index"
      :style="getDotPlacement(index, additionalDots ? additionalDots.length : 0)"
    />
  </div>
</template>

<script>
import CardCompartment from 'BOARD/components/CardCompartment/CardCompartment.vue';
import { getContrastedColor, hextToInt, calculateScaledValue } from 'BOARD/utils/utils';
import {
  DEFAULT_CARD_TEXT_COLOR, CARD_LOWER_FIELDS_FONT_SIZES, CARD_UPPER_FIELDS_FONT_SIZES,
  CARD_UPPER_FIELDS_LINE_HEIGHTS, CARD_LOWER_FIELDS_LINE_HEIGHTS,
  CARD_LOWER_FIELDS_ICON_SIZE,
  CARD_LOWER_FIELDS_COMPONENT_SIZE,
  CARD_FRIENDLY_ID_FONT_SIZE
} from 'GLOBALS/constants';

export default {
  name: 'IOBCard',
  components: {
    CardCompartment
  },
  props: {
    fields: {
      type: Array,
      default: () => ([{ originRef: '/vms/title' }])
    },
    canBeHighlighted: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: ''
    },
    hasColor: {
      type: Object,
      required: true
    },
    additionalDots: {
      type: Array,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    attributes: {
      type: Object,
      default: () => { }
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isHovered: false
  }),
  computed: {
    contrastedColor() {
      if (!this.hasColor || !this.bgColor) {
        return '';
      }
      const bgColorInt = hextToInt(this.bgColor);
      return getContrastedColor(bgColorInt, DEFAULT_CARD_TEXT_COLOR.light, DEFAULT_CARD_TEXT_COLOR.dark);
    },
    upperPartStyle() {
      return `height: ${this.upperPartPercentage * this.height}px; padding-bottom: 8px !important; width: ${this.width}px;
        padding: ${calculateScaledValue(this.width, this.height, 8)}px ${calculateScaledValue(this.width, this.height, 16)}px
      `;
    },
    lowerPartStyle() {
      return `background-color: #FFF !important; height: ${(1 - this.upperPartPercentage) * this.height}px; width: ${this.width}px;
        padding: ${calculateScaledValue(this.width, this.height, 4)}px ${calculateScaledValue(this.width, this.height, 8)}px
      `;
    },
    friendlyIdTextStyle() {
      return `
        font-size: ${calculateScaledValue(this.width, this.height, CARD_FRIENDLY_ID_FONT_SIZE)}px;
        line-height: ${calculateScaledValue(this.width, this.height, CARD_FRIENDLY_ID_FONT_SIZE)}px;
        color: ${this.contrastedColor};
        font-family: Noto Sans;
        font-weight: 400;
      `;
    },
    upperPartTextStyle() {
      return {
        level2: `
          font-size: ${calculateScaledValue(this.width, this.height, CARD_UPPER_FIELDS_FONT_SIZES.level2)}px;
          color: ${this.contrastedColor};
          font-family: Noto Sans;
          font-weight: 600;
          line-height: ${calculateScaledValue(this.width, this.height, CARD_UPPER_FIELDS_LINE_HEIGHTS.level2)}px;
        `,
        level3: `
          font-size: ${calculateScaledValue(this.width, this.height, CARD_UPPER_FIELDS_FONT_SIZES.level3)}px;
          color: ${this.contrastedColor};
          font-family: Noto Sans;
          font-weight: 600;
          line-height: ${calculateScaledValue(this.width, this.height, CARD_UPPER_FIELDS_LINE_HEIGHTS.level3)}px;
        `
      };
    },
    lowerPartTextStyle() {
      return {
        level2: `
          font-size: ${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_FONT_SIZES.level2)}px;
          line-height: ${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_LINE_HEIGHTS.level2)}px;
          color: #333044;
          font-family: Noto Sans;
          font-weight: 400
        `,
        level3: `
          font-size: ${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_FONT_SIZES.level3)}px;
          line-height: ${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_LINE_HEIGHTS.level3)}px;
          color: #333044;
          font-family: Noto Sans;
          font-weight: 400
        `
      };
    },
    lowerPartFieldStyle() {
      return {
        level2: { padding: `${calculateScaledValue(this.width, this.height, 8)}px` },
        level3: { padding: `0px ${calculateScaledValue(this.width, this.height, 8)}px` }
      };
    },
    lowerPartFieldsSize() {
      return {
        level2: `${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_COMPONENT_SIZE.level2)}px;`,
        level3: `${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_COMPONENT_SIZE.level3)}px;`
      };
    },
    lowerPartIconSize() {
      return {
        level2: `${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_ICON_SIZE.level2)}`,
        level3: `${calculateScaledValue(this.width, this.height, CARD_LOWER_FIELDS_ICON_SIZE.level3)}`
      };
    },
    titleField() {
      return this.fields.find((f) => f.originRef === '/vms/title');
    },
    lowerFields() {
      const titleEnd = this.titleField.h + this.titleField.y;
      const lowerFields = this.fields.filter((f) => f.y >= (titleEnd - 1));
      if (lowerFields && lowerFields.length > 0) {
        const transformedLowerFields = lowerFields.map((el) => ({ ...el, y: el.y - titleEnd + 1 }));
        return transformedLowerFields;
      }
      return [];
    },
    upperFields() {
      if (!this.lowerFields) {
        return this.fields;
      }
      const notInLowerLayout = (field) => !this.lowerFields.find((f) => f.fieldName === field.fieldName);
      const uF = this.fields.filter(notInLowerLayout);
      return uF;
    },
    upperPartPercentage() {
      return (this.titleField.h + this.titleField.y - 1) / 6;
    },
    upperGridSize() {
      return {
        cols: 8,
        rows: this.titleField.h + this.titleField.y - 1
      };
    },
    lowerGridSize() {
      return {
        cols: 8,
        rows: 6 - (this.titleField.h + this.titleField.y - 1)
      };
    },
    isHighlighted() {
      return this.isHovered && this.canBeHighlighted;
    }
  },
  methods: {
    setIsHovered(value) {
      this.isHovered = value;
    },
    getDotPlacement(index, additionalDots) {
      const offset = additionalDots < 10 ? 10 : 100 / additionalDots;
      let top = 0;
      const i = Math.floor(index / 2) + 1;
      if (index % 2 === 0) {
        top = (50 + (offset / 2)) - Number(i) * offset;
      } else {
        top = (50 - (offset / 2)) + Number(i) * offset;
      }
      return `position: absolute; left: 0%; top:${top}%; width:100%`;
    }
  }
};
</script>

<style scoped src='./Card.css' />
