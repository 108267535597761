<template>
  <outside-click-listener @outside-click="closeEditor">
    <div class="EmbedEditor">
      <div class="EmbedEditor-header">
        <h1 class="EmbedEditor-header-title">
          {{ $t('embed.editor.title') }}
        </h1>
        <iob-action-button
          icon="close"
          size="default"
          color="secondary"
          type="ghost"
          @click="closeEditor"
        />
      </div>
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-name">URL</label>
        </div>
        <iob-label-field
          :model-value="url"
          :placeholder="$t('embed.editor.urlPlaceholder') "
          has-clear-button
          :has-error="!urlIsValid"
          @update:modelValue="(value) => handleChange('url', value.trim())"
        />
        <div class="form-group-message">
          <IconLoader
            v-if="urlIsValid"
            name="AlertCircle"
            color="#2D6BFF"
            size="xsmall"
          />
          <span :class="urlIsValid ? 'form-group-warning-text' : 'form-group-error-text'">
            {{ message }}
          </span>
        </div>
      </div>
    </div>
  </outside-click-listener>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue';
import { useStore } from 'vuex';
import parser from 'SRC/powerbi/utils/parser';
import { useI18n } from 'vue-i18n';

const store = useStore();
const message = ref('');
const { t } = useI18n();
const urlIsValid = ref(true);
const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);
const url = computed(() => datasetElement.value ? datasetElement.value.attributes.url : '');
onMounted(() => {
  handleChange('url', url.value.trim());
});
const emit = defineEmits(['close']);
const closeEditor = () => {
  emit('close');
};
const validateUrl = (url) => (url && url.length && parser(url) !== null);
const handleChange = (name, url) => {
  urlIsValid.value = validateUrl(url);
  message.value = urlIsValid.value ? t('embed.editor.message.validUrlMessage') : t('embed.editor.message.invalidUrlMessage');
  store.dispatch('board/updateDatasetElementAttributes', {
    datasetElementId: datasetElementId.value,
    attributes: {
      ...datasetElement.value.attributes,
      [name]: url
    }
  });
};

</script>

<style lang="scss" scoped src="./EmbedEditor.scss" />
