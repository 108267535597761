import actions from './actions';
import mutations from './mutations';

const dockModule = {
  namespaced: true,
  state: {
    categories: {},
    toolsets: {},
    displayComponent: null,
    creationComponent: null,
    dataTypeId: null,
    viewTypeId: null,
    datasetElements: {},
    isCreating: false
  },
  mutations,
  actions
};

export default dockModule;
