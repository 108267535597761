import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const EditorModule = {
  namespaced: true,
  state: {
    dataType: null,
    datasetElementId: null,
    hierarchicalCount: 0,
    isEditorOpen: false,
    editorType: 'card',
    previousDatasetElementIds: []
  },
  mutations,
  actions,
  getters
};

export default EditorModule;
