<template>
  <IobModal
    id="PowerBI-modal"
    modal-type="popup"
    size="xSmall"
    :show-modal="true"
    :has-sidebar-footer="true"
    :should-close-on-outside-click="false"
    @close="close"
  >
    <template #popupHeader>
      <div class="PowerBI-header">
        <span class="PowerBI-title">{{ $t('embed.powerbi.creationModal.title') }}</span>
      </div>
    </template>
    <template #popupContent>
      <form class="PowerBI-form">
        <div class="PowerBI-formGroup">
          <div class="pb-3">
            URL
          </div>
          <input
            v-model="url"
            :class="{ 'PowerBI-field--error': error, 'PowerBI-field mb-3': true }"
            :type="'text'"
            :placeholder="$t('embed.powerbi.creationModal.urlPlaceholder')"
            :value="''"
            @keypress.enter.prevent="initBoardElement"
          >
          <span
            v-if="!error"
            class="PowerBI-msg PowerBI-msg--info"
          >{{ $t('embed.editor.message.validUrlMessage') }}</span>
          <span
            v-if="error"
            class="PowerBI-msg PowerBI-msg--error"
          >{{ $t('embed.editor.message.invalidUrlMessage') }}</span>
        </div>
      </form>
    </template>
    <template #popupFooter>
      <div class="PowerBI-buttonGroup">
        <IobButton
          :label="$t('btn-cancel')"
          color="secondarySoft"
          size="medium"
          type="filled"
          @click="close"
        />
        <IobButton
          :label="$t('btn-add')"
          color="primary"
          size="medium"
          type="filled"
          @click="initBoardElement"
        />
      </div>
    </template>
  </IobModal>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import parse from 'SRC/powerbi/utils/parser';
import {
  INTERACTION_MODES
} from 'GLOBALS/constants.js';

export default {
  name: 'PBICreationModal',
  data: () => ({
    url: '',
    error: false
  }),
  computed: {
    ...mapState('app', ['dataTypes', 'viewTypes']),
    ...mapState('board', [
      'selectedInteractionMode'
    ]),
    ...mapGetters('app', ['getViewTypeSize']),
    ...mapGetters('board', ['getElementInitialPosition']),
    ...mapState({
      boardId: (state) => state.board.id,
      viewTypeId: (state) => state.dock.viewTypeId
    })
  },
  methods: {
    ...mapMutations('board', [
      'setSelectedInteractionMode'
    ]),
    ...mapActions('board', ['createBoardElement']),
    async initBoardElement() {
      if (parse(this.url) == null) {
        this.error = true;
      } else {
        const size = this.getViewTypeSize(this.viewTypeId);
        const requestBody = {
          boardId: this.boardId,
          viewTypeId: this.viewTypeId,
          position: this.getElementInitialPosition(size),
          datasetElement: {
            attributes: {
              url: this.url
            }
          },
          width: size.width,
          height: size.height
        };
        this.elementId = await this.createBoardElement({
          requestBody
        });
        if (this.selectedInteractionMode === INTERACTION_MODES.PANNING) {
          this.setSelectedInteractionMode({
            value: INTERACTION_MODES.SELECT
          });
        }
        this.close();
      }
    },
    close() {
      this.$store.commit('dock/setCreationComponent', null);
    }
  }
};
</script>

<style scoped src='./PBICreationModal.css' />
