import { get, post, del } from 'SRC/api';
import { PAGE_SIZE, DEFAULT_BOARD_ELEMENT_SIZE, RELATION_TYPES } from 'GLOBALS/constants';
import { getUserStateId } from 'SRC/utils/collab';
import { formatChildren } from './utils';
import editorGetters from '../getters';
import apiUtils from 'SRC/store/utils';

export async function getAllItemsFromAllPages(paginatedUrl, isRelative = true) {
  const allItems = [];
  let url = paginatedUrl;
  while (url !== undefined) {
    const itemPage = await get(url, undefined, undefined, isRelative);
    allItems.push(...itemPage.data);
    url = itemPage.next;
  }
  return allItems;
}

export default {
  /* eslint-disable no-unused-vars */
  async getRelationFromDatasetElementId({state}, id) {
    return await get(`/hierarchical-relations/${id}`);
  },
  async fetchRelations({state}, datasetElementIds) {
    let query = datasetElementIds
      .map((id) => `datasetElementIds=${id}`)
      .join('&');
    try {
      const response = await get(`/hierarchical-relations?${query}`);
      if (!response) {
        return;
      }
      const datasetElementsWithChildren = response.data.filter(
        (el) => el.datasetElementChildrenIds.length > 0
      );
      if (datasetElementsWithChildren.length === 0) {
        return { childrenData: [], datasetElementsWithChildren: []};
      }
      const childrenIds = datasetElementsWithChildren.reduce((acc, el) => [...acc, ...el.datasetElementChildrenIds], []);
      query = childrenIds.map((id) => `ids=${id}`).join('&');
      const childrenData = await get(`/dataset-elements?${query}`);
      const returnedValue = {
        childrenData: childrenData.data,
        datasetElementsWithChildren
      };
      return returnedValue;
    } catch (error) {
      console.error('Error in fetchRelations', error);
    }
  },
  /* eslint-disable no-unused-vars */
  async getHierarchicalRelations({dispatch}, hierarchicalRelationIds) {
    try {
      return await Promise.all(hierarchicalRelationIds.map(async (id) => await dispatch('getRelationFromDatasetElementId', id)));
    } catch (error) {
      return;
    }
  },
  async fetchHierarchicalRelations({ commit, dispatch, rootState, rootGetters }) {
    const { datasetElementId } = rootState.editor;
    const hierarchicalRelationsData = await get(`/hierarchical-relations?datasetElementIds=${datasetElementId}`);
    if (hierarchicalRelationsData && hierarchicalRelationsData.data && hierarchicalRelationsData.data.length) {
      const { datasetElementParentId, datasetElementChildrenIds, hierarchicalRelationIds } = hierarchicalRelationsData.data[0];
      const hierarchicalRelations = await dispatch('getHierarchicalRelations', hierarchicalRelationIds);
      if (!hierarchicalRelations) {
        return;
      }
      commit('relations/setHierarchicalRelations', hierarchicalRelations, { root: true });
      if (datasetElementParentId) {
        const parentDatasetElement = await get(`/dataset-elements/${datasetElementParentId}`);
        if (parentDatasetElement) {
          const parent = editorGetters.getRelationObject(rootGetters)(parentDatasetElement);
          const id = editorGetters.getRelationFromDatasetElementIds(rootState)(datasetElementParentId, datasetElementId)?.id;
          const hierarchicalRelationId = Object.values(hierarchicalRelationIds)
            .find((hierarchicalRelationId) => hierarchicalRelationId === id);
          if (hierarchicalRelationId) {
            await dispatch('board/addDatasetElementToStore', datasetElementParentId, { root: true });
            commit('setParent', { ...parent, hierarchicalRelationId: id });
          }
        }
      }
      if (datasetElementChildrenIds && datasetElementChildrenIds.length) {
        const query = datasetElementChildrenIds.map((id) => `ids=${id}`).join('&');
        const childrenDatasetElements = await getAllItemsFromAllPages(`/dataset-elements?page=1&size=${PAGE_SIZE}&${query}`);
        const children = childrenDatasetElements.map((childDatasetElement) => editorGetters.getRelationObject(rootGetters)(childDatasetElement));
        const formattedChildren = children.map((element) => {
          const id = editorGetters.getRelationFromDatasetElementIds(rootState)(datasetElementId, element.id)?.id;
          const hierarchicalRelationId = Object.values(hierarchicalRelationIds)
            .find((hierarchicalRelationId) => hierarchicalRelationId === id);
          return ({ ...element, hierarchicalRelationId });
        });
        await dispatch('board/addDatasetElementsToStore', datasetElementChildrenIds, { root: true });
        commit('setChildren', formattedChildren);
      }
      // Update the hierarchical count after the relations have been retrieved
      dispatch('updateHierarchyCount');
    }
  },
  async fetchEligibleDataTypes({ commit, rootState }) {
    const { dataType } = rootState.editor;
    const { dataTypes } = rootState.app;
    if (!(dataType && dataType.id)) {
      return;
    }
    const eligibleDataTypes = await get(`/hierarchical-relation-definitions?dataTypeId=${dataType.id}`);

    if (eligibleDataTypes && eligibleDataTypes.parentDataTypeIds) {
      const eligibleParentDataTypesData = eligibleDataTypes.parentDataTypeIds;
      commit('setEligibleParentDataTypes', eligibleParentDataTypesData);
    }

    if (eligibleDataTypes && eligibleDataTypes.childDataTypeIds) {
      const childDataTypeIds = eligibleDataTypes.childDataTypeIds.filter((id) => dataTypes[id]);
      const eligibleChildDataTypesData = childDataTypeIds.map((childDataTypeId) => ({
        id: childDataTypeId,
        name: dataTypes[childDataTypeId].name.replace('/element-data-types/ppm/', '')
      }));
      commit('setEligibleChildDataTypes', eligibleChildDataTypesData);
    }
  },
  async fetchEligibleChildren({ rootState, commit, rootGetters }, searchQuery) {
    const { datasetElementId } = rootState.editor;
    let requestUrl = `/dataset-elements/${datasetElementId}/eligible-children?page=1&size=100`;
    if (searchQuery) {
      requestUrl += `&search=${searchQuery}`;
    }

    const eligibleChildren = await get(requestUrl);

    let eligibleChildrenData = [];
    if (eligibleChildren && eligibleChildren.data && eligibleChildren.data.length) {
      eligibleChildrenData = eligibleChildren.data.map((child) => editorGetters.getRelationObject(rootGetters)(child));
    }

    commit('setEligibleChildren', eligibleChildrenData);
  },

  async instanciateElementsOnBoard({ rootState, commit, dispatch, rootGetters }, elementsToInstanciate) {
    const currentUserRoute = 'users/getCurrentUser';
    const currentUser = rootGetters[`${currentUserRoute}`];
    const currentUserClientId = getUserStateId(currentUser.id);
    const boardElementInEditionId = Object.entries(rootState.board.boardElementsInEdition)
      .find(([, value]) => value.find((el) => el.awarenessStateId === currentUserClientId));
    const boardElementData = rootState.board.elements[boardElementInEditionId[0]];
    const boardSize = rootState.board.boardSize;
    if (elementsToInstanciate) {
      const { viewTypes } = rootState.app;
      const { id: boardId } = rootState.board;
      const requests = formatChildren(
        boardId,
        viewTypes,
        elementsToInstanciate,
        boardElementData,
        boardSize,
        DEFAULT_BOARD_ELEMENT_SIZE
      );
      return await Promise.all(requests.map((request) => (
        post(`/dataset-elements/${request.elementId}/board-elements`, request.body).then((newElement) => ({
          id: newElement.id,
          datasetElement: newElement.datasetElement,
          position: newElement.position,
          viewTypeId: newElement.viewTypeId
        }))
      ))).then((newElements) => {
        // Add the newly instanciated elements to the board
        if (newElements && newElements.length) {
          newElements.forEach(async (element) => {
            await dispatch('board/addDatasetElementToStore', element.datasetElement.id, { root: true });
            commit('board/addBoardElement', {
              elementId: element.id,
              element
            }, { root: true });
            const hierarchicalRelationId = elementsToInstanciate.find((elementToInstanciate) =>
              elementToInstanciate.id === element.datasetElement.id).hierarchicalRelationId;
            const link = { source: boardElementInEditionId[0], target: element.id, hierarchicalRelationId };
            await dispatch('board/createVisualLink', link, { root: true });
          });
        }
        return true;
      }).catch(() => false);
    }
  },
  async unlinkRelationalElement({ dispatch }, {relationId, relatedDatasetElementId, relationType}) {
    let requestUrl = `/hierarchical-relations/${relationId}`;
    if (relationType === RELATION_TYPES[2]) {
      requestUrl = `/relations/${relationId}`;
    }
    const isUnlinked = await del(requestUrl);
    if (isUnlinked) {
      await dispatch('updateRelationsData', {relatedDatasetElementId, relationType});
    }
  },
  async unlinkRelationalElements({ commit, state, rootState, dispatch }, hierarchicalRelationsIds) {
    if (hierarchicalRelationsIds.ids.length) {
      const unlinked = await del('/hierarchical-relations', hierarchicalRelationsIds);
      if (unlinked && unlinked.data) {
        const deletedElements = unlinked.data.filter(({ status }) => status === 204);
        if (!deletedElements.length) {
          return false;
        }
        deletedElements.forEach(({ id }) => {
          commit('relations/updateHierarchicalRelation', {
            hierarchicalRelationId: id,
            updates: { deleted: true }
          }, { root: true });
          const visualLinks = Object.values(rootState.relations.visualLinks);
          visualLinks.forEach((link) => {
            if (link.hierarchicalRelationId === id) {
              commit('relations/updateVisualLink', {
                visualLinkId: link.id,
                updates: { deleted: true }
              }, { root: true });
            }
          });
        });
        const childrens = state.children;
        const filteredChildrens = childrens.filter((children) =>
          !deletedElements.find((deletedElement) => deletedElement.id === children.hierarchicalRelationId));
        //reset parent, children, and eligible children data
        commit('setParent', null);
        commit('setChildren', filteredChildrens);
        commit('setEligibleChildren', []);
        // fetch the new data from the server
        await dispatch('fetchHierarchicalRelations');
        await dispatch('fetchEligibleChildren');
        return true;
      }
      return false;
    }
  },
  async createRelationsFromExistingElements({ state, commit, dispatch, rootState }, elements) {
    if (elements.length) {
      const datasetElementId = rootState.editor.datasetElementId;
      const requestBody = elements.map((child) => ({
        datasetElementParentId: datasetElementId,
        datasetElementChildId: child.id
      })
      );
      const creations = await post('/hierarchical-relations', requestBody);
      if (creations && creations.data) {
        if (creations.data[0] && creations.data[0].status === 400) {
          commit('app/setRelationError', {
            title: 'This element already has a parent',
            content: 'An element cannot have multiple parents at once. You have to remove its current parent before assigning a new one.'
          }, { root: true });
          return false;
        }
        const createdChildren = creations.data.filter(({ status }) => status === 200);
        const fromattedhierarchicalRelations = createdChildren.map((hierarchicalRelation) => {
          commit('relations/addHierarchicalRelation', { hierarchicalRelationId: hierarchicalRelation.id, hierarchicalRelation }, { root: true });
          if (hierarchicalRelation.visualLinks && hierarchicalRelation.visualLinks.length) {
            hierarchicalRelation.visualLinks.forEach((link) => {
              commit('relations/addVisualLink', { visualLinkId: link.id, visualLink: link }, { root: true });
            });
          }
          const datasetElementChild = elements.find(({ id }) => id === hierarchicalRelation.datasetElementChildId);
          return { ...datasetElementChild, hierarchicalRelationId: hierarchicalRelation.id, checked: false };
        });
        // Remove added children from eligible children array
        const eligibleChildrenIdsToRemove = fromattedhierarchicalRelations.map((child) => child.id);
        commit('setEligibleChildren', state.eligibleChildren.filter((child) => !eligibleChildrenIdsToRemove.includes(child.id)));

        commit('setChildren', state.children.concat(fromattedhierarchicalRelations));

        dispatch('updateHierarchyCount');

        return true; // Some children were created
      }

      return false; // No children were created
    }
  },
  async handleParentRelation({ commit, state }, {formattedHierarchicalRelation, eligibleDatasetElementId}) {
    const eligibleParents = state.eligibleParents.filter((parent) => parent.id !== eligibleDatasetElementId);
    commit('setEligibleParents', eligibleParents);
    commit('setParent', formattedHierarchicalRelation);
    return true; // Parent was created
  },
  updateElementChildren({ commit  }, { children, datasetElementId }) {
    commit('board/updateDatasetElement', {
      datasetElementId,
      updates: { children }
    }, { root: true });
  },
  async handleChildRelation({ rootState, state, commit, dispatch }, {formattedHierarchicalRelation, eligibleDatasetElementId}) {
    const eligibleChildren = state.eligibleChildren.filter((child) => child.id !== eligibleDatasetElementId);
    commit('setEligibleChildren', eligibleChildren);
    commit('setChildren', [...state.children, formattedHierarchicalRelation]);
    dispatch('updateElementChildren', { children: state.children, datasetElementId: rootState.editor.datasetElementId });
    // await dispatch('app/addFormulasResultsToElements', { root: true });
    return true; // Some children were created
  },
  async createRelationByTypeWithExistingElement({ rootState, rootGetters, dispatch }, { relation, eligibleDatasetElementId }) {
    const id = rootState.editor.datasetElementId;
    const requestBody = apiUtils.createRequestBodyForNewRelation(relation, eligibleDatasetElementId, id);
    const creations = await post('/hierarchical-relations', requestBody);

    if (!creations?.data?.length) {
      return false; // No relations were created
    }
    const createdHierarchicalRelation = creations.data.find(({ status }) => status === 200);
    let eligibleDatasetElement = await get(`/dataset-elements/${eligibleDatasetElementId}`);
    eligibleDatasetElement = editorGetters.getRelationObject(rootGetters)(eligibleDatasetElement);
    const formattedHierarchicalRelation = {
      ...eligibleDatasetElement,
      hierarchicalRelationId: createdHierarchicalRelation.id
    };
    await dispatch('board/addDatasetElementToStore', eligibleDatasetElementId, { root: true });

    return relation === 'parent'
      ? await dispatch('handleParentRelation', {formattedHierarchicalRelation, eligibleDatasetElementId})
      : await dispatch('handleChildRelation', {formattedHierarchicalRelation, eligibleDatasetElementId});
  },
  async createRelationFromNewElement({ state, rootState, commit, dispatch, rootGetters }, { title, dataTypeId }) {
    if (title && dataTypeId) {
      const datasetElementId = rootState.editor.datasetElementId;
      const requestBody = {
        title,
        dataTypeId
      };

      const REQUEST_URL = `/dataset-elements/${datasetElementId}/children`;
      const newDatasetElement = await post(REQUEST_URL, requestBody);
      if (newDatasetElement) {
        const { id, hierarchicalRelationId } = newDatasetElement;
        const datasetElement = await get(`/dataset-elements/${id}`);
        const relation = editorGetters.getRelationObject(rootGetters)(datasetElement);
        commit('setChildren', state.children.concat({ ...relation, hierarchicalRelationId }));
        dispatch('updateHierarchyCount');
        return datasetElement;
      }
      return null;
    }
  },
  updateHierarchyCount({ state, commit }) {
    const count = state.parent ? state.children.length + 1 : state.children.length;
    commit('editor/setHierarchicalCount', count, { root: true });
  },
  resetRelationsData({ commit }) {
    commit('setParent', null);
    commit('setChildren', []);
    commit('setEligibleChildren', []);
    commit('setEligibleParents', []);
    commit('setEligibleChildDataTypes', []);
    commit('setEligibleParentDataTypes', []);
    commit('setCreatedDependencies', []);
    commit('setDependencies', []);
    commit('setEligibleDependencies', []);
  },
  async fetchDatasetElementDependencies({ commit, rootState, state, rootGetters, dispatch }) {
    const datasetElementId = rootState.editor.datasetElementId;

    const response = await get(`/dataset-elements/${datasetElementId}/relations`);
    if (!response?.data) {
      return;
    }

    const createdDependencies = response.data;
    const importedDependeciesIds = createdDependencies.map(({ relatedDatasetElementId }) => relatedDatasetElementId);
    await dispatch('board/addDatasetElementsToStore', importedDependeciesIds, { root: true });
    const newDatasetElements = createdDependencies
      .map(({ relatedDatasetElementId }) => !rootState.board.datasetElements[relatedDatasetElementId] && relatedDatasetElementId)
      .filter(Boolean);

    let relatedDatasetElements = [];
    if (newDatasetElements.length) {
      const query = newDatasetElements.map((id) => `ids=${id}`).join('&');
      const datasetElementsInfo = await get(`/dataset-elements?page=1&size=${PAGE_SIZE}&${query}`);
      const datasetElementsData = datasetElementsInfo?.data ?
        await apiUtils.getNextPage(datasetElementsInfo, datasetElementsInfo.data, post) : [];
      const isElementDataType = (el) => !el.typeName.includes('/boardget-data-types/opex/Indicator');
      const filteredDatasetElementsData = datasetElementsData.filter(isElementDataType);
      if (filteredDatasetElementsData.length === 0) {
        return;
      }
      relatedDatasetElements = filteredDatasetElementsData.map((datasetElement) => editorGetters.getRelationObject(rootGetters)(datasetElement));
    }
    const formattedDependencies = createdDependencies.map(({ id, relatedDatasetElementId }) => {
      const relatedDatasetElement = rootState.board.datasetElements[relatedDatasetElementId] ?
        editorGetters.getRelationObject(rootGetters)(rootState.board.datasetElements[relatedDatasetElementId]) :
        relatedDatasetElements.find((el) => el.id === relatedDatasetElementId);
      return { relationId: id, ...relatedDatasetElement };
    });

    commit('setCreatedDependencies', formattedDependencies);
    const eligibleDependencies = state.eligibleDependencies.filter((eligibleDependency) =>
      !state.createdDependencies.some((dependency) => dependency.id === eligibleDependency.id)
    );
    commit('setEligibleDependencies', eligibleDependencies);
    const dependencies = apiUtils.formatDependencies(state.createdDependencies, rootState.app.dataTypes);
    commit('setDependencies', dependencies);
  },
  async fetchEligibleElementsForRelations({ commit, rootState, state, rootGetters }, {type, attributes, searchQuery}) {
    const id = rootState.editor.datasetElementId;
    const RELATION_URLS = {
      [RELATION_TYPES[0]]: `/dataset-elements/${id}/eligible-parents/search-requests`,
      [RELATION_TYPES[1]]: `/dataset-elements/${id}/eligible-children/search-requests`,
      [RELATION_TYPES[2]]: '/dataset-elements/search-requests'
    };

    let requestUrl = RELATION_URLS[type];
    if (!requestUrl) {
      return;
    }
    requestUrl += searchQuery ? `?${searchQuery}&page=1&size=5` : '?page=1&size=5';
    const body = attributes ? { attributes } : { attributes: {} };

    const eligibleHierarchyElements = await post(requestUrl, body);

    const apiResponseData = eligibleHierarchyElements?.data ?
      await apiUtils.getNextPage(eligibleHierarchyElements, eligibleHierarchyElements.data, post, false, {}, body) : [];
    const relationObject = (apiResponseData || [])
      .map((element) => editorGetters.getRelationObject(rootGetters)(element))
      .filter((element) => element.id !== id);

    if (type === RELATION_TYPES[0]) {
      commit('setEligibleParents', relationObject);
      return;
    }
    if (type === RELATION_TYPES[1]) {
      commit('setEligibleChildren', relationObject);
      return;
    }

    const eligibleDependencies = relationObject.filter((eligibleDependency) =>
      !state.createdDependencies.some((dependency) => dependency.id === eligibleDependency.id)
    );
    commit('setEligibleDependencies', eligibleDependencies);
  },
  updateDatasetElementDependencies({ commit }, { dependencies, datasetElementId }) {
    commit('board/updateDatasetElement', {
      datasetElementId,
      updates: { dependencies }
    }, { root: true });
  },
  async createDependencyWithNewDatasetElement({ rootState, rootGetters, dispatch, state, commit }, { title, dataTypeId }) {
    const datasetElementId = rootState.editor.datasetElementId;
    const relationTypeId = rootState.app.relationTypes.find((relationType) => relationType.name === 'Relates to').id;

    const requestBody = {
      title,
      dataTypeId,
      relationTypeId
    };

    const result = await post(`/dataset-elements/${datasetElementId}/relations`, requestBody);
    if (result) {
      const { relationId, id } = result;
      const relatedDatasetElementInfo = await get(`/dataset-elements/${id}`);
      const relatedDatasetElement = editorGetters.getRelationObject(rootGetters)(relatedDatasetElementInfo);

      const newDependency = { relationId, id, ...relatedDatasetElement };
      const updatedDependencies = [...state.createdDependencies, newDependency];
      commit('setCreatedDependencies', updatedDependencies);
      await dispatch('updateDatasetElementDependencies', {dependencies: state.createdDependencies, datasetElementId});
      // await dispatch('app/addFormulasResultsToElements', { root: true });
      const dependencies = apiUtils.formatDependencies(updatedDependencies, rootState.app.dataTypes);
      commit('setDependencies', dependencies);
      return relatedDatasetElement;
    }
  },
  async createDependencyWithExistingDatasetElement({ state, rootState, rootGetters, commit, dispatch }, { eligibleDatasetElementId }) {
    const datasetElementId = rootState.editor.datasetElementId;
    const relationTypeId = rootState.app.relationTypes.find((relationType) => relationType.name === 'Relates to').id;

    const requestBody = [{
      sourceDatasetElementId: datasetElementId,
      targetDatasetElementId: eligibleDatasetElementId,
      relationTypeId
    }];

    const creations = await post('/relations', requestBody);
    const createdDependencies = creations?.data?.filter(({ status }) => status === 200) || [];

    if (createdDependencies.length) {
      await dispatch('board/addDatasetElementToStore', eligibleDatasetElementId, { root: true });
      const formattedCreatedDependencies = await Promise.all(createdDependencies.map(async ({id, targetDatasetElementId}) => {
        let relatedDatasetElement;
        if (rootState.board.datasetElements[targetDatasetElementId]) {
          relatedDatasetElement = editorGetters.getRelationObject(rootGetters)(rootState.board.datasetElements[targetDatasetElementId]);
        } else {
          const relatedDatasetElementInfo = await get(`/dataset-elements/${targetDatasetElementId}`);
          relatedDatasetElement = editorGetters.getRelationObject(rootGetters)(relatedDatasetElementInfo);
        }
        return { relationId: id, ...relatedDatasetElement };
      }));

      const updatedDependencies = [...state.createdDependencies, ...formattedCreatedDependencies];
      commit('setCreatedDependencies', updatedDependencies);
      await dispatch('updateDatasetElementDependencies', {dependencies: updatedDependencies, datasetElementId});

      const updatedEligibleDependencies = state.eligibleDependencies.filter((eligibleDependency) =>
        !state.createdDependencies.some((dependency) => dependency.id === eligibleDependency.id)
      );
      commit('setEligibleDependencies', updatedEligibleDependencies);
      const dependencies = apiUtils.formatDependencies(state.createdDependencies, rootState.app.dataTypes);
      commit('setDependencies', dependencies);
      dispatch('updateDatasetElementDependencies', {
        dependencies: state.createdDependencies,
        datasetElementId
      });
      // await dispatch('app/addFormulasResultsToElements', { root: true });
      return true;
    }
    return false;
  },
  async updateRelationsData({ state, rootState, dispatch, commit }, {relatedDatasetElementId, relationType = RELATION_TYPES[2]}) {
    if (relationType === RELATION_TYPES[0]) {
      commit('setParent', null);
      return;
    }
    if (relationType === RELATION_TYPES[1]) {
      const newChildren = state.children.filter((child) => child.id !== relatedDatasetElementId);
      commit('setChildren', newChildren);
      return;
    }
    const datasetElementId = rootState.editor.datasetElementId;

    const createdDependencies = state.createdDependencies.filter((dependency) => dependency.id !== relatedDatasetElementId);
    commit('setCreatedDependencies', createdDependencies);
    const newDependencies = apiUtils.formatDependencies(state.createdDependencies, rootState.app.dataTypes);
    commit('setDependencies', newDependencies);

    dispatch('updateDatasetElementDependencies', {dependencies: state.createdDependencies, datasetElementId});
    // await dispatch('app/addFormulasResultsToElements', { root: true });
  },
  async deleteRelations({ dispatch }, {relationId, relatedDatasetElementId, relationType = RELATION_TYPES[2]}) {
    const DELETE_RELATION_URLS = {
      [RELATION_TYPES[0]]: `/hierarchical-relations/${relationId}`,
      [RELATION_TYPES[1]]: `/hierarchical-relations/${relationId}`,
      [RELATION_TYPES[2]]: `/relations/${relationId}`
    };
    const requestUrl = DELETE_RELATION_URLS[relationType];
    const isDeleted =  await del(requestUrl);
    if (isDeleted) {
      const isSuccessful = await dispatch('board/deleteSelectedDatasetElement', relatedDatasetElementId, { root: true });
      if (isSuccessful) {
        await dispatch('updateRelationsData', {relatedDatasetElementId, relationType});
      }
    }
  }
};
