import { POWERBI_URL_PATTERNS } from 'GLOBALS/constants';

function createResultObject(urlType, contentType, pathParams) {
  return { urlType, contentType, pathParams };
}
function validateResult(pathParams) {
  return !Object.values(pathParams).some((value) => value === '');
}
function getPathParams(url) {
  const parser = new URL(url);
  const pathParts = parser.pathname.split('/').filter((part) => part !== '');
  return pathParts;
}

function getValue(queryParams, queryParamKey, pathParts, pathPartKey, pathPartOffset) {
  return queryParams.get(queryParamKey) || pathParts[pathParts.indexOf(pathPartKey) + pathPartOffset];
}

function parseAppUrl(urlObj, pathParts, queryParams) {
  let pathParams;
  if (pathParts.includes('visual') || urlObj.search.includes('visual')) {
    pathParams = {
      appId: getValue(queryParams, 'appId', pathParts, 'apps', 1),
      reportId: getValue(queryParams, 'reportObjectId', pathParts, 'reports', 1),
      pageName: getValue(queryParams, 'reportPage', pathParts, 'reports', 2),
      visualId: queryParams.get('visual'),
      bookmarkGuid: queryParams.get('bookmarkGuid')
    };
    return validateResult(pathParams) ? createResultObject('App', 'Visual', pathParams) : null;
  } else if (pathParts.includes('reports') || urlObj.search.includes('reportObjectId')) {
    pathParams = {
      appId: getValue(queryParams, 'appId', pathParts, 'apps', 1),
      reportId: getValue(queryParams, 'reportObjectId', pathParts, 'reports', 1),
      pageName: getValue(queryParams, 'reportPage', pathParts, 'reports', 2),
      bookmarkGuid: queryParams.get('bookmarkGuid')
    };
    return validateResult(pathParams) ? createResultObject('App', 'Report', pathParams) : null;
  } else if (pathParts.includes('dashboards') || urlObj.search.includes('dashboards')) {
    pathParams = {
      appId: getValue(queryParams, 'appId', pathParts, 'apps', 1),
      dashboardId: getValue(queryParams, 'dashboard', pathParts, 'dashboards', 1)
    };
    return validateResult(pathParams) ? createResultObject('App', 'Dashboard', pathParams) : null;
  } else {
    pathParams = {
      appId: queryParams.get('appId')
    };
    return validateResult(pathParams) ? createResultObject('App', 'App', pathParams) : null;
  }
}

function parseDashboardUrl(pathParts) {
  const pathParams = {
    groupId: pathParts[1],
    dashboardId: pathParts[3]
  };
  return validateResult(pathParams) ? createResultObject('Dashboard', 'Dashboard', pathParams) : null;
}

function parseVisualUrl(pathParts, queryParams) {
  const pathParams = {
    visualId: queryParams.get('visual'),
    groupId: pathParts[1],
    reportId: pathParts[3],
    pageName: pathParts[4],
    bookmarkGuid: queryParams.get('bookmarkGuid')
  };
  return validateResult(pathParams) ? createResultObject('Visual', 'Visual', pathParams) : null;
}

function parseReportUrl(pathParts, queryParams) {
  const pathParams = {
    groupId: pathParts[1],
    reportId: pathParts[3],
    pageName: pathParts[4],
    bookmarkGuid: queryParams.get('bookmarkGuid')
  };
  return validateResult(pathParams) ? createResultObject('Report', 'Report', pathParams) : null;
}

export default (url) => {
  try {
  // Define regex patterns for different types of Power BI URLs
    const appPattern = POWERBI_URL_PATTERNS.appPattern;
    const dashboardPattern = POWERBI_URL_PATTERNS.dashboardPattern;
    const reportPattern = POWERBI_URL_PATTERNS.reportPattern;
    const visualPattern = POWERBI_URL_PATTERNS.visualPattern;

    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);
    const pathParts = getPathParams(url);
    // Test the URL against each pattern
    if (appPattern.test(url)) {
      return parseAppUrl(urlObj, pathParts, queryParams);
    } else if (dashboardPattern.test(url)) {
      return parseDashboardUrl(pathParts);
    } else if (visualPattern.test(url)) {
      return parseVisualUrl(pathParts, queryParams);
    } else if (reportPattern.test(url)) {
      return parseReportUrl(pathParts, queryParams);
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
