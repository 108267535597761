<template>
  <div
    class="IobCheckbox"
    :class="{ 'IobCheckbox--rounded': type === 'rounded' }"
  >
    <div
      v-if="type === 'rounded'"
      @click="changeAction"
    >
      <icon-loader
        :name="iconName"
        :color="iconColor"
      />
    </div>
    <input
      v-else
      :id="id"
      class="IobCheckbox-input focus-inside"
      :checked="checked"
      :disabled="disabled"
      type="checkbox"
      @click.stop
      @change="changeAction"
    >
    <span
      v-if="label && label.length"
      class="IobCheckbox-label"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
import IconLoader from '../../IconLoader/IconLoader.vue';

export default {
  name: 'IobCheckbox',
  components: { IconLoader },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'rounded'].includes(value),
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isChecked: this.checked,
    };
  },
  computed: {
    iconColor() {
      return this.isChecked
        ? 'var(--app-card-selection-color-checkbox-selectd, #2D6BFF)'
        : 'var(--app-card-selection-color-checkbox, #5D587B)';
    },
    iconName() {
      return this.isChecked ? 'CheckCircle-2-Filled' : 'Circle';
    },
  },
  watch: {
    checked(newVal) {
      this.isChecked = newVal;
    },
  },
  methods: {
    changeAction() {
      this.isChecked = !this.isChecked;
      this.$emit('changeCheckbox', {
        name: 'checkbox',
        isChecked: this.isChecked,
        id: this.id
      });
    }
  }
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-checkboxes.scss" />
<style lang="scss" scoped src="./IobCheckbox.scss" />
