<template>
  <div
    class="IobCardPreview level-container"
    :class="classList"
  >
    <div class="IobCardPreview-cover">
      <IobImage
        :src="cover"
        :render-type="coverRatio"
      />
    </div>
    <div
      v-show="title"
      class="IobCardPreview-title"
    >
      {{ title }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import IobImage from '../../../Atoms/IobImage/IobImage.vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  cover: {
    type: String,
    default: null,
  },
  coverRatio: {
    type: String,
    default: '16:9',
  },
  elevation: {
    type: Number,
    validator: (value) => [0, 1, 2, 3].includes(value),
    default: 1,
  }
});
const classList = computed(() => [
  `level-${props.elevation}`,
]);
</script>
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-app-cards.scss" />
<style lang="scss" src="./IobCardPreview.scss" scoped />
