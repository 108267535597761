<template>
  <div
    class="CardCompartment"
    :style="{
      gridTemplateRows: `repeat(${gridSize.rows}, minmax(0, 1fr))`,
      gridTemplateColumns: `repeat(${gridSize.cols}, minmax(0, 1fr))`,
      backgroundColor,
      ...outline
    }"
  >
    <div
      v-for="(field) in fields"
      :key="field.value"
      :style="getFieldStyle(field)"
      class="CardCompartment-field"
      data-target="board-element"
    >
      <iob-avatar-with-name
        v-if="field.type === 'user' && getCardUser(field.value)"
        :picture="getCardUser(field.value).picture"
        :name="getCardUser(field.value).fullName"
        :alt-text="getUserInitials(field.value)"
        :alt-text-style="`${getTextStyle(field)};`"
        :custom-dimension="componentSize"
        :name-style="`${getTextStyle(field)}; ${userNameStyle}`"
        data-target="board-element"
      />
      <design-system-icon-with-label
        v-else-if="field.type === 'priority'"
        :label="field.value"
        :color="defaultPriorityColors[field.value]"
        :custom-dimension="componentSize"
        :custom-icon-dimension="iconCustomSize"
        :label-style="`${getTextStyle(field)}`"
        icon="ChevronUp"
        data-target="board-element"
      />
      <iob-badge
        v-else-if="field.type === 'status'"
        :label="field.value.toString()"
        :color="defaultBadgeColors[field.value]"
        :custom-height="badgeSize"
        :label-style="`${getTextStyle(field)}`"
        data-target="board-element"
      />
      <design-system-icon-with-label
        v-else-if="field.type === 'timeframe'"
        :label="getHorizon(field.value)"
        color="secondary"
        :custom-dimension="componentSize"
        :custom-icon-dimension="iconCustomSize"
        :label-style="`${getTextStyle(field)}`"
        icon="CalendarRange"
        data-target="board-element"
      />
      <design-system-text
        v-else
        :style="getTextStyle(field)"
        data-target="board-element"
        class="CardCompartment-text"
        :value="field.value ? field.value.toString() : ''"
      />
    </div>
  </div>
</template>

<script>
import {
  ZOOM,
  DEFAULT_STATUS_BADGE_COLORS,
  DEFAULT_PRIORITY_ICON_COLORS,
  LEVEL2_CARD_BADGE_SIZE
} from 'GLOBALS/constants';
import { mapGetters, mapState } from 'vuex';
import { getUserInitials } from 'SRC/utils/collab-utils';
import { calculateScaledValue, formatDateToLabel } from 'BOARD/utils/utils';

export default {
  name: 'CardCompartment',
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    hasOutline: {
      type: Boolean,
      default: false
    },
    outlineColor: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    },
    hasColor: {
      type: Object,
      default: () => ({})
    },
    gridSize: {
      type: Object,
      default: () => ({})
    },
    textStyle: {
      type: Object,
      required: true
    },
    fieldStyle: {
      type: Object,
      default: null
    },
    friendlyIdTextStyle: {
      type: String,
      default: ''
    },
    lowerPartFieldsSize: {
      type: Object,
      default: null
    },
    lowerPartIconSize: {
      type: Object,
      default: null
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    lodThreshold: 0.6,
    textMargin: `
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
      overflow: hidden
    `,
    defaultBadgeColors: DEFAULT_STATUS_BADGE_COLORS,
    defaultPriorityColors: DEFAULT_PRIORITY_ICON_COLORS
  }),
  computed: {
    ...mapGetters('users', ['getUserById']),
    ...mapState('board', ['zoomLevel']),
    outline() {
      return this.hasOutline
        ? {
          outline: `${Math.max(2, 2 * (1 / this.zoomLevel))}px ${this.outlineColor
          } solid`
        }
        : {};
    },
    backgroundColor() {
      return this.hasColor && this.bgColor ? this.bgColor : '';
    },
    componentSize() {
      if (this.zoomLevel > this.lodThreshold) {
        return `${this.lowerPartFieldsSize && this.lowerPartFieldsSize.level3}`;
      }
      return `${this.lowerPartFieldsSize && this.lowerPartFieldsSize.level2}`;
    },
    iconCustomSize() {
      if (this.zoomLevel > this.lodThreshold) {
        return `${this.lowerPartIconSize && this.lowerPartIconSize.level3}`;
      }
      return `${this.lowerPartIconSize && this.lowerPartIconSize.level2}`;
    },
    badgeSize() {
      if (this.zoomLevel > this.lodThreshold) {
        return `${this.lowerPartFieldsSize && this.lowerPartFieldsSize.level3}`;
      }
      return `${calculateScaledValue(this.width, this.height, LEVEL2_CARD_BADGE_SIZE)}px;`;
    },
    userNameStyle() {
      if (this.zoomLevel > this.lodThreshold) {
        return `width: ${calculateScaledValue(this.width, this.height, 104)}px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`;
      }
      return '';
    },
    lowerFieldsStyle() {
      if (this.zoomLevel > this.lodThreshold) {
        return this.fieldStyle && this.fieldStyle.level3;
      }
      return this.fieldStyle && this.fieldStyle.level2;
    }
  },
  created() {
    this.lodThreshold = ZOOM.LEVEL_OF_DETAIL_THRESHOLD;
  },
  methods: {
    getHorizon(dateSettings) {
      if (!dateSettings) {
        return 'None';
      }
      const attributes = {};
      Object.keys(dateSettings).forEach((attr) => {
        attributes[attr] = dateSettings[attr];
      });
      return formatDateToLabel(dateSettings.type, attributes);
    },
    getLinesLimit({ originRef, h }) {
      const isTitle = originRef === '/vms/title';
      if (!isTitle) {
        return h;
      }
      const cellHeight = 252.0 / 6;
      const linesConstant = this.zoomLevel > this.lodThreshold ? 60 : 70;
      const lines = Math.floor((h * cellHeight) / linesConstant);
      return lines;
    },
    getFieldStyle({ x, y, w, h, fieldName }) {
      if (fieldName === 'friendly-id' || fieldName === 'title') {
        return {
          gridColumn: `${x} / ${x + w}`,
          gridRow: `${y} / ${y + h}`
        };
      }
      return {
        gridColumn: `${x} / ${x + w}`,
        gridRow: `${y} / ${y + h}`,
        ...this.lowerFieldsStyle
      };
    },
    getCardUser(userId) {
      const user = this.getUserById(userId);
      if (user) {
        return {
          fullName: `${user.firstname} ${user.lastname}`,
          picture: user.picture ? user.picture : ''
        };
      }
    },
    getUserInitials(userId) {
      const user = this.getUserById(userId);
      if (user) {
        return getUserInitials(user.firstname, user.lastname);
      }
    },
    getTextStyle(field) {
      if (field.fieldName !== 'friendly-id') {
        if (this.zoomLevel > this.lodThreshold) {
          return `${this.textStyle.level3} -webkit-line-clamp : ${this.getLinesLimit(field)}`;
        }
        return `${this.textStyle.level2} -webkit-line-clamp: ${this.getLinesLimit(field)}`;
      }
      if (this.zoomLevel > this.lodThreshold) {
        return `
          ${this.friendlyIdTextStyle};
          -webkit-line-clamp: ${this.getLinesLimit(field)};
        `;
      }
      return '';
    }
  }
};
</script>

<style scoped src="./CardCompartment.css" />
