<template>
  <div>
    <!-- text-->
    <span
      v-if="componentType === 'text'"
      class="ContextualMenu-text"
    >
      {{ text }}
    </span>

    <!-- icon-->
    <icon-loader
      v-if="componentType === 'icon'"
      :name="iconName"
      :color="iconColorChangeCondition ? iconColorInEdit : iconColor"
      :has-background-color="iconColorChangeCondition ? true: false"
      :background-color="backgroundColor"
      style="cursor: pointer"
      @click.stop="action"
    />

    <!-- dropdownButton-->
    <iob-dropdown-button
      v-if="componentType === 'dropdownButton'"
      :items="list"
      size="medium"
      right-icon-size="xsmall"
      :title="dropdownTitle"
      :icon-name="iconName"
      button-type="ghost"
      :dropdown-style="customStyle"
      @dropdownElementClick="handleItemClick"
    />

    <!-- colorsPopover-->
    <div v-if="componentType === 'colorsPopover'">
      <button @click="toggleColorsFuncName">
        <colored-circle :color="color" />
      </button>
      <colors-popover
        v-if="displayColorsCondition"
        :selected-color="color"
        class="ContextualMenu-colorsPopover"
        @select-color="action"
      />
    </div>
    <div
      v-if="hasSeparator"
      class="ContextualMenu-separator"
    />
  </div>
</template>

<script>

export default {
  name: 'ContextualMenu',
  props: {
    componentType: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      default: () => {}
    },
    iconName: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: '#14131B'
    },
    iconColorInEdit: {
      type: String,
      default: '#fff'
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    iconColorChangeCondition: {
      type: Boolean,
      default: false
    },
    dropdownTitle: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    hasSeparator: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    toggleColorsFuncName: {
      type: Function,
      default: () => {}
    },
    displayColorsCondition: {
      type: Boolean,
      default: false
    },
    customStyle: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleItemClick(item) {
      if (!this.action) {
        return;
      }
      this.action(item.text || item.value);
    }
  }
};
</script>

<style src="./ContextualMenu.scss" lang="scss" scoped />
