<template>
  <div>
    <iob-icon-shape
      :size="size"
      :color="currentItem.color"
      :icon-name="currentItem.name"
      :kanji-image="currentItem.backgroundImg"
      @click="openDropdown"
    />

    <outside-click-listener @outside-click="handleClickOutsideDropdown">
      <div
        v-if="toggleDropdown && items.length > 1"
        class="IobIconDropdown IobIconDropdown-container level-3"
        :style="{ width: `${width}px` }"
      >
        <div
          v-for="item in items"
          :key="item.name"
          @click="selectIconShape(item)"
        >
          <iob-icon-shape
            :icon-name="item.name"
            :size="size"
            type="square"
            :color="item.color"
            :kanji-image="item.backgroundImg"
          />
        </div>
      </div>
    </outside-click-listener>
  </div>
</template>

<script setup>
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import { ref, defineEmits, defineProps, watch, computed } from 'vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import { ICON_SHAPE_SIZES_MAP } from '../../../constants';

const props = defineProps({
  size: {
    type: String,
    default: 'xl'
  },
  iconName: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'other'
  },
  items: {
    required: true,
    type: Array
  },
  backgroundImg: {
    type: String,
    default: ''
  },
  selectedItem: {
    type: Object,
    default: () => ({})
  }
});
const getIconDropdownSize = (size) => ICON_SHAPE_SIZES_MAP[size] || ICON_SHAPE_SIZES_MAP.default;
const iconSize = ref(getIconDropdownSize(props.size));
const width = computed(() => 5 * iconSize.value + 4 * 16 + 48);
const toggleDropdown = ref(false);
const emits = defineEmits(['dropdownElementClick']);
let currentItem = ref(props.selectedItem);
const selectIconShape = (iconShape) => {
  emits('dropdownElementClick', iconShape);
  currentItem.value = iconShape;
  toggleDropdown.value = false;
};
const openDropdown = (e) => {
  e.stopPropagation();
  toggleDropdown.value = !toggleDropdown.value;
};
watch(() => props.selectedItem, (value) => {
  currentItem.value = value;
});

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};
watch(() => props.size, (newSize) => {
  iconSize.value = getIconDropdownSize(newSize);
});
</script>

<style lang='scss' src='./IobIconDropdown.scss' scoped />
