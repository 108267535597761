<template>
  <div>
    <div
      :class="{'input-label-field': true, 'dropdown-open': dropdownOpen}"
      @click="toggleDropdown"
      @mouseover="showDeleteIcon = true"
      @mouseleave="showDeleteIcon = false"
    >
      <div
        v-if="selectedItem"
        class="selected-info"
      >
        <span
          class="initials"
          :style="{ color: selectedItemColors.textColor, backgroundColor: selectedItemColors.bgColor }"
        >{{ getInitials(selectedItem) }}</span>
        <span>{{ selectedItem[labelField] }}</span>
      </div>
      <div
        v-else
        class="unassigned"
      >
        <template v-if="showStatus">
          <span
            class="initials"
            :style="statusStyle"
          >{{ getInitials(status) }}</span>
          <span>{{ status[labelField] }}</span>
        </template>
        <template v-else>
          <IconLoader
            name="Users"
            color="#5D587B"
            size="small"
            :has-background-color="true"
          />
          <span>Unassigned</span>
        </template>
      </div>
      <span
        v-if="(selectedItem || showStatus) && showDeleteIcon"
        class="delete-icon"
        @click.stop="clearSelectedItem"
      >
        <IconLoader
          name="xCircleFilled"
          :has-background-color="false"
          color="var(--action-button-color-icon-secondary-ghost-default)"
        />
      </span>
    </div>
    <outside-click-listener
      v-if="dropdownOpen"
      @outside-click="handleClickOutsideDropdown"
    >
      <div
        v-if="dropdownOpen && treeData.length"
        class="dropdown-menu"
      >
        <div
          v-for="(item, index) in treeData"
          :key="item.id"
        >
          <tree-item
            :data-id="item.id"
            :item="item"
            :expanded-nodes="expandedNodes"
            :colors="item.attributes.colors"
            :label-field="labelField"
            :selected-item="status"
            @select-item="selectItem"
            @toggle-node="toggleNode"
          />
          <div
            v-if="index < treeData.length - 1"
            class="separator-container"
          >
            <IobSeparator />
          </div>
        </div>
      </div>
    </outside-click-listener>
  </div>
</template>

<script>
import TreeItem from '../TreeItem/TreeItem.vue';
import IconLoader from '../../../IconLoader/IconLoader.vue';
import IobSeparator from '../../../Atoms/IobSeparator/IobSeparator.vue';
import { getInitials } from '../../../../utils';
import { UNASSIGNED } from '../../../../constants';
import OutsideClickListener from '../../../OutsideClickListener/OutsideClickListener.vue';

export default {
  name: 'TreeView',
  components: {OutsideClickListener, IobSeparator, TreeItem, IconLoader },
  props: {
    treeData: {
      type: Array,
      required: true
    },
    status: {
      type: Object,
      default: () => ({})
    },
    labelField: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dropdownOpen: false,
      expandedNodes: [],
      selectedItem: null,
      selectedItemColors: {},
      showDeleteIcon: false,
      isClearSelection: false,
      hasBeenExpanded: false
    };
  },
  computed: {
    showStatus() {
      return this.status &&
          this.status[this.labelField] !== UNASSIGNED &&
          this.status.colors &&
          !this.isClearSelection;
    },
    statusStyle() {
      return {
        color: this.status.colors.textColor,
        backgroundColor: this.status.colors.bgColor,
      };
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        this.expandedNodes = [];
        this.treeData.forEach(item => this.expandNodes(item, 0));
        this.$nextTick(() => this.scrollToSelectedItem());
      }
    },
    expandNodes(item, levelCount) {
      if (item.children && (this.status?.id || levelCount < 3)) {
        const stopExpand = levelCount >= 3 &&
            (this.expandedNodes.includes(this.status?.id) || item.id === this.status?.id);
        if (stopExpand) {
          return;
        }
        this.expandedNodes.push(item.id);
        item.children.forEach(child => {
          const shouldExpand = !this.status?.id ||
              (this.expandedNodes.includes(this.status.id) && levelCount < 3) ||
              this.isSelectedItemOrParent(child);
          if (shouldExpand) {
            this.expandNodes(child, levelCount + 1);
          }
        });
      }
    },
    isSelectedItemOrParent(item) {
      if (item.id === this.status?.id) {
        return true;
      }
      return item.children?.some(child => this.isSelectedItemOrParent(child)) ?? false;
    },
    scrollToSelectedItem() {
      const selectedElement = this.$el.querySelector(`.dropdown-menu [data-id="${this.status?.id}"]`);
      selectedElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    toggleNode(node) {
      const nodeId = node.id;
      const isExpanded = this.expandedNodes.includes(nodeId);
      this.hasBeenExpanded = true;
      if (isExpanded) {
        this.expandedNodes = this.expandedNodes.filter(id => id !== nodeId);
      } else {
        this.expandedNodes.push(nodeId);
      }
    },
    selectItem(item) {
      this.$emit('select-item', item);
      this.selectedItem = item.attributes;
      this.selectedItemColors = item.attributes.colors;
      this.dropdownOpen = false;
    },
    clearSelectedItem() {
      this.selectedItem = null;
      this.selectedItemColors = {};
      this.isClearSelection = true;
      this.$emit('select-item', {});
    },
    getInitials(item) {
      return getInitials(item[this.labelField]);
    },
    handleClickOutsideDropdown() {
      if (this.hasBeenExpanded) {
        this.hasBeenExpanded = false;
      } else {
        this.dropdownOpen = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped src="./TreeView.scss" />

