<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div
    v-if="fieldType === 'title' && fieldType.length"
    class="IobListItemAttributes-titleContainer"
  >
    <div class="IobListItemAttributes-title">
      {{ value }}
    </div>
  </div>
  <div 
    v-if="fieldType === 'label'"
    class="IobListItemAttributes-label"
  >
    <iob-icon-shape 
      :icon-name="value.icon"
      :color="value.color"
      size="xsmall"
      :is-clickable="false"
    />
    <span>
      {{ value.text }}
    </span>
  </div>
  <div
    v-if="fieldType === 'text' && fieldType.length"
    class="IobListItemAttributes-textContainer"
  >
    <div class="IobListItemAttributes-text">
      {{ value }}
    </div>
  </div>
  <iob-badge
    v-if="fieldType === 'progress'"
    size="s"
    color="secondary"
    type="outlined"
    :label="value"
  />
  <iob-badge
    v-if="fieldType === 'status'"
    size="s"
    :color="getBadgeColor"
    :label="value"
  />
  <iob-avatar
    v-if="fieldType === 'user'"
    :deleted-user="!value ? true : false"
    :picture="value.picture"
    :alt-text="value.altText"
    size="small"
  />
</template>
  
<script setup>
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue';
import IobAvatar from '../../../Molecules/IobAvatar/IobAvatar.vue';
import { defaultBadgeColors } from '../../../../constants';
import { defineProps, computed } from 'vue';

const props = defineProps({
  fieldType: {
    type: String,
    default: 'title',
  },
  value: {
    type: String,
    default: '',
  }
});

const getBadgeColor = computed(() => {
  return defaultBadgeColors[props.value]
});
</script>
  
<style lang="scss" src="./IobListItemAttributes.scss" scoped />
  