import { evaluate } from '@iobeya/vms-formula-evaluator/evaluator';
import { buildVmsTree } from '@iobeya/vms-formula-evaluator/vmsExprTree';
import DatasetElementContext from 'BOARD_ELEMENT_EDITOR/cardEditor/DatasetElementContext';

const utils = {
  generateNewCardIndex: (dataTypes) => {
    const cardsIndexes = [];
    let isFirstNameAssigned = false;
    dataTypes.forEach((dataType) => {
      const name = dataType.name.replace(/.*\//, '');
      if (name === 'NewCard') {
        cardsIndexes.push(1);
        isFirstNameAssigned = true;
      } else if (name.includes('NewCard(') && name[name.length - 1 ] === ')') {
        const startIndex = name.lastIndexOf('(');
        const index = name.substring(startIndex + 1, name.length - 1);
        const parsedIndex = parseInt(index);
        if (!isNaN(index)) {
          cardsIndexes.push(parsedIndex);
        }
      }
    });
    if (cardsIndexes.length === 1 && isFirstNameAssigned) {
      return 1;
    }
    if (cardsIndexes.length > 0) {
      const newValue = Math.max(...cardsIndexes) + 1;
      if (isNaN(newValue)) {
        return 0;
      }
      return newValue;
    }
    return 0;
  },
  setFormulas: (data) => data.reduce((acc, curr) => Object.assign(acc, {
    [`${curr.id}`]: curr }), {}),
  constructFormulasQuery: (formulasIds) => {
    let result = [];
    formulasIds.forEach((id) => {
      result = [...result, `ids=${id}`];
    });
    return result.join('&');
  },
  evaluateFormula: async (elementData, dataType, formula, allDataTypes) => {
    const evalCtx = new DatasetElementContext({ ...elementData, dataType, allDataTypes});
    try {
      const res = await evaluate(buildVmsTree(formula), evalCtx);
      return res;
    } catch (e) {
      console.error('Error evaluating formula', e);
      return null;
    }
  },
  initializeHierarchyColors: (hierarchy, colors, index = 0, isFirstLevel = true) => {
    if (!hierarchy) {
      return;
    }
    if (isFirstLevel) {
      hierarchy.attributes.colors = colors[index % colors.length];
      index++;
    }
    hierarchy.children.forEach((child) => {
      child.attributes.colors = colors[index % colors.length];
      utils.initializeHierarchyColors(child, colors, index + 1, false);
    });
  },
  buildElementsById: (data) => Object.fromEntries(data.childrenData.map((element) => [element.id, { ...element, children: [] }])),
  createRootElementClone: (rootElement, elementsById) => {
    const rootElementClone = { ...rootElement, children: [] };
    elementsById[rootElementClone.id] = rootElementClone;
    return rootElementClone;
  },
  buildChildren: (datasetElementsWithChildren, elementsById) =>
    datasetElementsWithChildren.map((relation) => ({
      ...relation,
      children: relation.datasetElementChildrenIds
        .map((childId) => elementsById[childId])
        .filter(Boolean)
        .map((child) => ({ ...child, parentId: relation.datasetElementId }))
    }))
};

export default utils;
