// @ts-check
import { models } from 'powerbi-client';

export default (result, token) => {
  if (!result) {
    return null;
  }

  /**
   * @type {import('../../../node_modules/powerbi-models/dist/models.d').IEmbedConfigurationBase}
   */
  const baseConfig = {
    type: result.contentType.toLowerCase(),
    accessToken: token,
    tokenType: models.TokenType.Aad
  };

  switch (result.contentType) {
  case 'App':
    return null;

  case 'Dashboard':{
    /**
       * @type {import('../../../node_modules/powerbi-models/dist/models.d').IDashboardEmbedConfiguration}
       */
    const dashboardEmbedConfig = {
      ...baseConfig,
      id: result.pathParams.dashboardId,
      embedUrl: `https://app.powerbi.com/dashboardEmbed?dashboardId=${result.pathParams.dashboardId}`
    };
    return dashboardEmbedConfig;}

  case 'Report':{
    /**
       * @type {import('../../../node_modules/powerbi-models/dist/models.d').IReportEmbedConfiguration}
       */
    const reportEmbedConfig = {
      ...baseConfig,
      settings: {
        panes: {
          filters: {
            visible: true
          },
          pageNavigation: {
            visible: true
          }
        },
        bars: {
          statusBar: {
            visible: true
          }
        },
        personalBookmarksEnabled: true
      },
      id: result.pathParams.reportId,
      pageName: result.pathParams.pageName,
      embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${result.pathParams.reportId}`,
      ...result.pathParams.bookmarkGuid && { bookmark: { name: result.pathParams.bookmarkGuid }}
    };
    return reportEmbedConfig;}

  case 'Visual':{
    /**
       * @type {import('../../../node_modules/powerbi-models/dist/models.d').IVisualEmbedConfiguration}
       */
    const visualEmbedConfig = {
      ...baseConfig,
      settings: {
        bars: {
          statusBar: {
            visible: true
          }
        },
        personalBookmarksEnabled: true
      },
      id: result.pathParams.reportId,
      pageName: result.pathParams.pageName,
      visualName: result.pathParams.visualId,
      embedUrl: `https://app.powerbi.com/reportEmbed?visualId=${result.pathParams.visualId}`,
      ...result.pathParams.bookmarkGuid && { bookmark: { name: result.pathParams.bookmarkGuid }}
    };
    return visualEmbedConfig;}

  default:
  {return null;}
  }
};
