<template>
  <div
    class="IobListItem focus-inside"
    tabindex="0"
  >
    <div class="IobListItem-left">
      <iob-icon-shape
        v-if="leftIconName && leftIconName.length"
        :icon-name="leftIconName"
        :color="leftIconColor"
        size="small"
      />
      <iob-list-item-attributes
        v-for="(item, index) in leftItems"
        :key="index"
        :field-type="item.fieldType"
        :value="item.value"
        :left-icon-name="leftIconName"
        :left-icon-color="leftIconColor"
      />
    </div>
    <div
      class="IobListItem-right"
      :style="rightItemsWithValue.length > 0 ? 'grid-template-columns: repeat(' + rightItemsWithValue.length + ', minmax(40px, 1fr))' : ''"
      @click.stop
    >
      <iob-list-item-attributes
        v-for="(item, index) in rightItems"
        :key="index"
        :field-type="item.fieldType"
        :value="item.value"
      />
      <icon-loader
        v-if="hasDetails"
        id="detailsIcon"
        name="MoreHorizontal"
        size="large"
        color="#787391"
        @icon-click="emits('onClickDetailsIcon')"
      />
    </div>
  </div>
</template>

<script setup>
import IobListItemAttributes from './IobListItemAttributes/IobListItemAttributes.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import { defineProps, defineEmits, computed } from 'vue';

const emits = defineEmits([
  'onClickDetailsIcon'
]);

const props = defineProps({
  leftItems: {
    type: Array,
    default: () => [],
  },
  rightItems: {
    type: Array,
    default: () => [],
  },
  leftIconName: {
    type: String,
    default: '',
  },
  leftIconColor: {
    type: String,
    default: 'other',
  },
  hasDetails: {
    type: Boolean,
    default: true
  }
});

const rightItemsWithValue = computed(() => {
  return props.rightItems.filter(item => item.fieldType === 'title');
});
</script>

<style lang="scss" src="./IobListItem.scss" scoped />
