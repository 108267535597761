<template>
  <div>
    <slot
      :err="err"
      :vm="vm"
      :info="info"
    />
    <dialog-box
      v-if="errors.length && !!err"
      class="z-50"
      :dialog-box-specific-style="dialogBoxStyle"
      :no-padding="true"
    >
      <pop-up
        :details-object="errors[0]"
        title="Something went wrong"
        @popup-button-click="handleError"
      />
    </dialog-box>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { API_HOST } from 'SRC/config/api';

export default {
  name: 'ErrorHandler',
  props: {
    stopPropagation: Boolean
  },
  data() {
    return {
      dialogBoxStyle: 'position: absolute; left: 35%; top: 35%; width: 35%;',
      err: null,
      vm: null,
      info: null,
      API_HOST
    };
  },
  computed: {
    ...mapState('app', [
      'errors'
    ])
  },
  errorCaptured(err, vm, info) {
    const errors = this.errors;
    errors.push({Error: err, Information: info});
    this.setErrors(errors);
    this.err = err;
    this.vm = vm;
    this.info = info;
  },
  methods: {
    ...mapMutations('app', [
      'setErrors'
    ]),
    handleError() {
      const errors = this.errors;
      errors.shift();
      this.setErrors(errors);
      if (errors.length === 0) {
        this.err = false;
      }
    }
  }
};
</script>
