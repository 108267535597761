<template>
  <td
    class="IobTableCell"
    :class="[autoWidthClass, {'IobTableCell-content--greyedOut': props.enableGreyedOut && props.data && props.data.isDisabled}]"
    :style="{ width: props.width, borderRight: props.hasVerticalSeparator ? '1px solid var(--color-separator-base-low-contrast-on-container, #CCCBD6)' : 'none' }"
  >
    <div
      class="IobTableCell-content"
      :style="{maxWidth: props.maxWidth,
               minWidth: props.minWidth}"
      :class="classList"
    >
      <iob-table-cell-skeleton
        v-if="props.isLoading"
        :type="props.type"
        :icons="props.icons"
      />
      <div v-else-if="props.data.isDisabled" />
      <template v-else-if="props.type === 'titleWithIconShape'">
        <iob-checkbox
          :id="props.data.id"
          :checked="props.data.checked"
          @change-checkbox="handelClickItem"
        />
        <iob-icon-shape-dropdown
          :icon-name="props.data.icon"
          :title="props.data.title"
          :color="props.data.color"
        />
      </template>
      <template v-else-if="props.type === 'title'">
        <iob-cover
          v-if="props.data.showCover && props.data.coverImg"
          :src="props.data.coverImg"
          :preview="preview"
          :background-color="props.data.backgroundColor"
          size="s"
        />
        <iob-skeleton
          v-if="props.data.showCover && !props.data.coverImg"
          width="var(--illustration-cover-size-small)"
          shape="rect"
        />
        <div style="flex-grow: 1;">
          <div
            class="IobTableCell-content-span" 
            :style="{ minWidth: '100%', width: 0 }"
          >
            <span
              v-for="(part, index) in highlightedTitle"
              :key="`${part.text}-${index}`"
              :class="{ 'IobTableCell-content--highlighted': part.highlight}"
            >{{ part.text }}</span>
          </div>
        </div>
        <iob-badge
          v-if="props.data.isDraft"
          color="secondary"
          type="outlined"
          :label="props.data.draftText || 'Draft'"
          size="s"
        />
      </template>
      <template v-else-if="props.type === 'label'">
        <div>
          <span
            v-for="(part, index) in highlightedLabel"
            :key="`${part.text}-${index}`"
            :class="{ 'IobTableCell-content--highlighted': part.highlight}"
          >{{ part.text }}</span>
        </div>
      </template>
      <template v-else-if="props.type === 'badge'">
        <iob-badge
          v-for="tag in props.data.statusList"
          :key="tag"
          :label="tag.label"
          :color="name === 'STATUS' ? defaultBadgeColors[tag.label] : tag.color"
          :icon="tag.icon"
          size="s"
        />
      </template>
      <template v-else-if="props.type === 'user'">
        <iob-avatar-with-name
          inert
          :name="props.data.name"
          :picture="props.data.img"
          size="large"
        />
      </template>
      <template v-else-if="props.type === 'users'">
        <iob-avatar-group :collaborators="props.data" />
      </template>
      <template v-else-if="props.type === 'icons'">
        <iob-action-button
          v-for="(icon, index) in props.data"
          :id="`${icon}-${rowIndex}`"
          :key="index"
          type="ghost"
          color="secondary"
          :icon-name="icon"
          @click="handelClickItem(icon)"
        />
      </template>
      <template v-else-if="props.type === 'button'">
        <iob-button
          type="ghost"
          color="primary"
          :label="props.data"
          @click="handelClickItem('button')"
        />
      </template>
      <template v-else-if="props.type === 'progress'">
        <div class="IobTableCell-content-progress">
          <h3>
            {{ props.data.progress }}%
          </h3>
          <iob-progress-bar
            :color="props.data.color"
            :progress="props.data.progress"
          />
        </div>
      </template>
    </div>
  </td>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import IobCover from '../../Cover/IobCover.vue';
import IobProgressBar from '../../../Atoms/IobProgressBar/IobProgressBar.vue';
import IobAvatarWithName from '../../IobAvatarWithName/IobAvatarWithName.vue';
import IobAvatarGroup from '../../IobAvatarGroup/IobAvatarGroup.vue';
import IobActionButton from '../../IobActionButton/IobActionButton.vue';
import IobButton from '../../IobButton/IobButton.vue';
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue';
import IobIconShapeDropdown from '../../IobIconShapeDropdown/IobIconShapeDropdown.vue';
import IobCheckbox from '../../../Atoms/IobCheckbox/IobCheckbox.vue';
import { defaultBadgeColors } from '../../../../constants';
import IobTableCellSkeleton from '../IobTableCellSkeleton/IobTableCellSkeleton.vue';
import IobSkeleton from '../../../Atoms/IobSkeleton/IobSkeleton.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'label',
    validator: (value) => ['checkbox', 'label', 'title', 'badge', 'user', 'users', 'icons', 'button'].includes(value)
  },
  data: {
    type: Object,
    default: () => ({})
  },
  name: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: 'auto'
  },
  maxWidth: {
    type: String,
    default: 'auto'
  },
  minWidth: {
    type: String,
    default: 'auto'
  },
  hasVerticalSeparator: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  icons: {
    type: Array,
    default: () => []
  },
  searchData: {
    type: String,
    default: ''
  },
  enableGreyedOut: {
    type: Boolean,
    default: false
  },
  rowIndex: {
    type: Number,
    default: 0
  }
});

const classList = computed(() => {
  return {
    'IobTableCell-content--small': props.type === 'tags',
    'IobTableCell-content-title': props.type === 'title'
  }
});

const autoWidthClass = computed(() => props.width === 'auto' ? 'IobTableCell--autoWidth' : '');
const preview = computed(() => props.data.withPreview ? { title: props.data.title, image: props.data.coverImg } : null);
const highlightedTitle = computed(() => {
  return searchResult(props.data.title);
});
const highlightedLabel = computed(() => {
  return searchResult(props.data);
});

const searchResult = (text)=>{
  if (!props.searchData) {
    return [{ text, highlight: false }];
  }
  const parts = text.split(new RegExp(`(${props.searchData})`, 'gi'));
  return parts.map(part => ({
    text: part,
    highlight: part.toLowerCase() === props.searchData.toLowerCase(),
  }));
}
const emits = defineEmits(['onClickItem']);

const handelClickItem = (item) => {
  emits('onClickItem', item);
}

</script>

<style lang="scss" scoped>
.IobTableCell {
  height: var(--app-table-row-sizing-height);
  padding: 0 var(--app-table-row-padding-horizontal);
  overflow: hidden;
  color: var(--app-table-row-color-text-default);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* 100% */
  letter-spacing: 0.14px;

  &-clickable:hover {
    text-decoration: underline;
  }

  &--autoWidth {
    flex: 1 0 0;
  }

  &-content {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: var(--app-table-row-gap-large);

    &--small {
      gap: var(--app-table-row-gap-small);
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.14px;
    }

    &-span {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;

    }

    &--highlighted {
      font-weight: 800
    }

    &--greyedOut {
      background-color: #E6E6ED;
    }

    &-progress {
      width: 100%
    }

    .IobAvatarWithName {
      pointer-events: none;
    }
  }
}
</style>
