<template>
  <div
    v-if="showModal"
    class="IobModal"
  >
    <outside-click-listener
      class="IobModal-container"
      :class="classList"
      @outside-click="handleOutsideClick"
    >
      <iob-sidebar
        v-if="isSidebar"
        :size="props.size"
        class="IobModal--sideBar"
        elevation="3"
      >
        <iob-sidebar-header>
          <slot name="sidebarHeader">
            <div class="IobModal--side-bar-header">
              <div class="IobModal--side-bar-title">
                <div class="IobModal--side-bar-title-text">
                  <span>{{ title }}</span>
                </div>
                <iob-action-button
                  class="IobModal--side-bar-title-button"
                  size="small"
                  color="secondary"
                  type="ghost"
                  state="default"
                  @click="closeModal"
                />
              </div>
            </div>
          </slot>
        </iob-sidebar-header>
        <iob-sidebar-content
          class="IobModal--side-bar-content"
        >
          <div class="IobModal--side-bar-content-container">
            <slot name="sidebarContent" />
          </div>
        </iob-sidebar-content>
        <iob-sidebar-footer v-if="hasSidebarFooter">
          <div class="IobModal--side-bar-footer">
            <slot name="sidebarFooter" />
          </div>
        </iob-sidebar-footer>
      </iob-sidebar>
      <slot v-else />
      <div
        v-if="modalType === 'popup' && ['xSmall','small','large', 'medium'].includes(size)"
        :class="`IobModal--popup-${size}`"
      >
        <div
          v-if="popupSidebar === 'left' && modalType === 'popup' && ['large', 'medium'].includes(size)"
          :class="`IobModal--popup-${size}-sidebar`"
        >
          <div :class="`IobModal--popup-${size}-sidebar-header`">
            <slot name="popupSidebarHeader" />
          </div>
          <div :class="`IobModal--popup-${size}-sidebar-content`">
            <slot name="popupSidebarContent" />
          </div>
          <div :class="`IobModal--popup-${size}-sidebar-footer`">
            <slot name="popupSidebarFooter" />
          </div>
        </div>
        <div :class="`IobModal--popup-${size}-content`">
          <div :class="`IobModal--popup-${size}-content-top`">
            <div :class="`IobModal--popup-${size}-content-top-header`">
              <slot name="popupHeader">
                <div :class="`IobModal--popup-${size}-content-top-header-left`">
                  <span :class="`IobModal--popup-${size}-content-top-header-left-title`">{{ title }}</span>
                  <span :class="`IobModal--popup-${size}-content-top-header-left-description`">{{ description }}</span>
                </div>
              </slot>
              <div
                v-if="['left', 'none'].includes(popupSidebar)"
                :class="`IobModal--popup-${size}-content-top-header-right`"
              >
                <iob-action-button
                  v-if="showMoreMenu"
                  size="medium"
                  color="secondary"
                  type="ghost"
                  state="default"
                  icon-name="MoreHorizontal"
                />
                <iob-action-button
                  size="medium"
                  color="secondary"
                  type="ghost"
                  state="default"
                  @click="closeModal"
                />
              </div>
            </div>
            <div :class="`IobModal--popup-${size}-content-content`">
              <slot name="popupContent" />
            </div>
          </div>
          <div :class="`IobModal--popup-${size}-content-footer`">
            <slot name="popupFooter" />
          </div>
        </div>
        <div
          v-if="popupSidebar === 'right' && modalType === 'popup' && ['large', 'medium'].includes(size)"
          :class="`IobModal--popup-${size}-sidebar`"
        >
          <div :class="`IobModal--popup-${size}-sidebar-header`">
            <slot name="popupSidebarHeader" />
            <div
              v-if="popupSidebar === 'right'"
              :class="`IobModal--popup-${size}-sidebar-header-right`"
            >
              <iob-action-button
                v-if="showMoreMenu"
                size="medium"
                color="secondary"
                type="ghost"
                state="default"
                icon-name="MoreHorizontal"
              />
              <iob-action-button
                size="medium"
                color="secondary"
                type="ghost"
                state="default"
                @click="closeModal"
              />
            </div>
          </div>
          <div :class="`IobModal--popup-${size}-sidebar-content`">
            <slot name="popupSidebarContent" />
          </div>
          <div :class="`IobModal--popup-${size}-sidebar-footer`">
            <slot name="popupSidebarFooter" />
          </div>
        </div>
      </div>
    </outside-click-listener>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import IobSidebar from '../../Atoms/Sidebar/IobSidebar.vue';
import IobSidebarContent from '../../Atoms/Sidebar/SidebarContent/IobSidebarContent.vue';
import IobSidebarFooter from '../../Atoms/Sidebar/SidebarFooter/IobSidebarFooter.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';

const props = defineProps({
  showModal: { type: Boolean, default: false },
  shouldCloseOnOutsideClick: { type: Boolean, default: true },
  modalType: { type: String, default: 'popup', validator: (value) => ['popup', 'sidebar-left', 'sidebar-right', 'bottom-sheet'].includes(value) },
  size: { type: String, default: 'small', validator: (value) => ['xSmall', 'small', 'medium', 'large'].includes(value) },
  hasSidebarFooter: { type: Boolean, default: false },
  title: { type: String, default: '' },
  description: { type: String, default: '' },
  popupSidebar : { type: String, default: 'none' },
  showMoreMenu: { type: Boolean, default: false }
});
const emit = defineEmits(['close']);
const handleOutsideClick = () => {
  if (props.shouldCloseOnOutsideClick) {
    closeModal();
  }
}
const closeModal = () => emit('close');
const isSidebar = computed(() => ['sidebar-left', 'sidebar-right'].includes(props.modalType));
const classList = computed(() => {
  return [
    {
      'IobModal--popup': props.modalType === 'popup',
      'IobModal--popup-small': props.modalType === 'popup' && props.size === 'small',
      'IobModal--popup-medium': props.modalType === 'popup' && props.size === 'medium',
      'IobModal--popup-large': props.modalType === 'popup' && props.size === 'large',
      'IobModal--side-bar': isSidebar.value,
      'IobModal--side-bar-left': props.modalType === 'sidebar-left',
      'IobModal--side-bar-right': props.modalType === 'sidebar-right',
      'IobModal--bottom-sheet': props.modalType === 'bottom-sheet'

    }
  ]
});
</script>

<style lang="scss" scoped src="./IobModal.scss" />
<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-modal.scss" />
