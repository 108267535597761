<template>
  <div
    tabindex="0"
    class="IobRoutineCard level-1 level-container focus-outside"
    :class="ghostClass"
  >
    <div
      v-if="!ghost"
      class="IobRoutineCard-header"
    >
      <div class="IobRoutineCard-header-left">
        <iob-skeleton 
          v-if="isLoading"
          shape="rect"
          width="var(--icon-shape-sizing-default)"
        />
        <iob-icon-shape
          v-else
          :icon-name="iconName"
          :color="iconColor"
          :is-clickable="false"
          :kanji-image="iconKanji"
        />
      </div>
      <div class="IobRoutineCard-header-right">
        <iob-badge
          v-if="badgeText && !isLoading"
          :color="badgeColor"
          size="xs"
          :label="badgeText"
        />
        <div class="IobRoutineCard-header-right-actions">
          <iob-action-button
            v-if="!isLoading"
            color="secondary"
            type="ghost"
            icon-name="MoreVertical"
            :selected="showDropdown"
            @click.stop="toggleDropdown"
          />
          <outside-click-listener
            @outside-click="showDropdown = false"
          >
            <iob-dropdown 
              v-show="showDropdown && actions.length"
              ref="dropdownRef"
              :style="{[direction]: 0}"
              class="IobRoutineCard-header-right-actions-dropdown"
              :items="actions"
              @dropdown-element-item.self="handleActionSelect"
            />
          </outside-click-listener>
        </div>
        <iob-skeleton 
          v-if="isLoading"
          height="var(--badge-sizing-vertical-xs)"
          width="calc(var(--badge-sizing-vertical-xs) * 2)"
        />
        <iob-skeleton 
          v-if="isLoading"
          shape="icon"
          icon-name="MoreVertical"
          icon-size="large"
        />
      </div>
    </div>

    <div class="IobRoutineCard-content">
      <iob-skeleton 
        v-if="isLoading"
        width="60%"
        height="16px"
      />
      <div
        v-else
        class="IobRoutineCard-content-title"
      >
        {{ title }}
      </div>
      <div
        v-if="!ghost && !isLoading"
        class="IobRoutineCard-content-subtitle"
      >
        {{ subtitle }}
      </div>
      <iob-skeleton 
        v-if="isLoading"
        width="80%"
        height="16px"
      />
      <iob-skeleton 
        v-if="isLoading"
        width="40%"
        height="16px"
      />
    </div>
  </div>
</template>

<script setup>
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import IobSkeleton from '../../Atoms/IobSkeleton/IobSkeleton.vue';
import { computed, defineProps, ref } from 'vue';

const props = defineProps({
  iconName: {
    type: String,
    default: 'Hand'
  },
  iconColor: {
    type: String,
    default: 'primary'
  },
  iconKanji: {
    type: String,
    default: '',
    validator: (value) => {
      return ['01', '02', '03', '04'].includes(value);
    }
  },
  badgeText: {
    type: String,
    default: ''
  },
  badgeColor: {
    type: String,
    default: 'primary',
    validator: (value) => {
      return ['primary', 'secondary', 'alert', 'success', 'warning', 'info', 'other'].includes(value);
    },
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  ghost: {
    type: Boolean,
    default: false
  },
  actions: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});
const dropdownRef = ref(null);
const direction = ref('right');
const showDropdown = ref(false);
const emit = defineEmits(['action-select']);

function toggleDropdown(event) {
  if (event.pageX > window.innerWidth / 2) {
    direction.value = 'right';
  } else {
    direction.value = 'left';
  }
  showDropdown.value = !showDropdown.value;
}

function handleActionSelect( { item }) {
  const index = props.actions.indexOf(item);
  emit('action-select', index);
}
const ghostClass= computed(() => props.ghost ? 'IobRoutineCard-ghost' : 'elevation-on-hover');
</script>

<style lang="scss" scoped>
.IobRoutineCard {
  display: flex;
  width: 100%;
  height: 148px;
  padding: var(--app-card-routine-padding-default, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--app-card-routine-gap-default, 16px);
  border-radius: var(--app-card-routine-radius-global, 8px);
  &:hover:not(.IobRoutineCard-ghost) {
    cursor: pointer;

    .IobRoutineCard-content-title {
      color: var(--app-card-routine-color-text-title-hover, #2D6BFF);
    }
  }

  &-header {
    display: flex;
    width: 100%;
    align-items: center;
    gap: var(--app-card-routine-gap-header, 16px);

    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--app-card-routine-gap-small, 8px);

      &-actions {
        position: relative;
        
        &-dropdown {
          position: absolute;
          top: 100%;
          transform: translateY(8px);
        }
      }
    }
  }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--app-card-routine-gap-small, 8px);
      flex-grow:1;
      width: 100%;

      &-title {
        color: var(--app-card-routine-color-text-title, #242230);

        font-family: "Noto Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 0;
        min-width: 100%;
      }

      &-subtitle {
        color: var(--app-card-routine-color-text-subtitle, #787391);
        word-break: break-word;
        hyphens: auto;
        font-family: "Noto Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.12px;
        overflow-y: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  
    &-ghost {
      border: 1px dashed var(--elevation-color-border, #CCCBD6);
      align-items: center;
      justify-content: center;
  
      .IobRoutineCard-content {
        align-items: center;
        flex-grow: inherit;
  
        &-title {
          color: var(--app-card-routine-color-text-title-hover, #2D6BFF);
          text-align: center;
        }
      }
    }
  
    }

</style>
