export class DatesSorter {
  /**
    * constructor description
    * @param  String frequency
    * @param  String currentDate
    */
  constructor(frequency, datesArray) {
    this._frequency = frequency;
    this._datesArray = datesArray;
  }
  /**
     * @description - Get frequency of the date array
     */
  get frequency() {
    return this._frequency;
  }
  /**
     * @param  String frequency - Frequency of the date array
     */
  set frequency(frequency) {
    this._frequency = frequency;
  }
  /**
     * @description - Get frequency of the date array
     */
  get datesArray() {
    return this._datesArray;
  }
  /**
     * @param  String frequency - Frequency of the date array
     */
  set datesArray(value) {
    this._datesArray = value;
  }
  #frequencyMethodMap = {
    Monthly: 'compareMonths',
    Yearly: 'compareYears',
    Quarterly: 'compareQuarters',
    Semesterly: 'compareSemesters',
    Daily: 'compareDays',
    Weekly: 'compareWeeks'
  };
  monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
    /**
     * @description generates the currentDateOccurence given a frequency
     * @returns String
     */
  generate() {
    if (!this.#frequencyMethodMap[this.frequency]) {
      return [];
    }
    return this.datesArray.toSorted(this[this.#frequencyMethodMap[this.frequency]].bind(this));
  }
  compareMonths(month1, month2) {
    const year1 = parseInt(month1.substr(-4));
    const year2 = parseInt(month2.substr(-4));
    if (year1 !== year2) {
      return year1 - year2;
    }
    const month1Rank = this.monthsArray.indexOf(month1.substr(0, 3));
    const month2Rank = this.monthsArray.indexOf(month2.substr(0, 3));
    return month1Rank - month2Rank;
  }
  compareYears(year1, year2) {
    const parsedYear1 = parseInt(year1);
    const parsedYear2 = parseInt(year2);
    return parsedYear1 - parsedYear2;
  }
  compareQuarters(quarter1, quarter2) {
    const year1 = parseInt(quarter1.substr(-4));
    const year2 = parseInt(quarter2.substr(-4));
    if (year1 !== year2) {
      return year1 - year2;
    }
    const quarter1Rank = parseInt(quarter1.substr(1, 1));
    const quarter2Rank = parseInt(quarter2.substr(1, 1));
    return quarter1Rank - quarter2Rank;
  }
  compareSemesters(semester1, semester2) {
    const year1 = parseInt(semester1.substr(-4));
    const year2 = parseInt(semester2.substr(-4));
    if (year1 !== year2) {
      return year1 - year2;
    }
    const semester1Rank = parseInt(semester1.substr(1, 1));
    const semester2Rank = parseInt(semester2.substr(1, 1));
    return semester1Rank - semester2Rank;
  }
  compareWeeks(week1, week2) {
    const year1 = parseInt(week1.substr(-4));
    const year2 = parseInt(week2.substr(-4));
    if (year1 !== year2) {
      return year1 - year2;
    }
    const week1Rank = parseInt(week1.substr(1, week1.indexOf(' ') - 1));
    const week2Rank = parseInt(week2.substr(1, week2.indexOf(' ') - 1));
    return week1Rank - week2Rank;
  }
  compareDays(day1, day2) {
    const day1month = day1.substr(-8);
    const day2month = day2.substr(-8);
    if (day1month !== day2month) {
      return this.compareMonths(day1month, day2month);
    }
    const day1Number = parseInt(day1.substr(0, day1.substr(0, day1.indexOf(' '))));
    const day2Number = parseInt(day2.substr(0, day2.substr(0, day2.indexOf(' '))));
    return day1Number - day2Number;
  }
}
