<template>
  <div class="imageSelection">
    <iob-image-text-element
      v-for="(item, index) in items"
      :key="index"
      :text="item.text"
      :img-src="item.imgSrc || ''"
      is-checkable
      :checked="item.value === selectedItem"
      @check="(checkedValue) => handleCheck(checkedValue, item.value)"
    />
  </div>
</template>
<script setup>
import IobImageTextElement from '../IobImageTextElement/IobImageTextElement.vue';
import {ref} from 'vue';
const emit = defineEmits(['update:selectedItem']);
const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  selected: {
    type: String,
    default: ''
  }
});
const selectedItem = ref(props.selected);

const handleCheck = (checkedValue, itemValue) => {
  const newValue = checkedValue ? itemValue : null;
  selectedItem.value = newValue;
  emit('update:selectedItem', newValue);
};
</script>

<style scoped>
.imageSelection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px,1fr));
  gap: 8px var(--app-form-group-gap-default-horizontal, 8px);
}
</style>
