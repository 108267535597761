import { get } from 'SRC/api';

export const getChildrenIds = async (datasetElementId) => {
  const hierarchicalRelationsData = await get(`/hierarchical-relations?datasetElementIds=${datasetElementId}`);
  if (hierarchicalRelationsData && hierarchicalRelationsData.data && hierarchicalRelationsData.data.length) {
    const { datasetElementChildrenIds }  = hierarchicalRelationsData.data[0];
    return datasetElementChildrenIds;
  }
  return [];
};
