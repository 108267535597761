import { SimpleDatasetElementContext, evaluate } from '@iobeya/vms-formula-evaluator/evaluator';
import { buildVmsTree } from '@iobeya/vms-formula-evaluator/vmsExprTree';
import { PAGE_SIZE } from 'GLOBALS/constants';
import { getAllItemsFromAllPages } from 'SRC/store/editor/hierarchy/actions';
import { fetchChildrenIds } from './utils/fetchChildrenIds';

class DatasetElementContext extends SimpleDatasetElementContext {
  async ownChildren() {
    const childrenIds = await fetchChildrenIds(this._datasetElement.datasetElementId);
    if (!(childrenIds && childrenIds.length > 0)) {
      return [];
    }
    const query = childrenIds.map(({id}) => `ids=${id}`).join('&');
    const childrenDatasetElements = await getAllItemsFromAllPages(`/dataset-elements?page=1&size=${PAGE_SIZE}&${query}`);
    return childrenDatasetElements
      .map((elem) => new DatasetElementContext({
        ...elem,
        attributes: {...elem.attributes, typeName: elem.typeName}
      }, this.super));
  }
  async ownAttribute(name) {
    if (!this._datasetElement.dataType) {
      return this.simpleOwnAttribute(name);
    }
    const computedByFormula = this._datasetElement
      .dataType.attributes.find((attr) => attr.name === name && attr.computedByFormula);
    if (computedByFormula) {
      const formula = this._datasetElement.formulas[`${name}--${this._datasetElement.dataType.name}`];
      if (formula) {
        const formulaExpression = formula.expression;
        const value = await evaluate(buildVmsTree(formulaExpression), this);
        return value;
      }
    }
    return this.simpleOwnAttribute(name);
  }
}
export default DatasetElementContext;
