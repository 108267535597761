/**
 * Adds a title element to the dropdown data based on the type name.
 * @param {array} elements
 * @param {string} typeName
 * @returns {array}
 */
export const addTitleToDropdownData = (elements, typeName) => {
  elements = elements.filter((item) => item.type !== 'title');
  elements.unshift({
    title: `Recent ${typeName}s`,
    type: 'title',
    color: 'var(--gray-500, #787391)'
  });
  return elements;
};

/**
 * Returns the data with the required format.
 * @param {array} data
 * @returns {array}
 */
export const formatDatasetElements = (data) =>
  Object.values(data).map((item) => ({
    title: item.attributes['friendly-id'],
    text: item.attributes.title || '',
    id: item.id,
    attributes: item.attributes,
    typeId: item.typeId,
    shapeColor: item.attributes.color
  }));

export const checkIfStringStartsWithVowal = (value) => /^[aeiou]/i.test(value) ? 'an' : 'a';

export const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};
