import {
  getChildrenIds
} from './utils';
export const fetchChildrenIds = async (datasetElementId) => {
  const childrenIds = await getChildrenIds(datasetElementId);
  if (childrenIds) {
    let formattedDatasetElementChildrenIds = childrenIds.map((e) => ({id: e, isFinal: false}));
    let existsChildren = true;
    while (existsChildren) {
      let newChildrenLayer = await Promise.all(formattedDatasetElementChildrenIds
        .filter(((el) => !el.isFinal))
        .map((child) => getChildrenIds(child.id)));
      formattedDatasetElementChildrenIds = formattedDatasetElementChildrenIds.map((el) => ({id: el.id, isFinal: true}));
      newChildrenLayer = newChildrenLayer.flat().map((el) => ({id: el, isFinal: false}));
      formattedDatasetElementChildrenIds = [...formattedDatasetElementChildrenIds, ...newChildrenLayer];
      existsChildren = formattedDatasetElementChildrenIds.some((el) => !el.isFinal);
    }
    return formattedDatasetElementChildrenIds;
  }
};
