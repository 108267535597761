<template>
  <div class="IobCarousel">
    <IobImage
      :src="imageUrl"
      render-type="fit-to-box"
    />
    <div class="IobCarousel-container">
      <div class="IobCarousel-container-title">
        {{ title }}
      </div>
      <div class="IobCarousel-container-content">
        {{ content }}
      </div>
      <div class="IobCarousel-container-actions">
        <iob-button
          v-for="(action,index) in actions"
          :key="index"
          v-bind="action"
          @click="handleActionClicked(index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import IobImage from '../../Atoms/IobImage/IobImage.vue';
import IobButton from '../IobButton/IobButton.vue';
import { defineProps, defineEmits } from 'vue';

defineProps({
  imageUrl: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
  actions: {
    type: Array,
    default: () => ([])
  }
});

const emit = defineEmits(['onActionCicked']);

const handleActionClicked = (index) => {
  emit('onActionCicked', index);
}

</script>

<style lang="scss" scoped>
.IobCarousel{
  width: 100%;
  height: 100%;
  border-radius: var(--caroussel-radius-default, 8px);
  overflow: hidden;
  position: relative;

  &-container{
    display: flex;
    padding: 24px var(--caroussel-padding-content, 24px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: var(--caroussel-gap-content, 8px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-title{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      max-width: var(--caroussel-size-text-max-width, 640px);
      overflow: hidden;
      color: var(--caroussel-color-title, #FFF);
      text-overflow: ellipsis;
      font-family: 'Markazi Text', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.32px;
    }

    &-content {
      display: flex;
      padding-bottom: var(--caroussel-padding-description, 8px);
      justify-content: center;
      align-items: flex-end;
      gap: var(--app-card-selection-gap-large, 12px);
      max-width: var(--caroussel-size-text-max-width, 640px);

      color: var(--caroussel-color-content, #FFF);
      font-family: 'Noto Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-actions {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: var(--caroussel-gap-actions, 12px);
      align-self: stretch;
    }
  }

}
</style>
