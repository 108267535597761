<script setup>
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobButton from '../IobButton/IobButton.vue';
import { computed, defineProps, defineEmits } from 'vue';
import IobLabelField from '../IobLabelField/IobLabelField.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
const props = defineProps({
  title: { type: String, default: '' },
  subTitle: { type: String, default: '' },
  isSmallTitle: { type: Boolean, default: false },
  showButton: { type: Boolean, default: true },
  buttonLabel: { type: String, default: '' },
  buttonIcon: { type: String, default: '' },
  searchPlaceholder: { type: String, default: '' },
  sortButton: { type: Boolean, default: true },
  filterButton: { type: Boolean, default: true },
  filterDetails: {type: Object , default: () => ({label: '', showRightIcon: true, icon:'ListFilter', showLeftIcon: false})},
  listViewButton: { type: Boolean, default: true },
  gridViewButton: { type: Boolean, default: false },
  muralViewButton: { type: Boolean, default: false },
  alignSearchSectionRight: { type: Boolean, default: true },
  hasSearchInput: { type: Boolean, default: true },
  searchInput: { type: String, default: '' },
  moreBtn: { type: Boolean, default: false },
  badgeText: { type: String, default: '' },
  badgeColor: { type: String, default: 'primary', validator: (value) => ['primary', 'secondary', 'alert', 'success', 'warning', 'info', 'other'].includes(value) },
});
const showSubtitle = computed(() => props.subTitle !== null && props.subTitle !== undefined && props.subTitle !== '');
const emits = defineEmits(['search', 'buttonClicked', 'sort', 'filter', 'listView', 'gridView', 'muralView']);
const handleButtonClick = () => {
  emits('buttonClicked');
};
const handleSortButtonClick = () => {
  emits('sort');
};
const handleFilterButtonClick = () => {
  emits('filter');
};
const handleListButtonClick = () => {
  emits('listView');
};
const handleLayoutGridButtonClick = () => {
  emits('gridView');
};
const handleLayoutMuralButtonClick = () => {
  emits('muralView');
};

const handleSearch = (searchValue) => {
  emits('search', searchValue);
};

const handleMoreMenuButton = () => {
  emits('moreMenu');
};

const subtitleColor = 'var(--header-action-color-subtitle, #5d587b)';
const titleClass = computed(() => {
  return props.isSmallTitle ? 'IobHeaderAction-left-titleSmall' : 'IobHeaderAction-left-title';
});
const contentClass = computed(() => {
  return props.alignSearchSectionRight ? 'IobHeaderAction-content-spceBetween' : ''
});
const showViewButtons = computed(() => {
  return props.listViewButton || props.gridViewButton || props.muralViewButton;
});
const isSortOrFilterButtonVisible = computed(() => {
  return props.sortButton || props.filterButton;
});
const showSeparator = computed(() => {
  return showViewButtons.value || isSortOrFilterButtonVisible.value;
});
const filterDetailsComputed = computed(() => props.filterDetails);
const input = computed(() => props.searchInput);
</script>

<template>
  <div class="IobHeaderAction">
    <div
      class="IobHeaderAction-content"
      :class="contentClass"
    >
      <div class="IobHeaderAction-left">
        <div class="IobHeaderAction-left-titleGroup">
          <span
            v-if="title"
            :class="titleClass"
          > {{ title }}</span>
          <IconLoader
            v-if="title && showSubtitle"
            name="Dot"
            :color="subtitleColor"
            size="large"
          />
          <span
            v-if="showSubtitle"
            class="IobHeaderAction-left-subtitle"
            data-test="IobHeaderAction-leftSubtitle"
          >{{ subTitle }}</span>
        </div>
        <IobButton
          v-if="showButton"
          size="medium"
          color="primary"
          type="filled"
          state="default"
          :label="buttonLabel"
          :left-icon="buttonIcon"
          :show-left-icon="true"
          @click="handleButtonClick"
        />
        <iob-badge
          v-if="badgeText"
          size="xs"
          :label="badgeText"
          :color="badgeColor"
        />
      </div>
      <div class="IobHeaderAction-right">
        <IobButton
          v-if="sortButton"
          color="secondary"
          show-right-icon
          right-icon="ArrowDownWideNarrow"
          size="medium"
          type="ghost"
          class="IobHeaderAction-right-icon"
          @click="handleSortButtonClick"
        />
        <IobButton
          v-if="filterButton"
          :show-right-icon="filterDetailsComputed.showRightIcon"
          :show-left-icon="filterDetailsComputed.showLeftIcon"
          :right-icon="filterDetailsComputed.icon"
          :left-icon="filterDetailsComputed.icon"
          :label="filterDetailsComputed.label"
          :is-focused="filterDetailsComputed.isFocused"
          color="secondary"
          size="medium"
          type="ghost"
          class="IobHeaderAction-right-icon"
          @click="handleFilterButtonClick"
        />
        <div
          v-if="showViewButtons"
          class="IobHeaderAction-right-viewButtons"
        >
          <IobButton
            v-if="listViewButton"
            show-right-icon
            right-icon="List"
            color="secondary"
            size="medium"
            type="ghost"
            class="IobHeaderAction-right-icon"
            @click="handleListButtonClick"
          />
          <IobButton
            v-if="gridViewButton"
            show-right-icon
            right-icon="LayoutGrid"
            color="secondary"
            size="medium"
            type="ghost"
            class="IobHeaderAction-right-icon"
            @click="handleLayoutGridButtonClick"
          />
          <IobButton
            v-if="muralViewButton"
            show-right-icon
            right-icon="LayoutMural"
            color="secondary"
            size="medium"
            type="ghost"
            class="IobHeaderAction-right-icon"
            @click="handleLayoutMuralButtonClick"
          />
        </div>

        <div
          v-if="showSeparator"
          class="IobHeaderAction-right-separator"
        >
          <IobSeparator
            is-vertical
            :contrast="'low'"
          />
        </div>
        <div class="IobHeaderAction-right-searchAndSort full-width-mobile">
          <IobLabelField
            v-if="hasSearchInput"
            has-icon
            size="medium"
            :model-value="input"
            :placeholder="searchPlaceholder"
            class="IobHeaderAction-right-searchField full-width-mobile"
            @update:modelValue="handleSearch"
          />
          <IobButton
            v-if="moreBtn"
            class="HeaderNav-left-icons-moreBtn"
            size="medium"
            color="secondary"
            type="ghost"
            left-icon="MoreHorizontal"
            :show-left-icon="true"
            @click="handleMoreMenuButton"
          />
        </div>
      </div>
    </div>
    <div class="IobHeaderAction-separator">
      <IobSeparator :contrast="'low'" />
    </div>
  </div>
</template>

<style lang='scss' scoped src='./IobHeaderAction.scss' />
