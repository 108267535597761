<template>
  <div
    class="IobFilterModal"
    @click.self="closeModal"
  >
    <div class="IobFilterModal-top">
      <div class="IobFilterModal-top-header">
        <div class="IobFilterModal-top-header-container">
          <span class="IobFilterModal-top-header-container-span">
            Filters
          </span>
        </div>
        <iob-button
          size="medium"
          color="secondary"
          type="ghost"
          label="Clear all filters"
          left-icon="Trash"
          left-icon-size="small"
          :show-left-icon="true"
          :disabled="isFiltersEmpty"
          @click="cleanAllFilters"
        />
      </div>
      <iob-separator
        :is-vertical="false"
        state="default"
        color-type="onDefault"
        color="base"
      />
      <div class="IobFilterModal-top-title">
        <span class="IobFilterModal-top-title-span"> Quick filters </span>
      </div>
      
      <div class="IobFilterModal-top-buttonFilters">
        <iob-button
          size="medium"
          color="secondarySoft"
          type="filled"
          label="Assigned to me"
          left-icon="User"
          :show-left-icon="true"
          :is-focused="assignedToMeItem.isFocused"
          @click="handleQuickFilter(assignedToMeItem)"
        />
        <iob-button
          size="medium"
          color="secondarySoft"
          type="filled"
          label="Due this week"
          left-icon="CalendarClock"
          :show-left-icon="true"
          :is-focused="dueDateItem.isFocused"
          @click="handleQuickFilter(getCurrentWeekDates(dueDateItem))"
        />
      </div>
    </div>
    <iob-separator
      :is-vertical="false"
      state="default"
      color-type="onDefault"
      color="base"
    />
    <div class="IobFilterModal-otherFilters">
      <div class="IobFilterModal-otherFilters-title">
        <span class="IobFilterModal-otherFilters-title-span"> Other filters </span>
      </div>
      <iob-dropdown-button
        size="medium"
        color="primary"
        type="ghost"
        icon-name="Plus"
        :show-left-icon="true"
        :show-right-icon="false"
        title="Add Filter"
        :type-check="false"
        :items="itemsDropdown"
        :is-scrollable="true"
        dropdown-style="max-height: 500px; left: -51px; top: 42px"
        @dropdown-element-click="(item)=> handleAddFilter(item)"
      />
    </div>
    <div class="IobFilterModal-otherFilters-container">
      <div
        v-for="(filter, index) in mappedOtherFilters"
        :key="`${filter.name}-${index}`"
        class="IobFilterModal-otherFilters-container-filter"
      >
        <div class="IobFilterModal-otherFilters-container-filter-content">
          <icon-loader
            :name="filter.iconName"
            color="#47435F"
          />
          <span
            class="IobFilterModal-otherFilters-container-filter-content-span"
          >
            {{ filter.label }}
          </span>
        </div>
        <iob-toggle-switch 
          v-if="filter.componentType ==='checkbox'"
          :id="filter.id" 
          :checked="filter.isChecked"
          @change="(value)=> handleSwitchchange({name: filter.name, isChecked: value})"
        />
        <iob-multiple-select
          v-else
          :dropdown-items="filter.options"
          :selected-items="handleSelectItemByName(filter.name)"
          size="xs"
          color="secondary"
          :is-scrollable="true"
          :max-displayed-items="filter.maxDisplayedItems"
          dropdown-style="max-height: 250px; z-index:1"
          @on-click-item="handleOnClickItem"
          @on-delete-badge="handleDeleteItem"
        >
          <iob-timeframe
            v-if="filter.componentType === 'timeframe' || filter.componentType === 'dateRange'"
            style="width: 100%;"
            date-picker-styles="transform: translate(0);"
            :default-field="false"
            :toggle-timeframe="true"
            :types="timeframeTypes[filter.componentType]"
            :default-type="defaultTypes[filter.componentType]"
            :name="filter.name"
            @handle-date-change="
              (attributes) => 
                handleOnClickItem(formatSelectedAttributes(filter, attributes))
            "
          />
        </iob-multiple-select>
        <Iob-action-button
          size="default"
          color="secondary"
          type="ghost"
          icon-name="Minus"
          @click="handleRemoveFilter(filter)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, defineEmits, watch} from 'vue'; 
import IobButton from '../IobButton/IobButton.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobDropdownButton from '../IobDropdownButton/IobDropdownButton.vue';
import IobActionButton from '../IobActionButton/IobActionButton.vue';
import IobMultipleSelect from '../../Organisms/IobMultipleSelect/IobMultipleSelect.vue';
import IobTimeframe from '../IobTimeframe/IobTimeframe.vue';
import IobToggleSwitch from '../../Atoms/IobToggleSwitch/IobToggleSwitch.vue';
import IconLoader from '../../IconLoader/IconLoader.vue';

const props = defineProps({
  items: {
    required: true,
    type: [Array, String]
  },
  currentUser: {
    type: Object,
    default: () => {}
  }
});
const emit = defineEmits(['setFilter']);
const quickFilters = ref({});
const otherFilters = ref({});
const selectedItems = ref([]);
const dueDateItem= ref({isFocused: false, name: 'due-date', dates: '' })
const assignedToMeItem = ref({isFocused: false, name: 'owner',id: props.currentUser?.id  || ''});
const timeframeTypes = ref({dataRange: [], timeframe: [{ id: 0, value: 'standard' }, { id: 1, value: 'custom' }]});
const defaultTypes = ref({dataRange: '', timeframe:'standard' });
const orderedItems = ref([]);

const isFiltersEmpty = computed(() => Object.keys(combinedFilters.value).length === 0);
const combinedFilters = computed(() => {
  const allKeys= [...Object.keys(otherFilters.value), ...Object.keys(quickFilters.value)];
  const filters = allKeys.reduce((acc, key)=> 
  {
    let values = [];
    if (otherFilters.value[key] && quickFilters.value[key]) {
      values = [...otherFilters.value[key], ...quickFilters.value[key]]; 
    } else  {
      values = otherFilters.value[key] || quickFilters.value[key];
    } 
    if (!values) return {...acc};
    const formattedData =  (values && values.length) ? Object.values(values) : values;
    return {...acc, [key]: formattedData};
  }, {});
  return filters;
});

const itemsDropdown = computed(() => {
  if (props.items && props.items.length) {
    return props.items.filter((item)=> !Object.keys(otherFilters.value).some((key) => key === item.name))
      .map((item) => ({...item, 'text': item.label}));
  }
  return [];
});

const mappedOtherFilters = computed(() => {
  if(!Object.keys(otherFilters.value).length || !props.items.length) return [];
  const filteredData = props.items.reduce((acc, item) => {
    const itemExists = Object.keys(otherFilters.value).some((key)=> key === item.name);
    if (itemExists) {
      const value = otherFilters.value[item.name];
      acc[item.name] = {...item, value};
    }
    return acc;
  }, {});
  return orderItem(filteredData);
});

watch(combinedFilters, (newVal) => {
  emit('setFilter', newVal);
}, {deep: true});

const orderItem = (filteredData) => {
  return orderedItems.value.reduce((acc, key) => {
    if (filteredData[key]) {
      return {...acc, [key]: filteredData[key]};
    }
    return acc;
  }, {});
};

const handleSwitchchange =(item) => {
  otherFilters.value = { ...otherFilters.value, [item.name]: item.isChecked};
};
const formatSelectedAttributes = (filter, attributes) => {
  const { name } = filter;
  const { label, date } = attributes;
  return { name, text:label, date};
};
const filterSelectedValues = (name) => {
  const filteredValues = handleSelectItemByName(name);
  return filteredValues.map((value) => value.id || value.date || value.text);
};
const handleSelectItemByName = (name) => {
  return selectedItems.value.filter((item) => item.name === name);
};
const handleAddFilter = (item) => {
  const newItem = props.items.find((filter) => filter.name === item.name);
  if(!newItem) return;
  orderedItems.value[orderedItems.value.length]= item.name;
  otherFilters.value = {...otherFilters.value, [newItem.name]: ''};
};
const handleRemoveFilter = (item) => {
  delete otherFilters.value[item.name];
  orderedItems.value =  orderedItems.value.filter((name) => name !== item.name);
  selectedItems.value = selectedItems.value.filter((selectedItem) => selectedItem.name !== item.name);
};
const handleFocus = (name) => {
  if (name === 'owner'){
    assignedToMeItem.value.isFocused = !assignedToMeItem.value.isFocused;
  }
  else if (name === 'due-date') {
    dueDateItem.value.isFocused = !dueDateItem.value.isFocused;
  }
}
const handleQuickFilter = (item) => {
  if (!item.isFocused ) {
    quickFilters.value= {...quickFilters.value, [item.name]: (item.dates || [item.id])};
    handleFocus(item.name);
  }
  else {
    const mapQuickFilters = Object.keys(quickFilters.value).filter((el)=> item.name !== el);
    quickFilters.value = mapQuickFilters.reduce((acc, el) => {
      return {...acc, [el]: quickFilters.value[el]};
    }, {});
    handleFocus(item.name);
  }
};
const handleOnClickItem = (item) => {
  selectedItems.value = [...selectedItems.value, item]; 
  const filteredValues = filterSelectedValues(item.name);
  otherFilters.value = { ...otherFilters.value, [item.name]: filteredValues};
};

const handleDeleteItem = (item) => {
  selectedItems.value = selectedItems.value.filter((selectedItem) => selectedItem.text !== item.text);
  otherFilters.value = selectedItems.value.reduce((acc, el) => {
    const filteredValues = filterSelectedValues(el.name);
    return {...acc, [el.name]: filteredValues};
  }, {});
};
const getCurrentWeekDates = (item) => {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay(); 
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
  const dates = [];

  for (let i = 0; i < 7; i++) {
    const endDate = new Date(startOfWeek);
    endDate.setDate(startOfWeek.getDate() + i);
    const date = {
      'type': 'custom', 
      'end-date': endDate.toISOString().split('T')[0] 
    }
    dates.push(date);
  }
  return {...item, dates};
};

const cleanAllFilters = () => {
  otherFilters.value = {};
  quickFilters.value = {};
  selectedItems.value = [];
  orderedItems.value = [];
  assignedToMeItem.value.isFocused = false;
  dueDateItem.value.isFocused = false;
}
</script>

<style lang="scss" src="./IobFilterModal.scss" scoped />