<template>
  <div
    class="ListBoardgetModal-overlay"
    @click.self="closeModal"
  >
    <div
      ref="ListBoardgetModalRef"
      class="ListBoardgetModal"
    >
      <div class="ListBoardgetModal-content">
        <div class="ListBoardgetModal-header">
          <h1>Create a list</h1>
          <iob-action-button
            size="default"
            color="secondary"
            type="ghost"
            icon-name="X"
            @click="closeModal"
          />
        </div>
        <div class="ListBoardgetModal-inputs">
          <div class="ListBoardgetModal-inputs-element">
            <div class="ListBoardgetModal-inputs-element-field">
              <h3 class="ListBoardgetModal-inputs-element-field-label">
                List name
              </h3>
              <iob-label-field
                :model-value="boardgetListTitle"
                size="medium"
                type="ghost"
                badge-color="secondary"
                placeholder="Add name.."
                @update:modelValue="(value) => boardgetListTitle = value"
              />
            </div>
            <div class="ListBoardgetModal-inputs-element-field">
              <h3 class="ListBoardgetModal-inputs-element-field-label">
                Element Type
              </h3>
              <iob-input-dropdown
                :type-check="true"
                :status="getDataTypes[0]"
                size="xs"
                :items="getDataTypes"
                @dropdown-element-click="onDataTypeSelect($event)"
              />
            </div>
          </div>
        </div>
        <div class="ListBoardgetModal-inputs-element-field">
          <h3 class="ListBoardgetModal-inputs-element-field-label">
            Related to
          </h3>
          <iob-dropdown-search-select
            :default-field="false"
            :dropdown-menu-items="datasetElementsWithTitle"
            :dropdown-style="datasetElementsDropdownStyle"
            :sliced-data="true"
            :placeholder="placeholder"
            :search-input="relatedSearchQuery"
            @onClickDropdownSearchSelectMenu="
              ({ item }) => getDatasetElement(item)
            "
            @update:modelValue="searchDatasetElements"
          >
            <related-elements-field
              :tags="relatedElements"
              @search="(searchValue) => searchDatasetElements(searchValue)"
              @on-tags-change="(id) => handleRemoveRelatedTag(id)"
            />
          </iob-dropdown-search-select>
        </div>
        <div
          ref="ListBoardgetModalFiltersRef"
          class="ListBoardgetModal-inputs-element-field-filters"
        >
          <div class="ListBoardgetModal-inputs-element-field-header">
            <h3 class="ListBoardgetModal-inputs-element-field-label">
              Filters
            </h3>
            <outside-click-listener
              @outside-click="toggleFilterDropdown = false"
            >
              <iob-button
                size="medium"
                color="primary"
                type="ghost"
                left-icon="Plus"
                :show-left-icon="true"
                label="Add filter"
                :selected="toggleFilterDropdown"
                class="ListBoardgetModal-button"
                :disabled="filtersToAdd.length === 0"
                @click="handleAddFilter"
              />
              <iob-dropdown
                v-if="toggleFilterDropdown && filtersToAdd.length > 0"
                :items="filtersToAdd"
                class="ListBoardgetModal-button-dropdown"
                @dropdown-element-item="handleDropdownItemClick"
              />
            </outside-click-listener>
          </div>
          <div
            v-for="(filter, index) in formData.fields"
            :key="`${index}-${filter.name}-${dataTypeName}`"
            class="ListBoardgetModal-inputs-element-field-filters-filter"
          >
            <div class="ListBoardgetModal-iconWithLabel">
              <icon-loader
                :name="filter.iconName || 'User'"
                color="#333044"
                size="small"
              />
              <h3>{{ filter.label }}</h3>
            </div>
            <tags-field
              v-if="
                filter.componentType !== 'timeframe' &&
                  filter.componentType !== 'dateRange' && filter.componentType !== 'checkbox'
              "
              :component-type="filter.componentType"
              :field-name="filter.label"
              :model-value="filter.value"
              :items="filter.options"
              @update:modelValue="(value) => filter.value = value"
            />
            <div
              v-else-if="
                filter.componentType === 'checkbox'"
              style="width: 100%;"
            >
              <div class="ListBoardgetModal-toggle-switch">
                <input
                  id="ListBoardgetModal-switch"
                  type="checkbox"
                  :checked="modelValue"
                  @change="getToggleSwitchValue($event, filter.name)"
                >
                <label
                  for="ListBoardgetModal-switch"
                  class="ListBoardgetModal-switch-label"
                />
              </div>
            </div>
            <iob-timeframe
              v-else-if="filter.componentType === 'timeframe'"
              style="width: 100%;"
              date-picker-styles="transform: translate(0);"
              :default-field="false"
              :types="timeframeTypes"
              default-type="standard"
              :name="name"
              @handle-date-change="
                (attributes) => getDateTag(filter.name, attributes, formData)
              "
            >
              <tags-field
                :component-type="filter.componentType"
                :model-value="filter.value.map(item => ({ text: item.text, date: {...item.date} }))"
                @update:model-value="(attributes) => removeDateTag(filter.name, attributes, formData)"
              />
            </iob-timeframe>
            <iob-timeframe
              v-if="filter.componentType === 'dateRange'"
              style="width: 100%;"
              date-picker-styles="transform: translate(0);"
              :default-field="false"
              :name="filter.text"
              @handle-date-change="
                (attributes) => getDateTag(filter.name, attributes, formData)
              "
            >
              <tags-field
                :component-type="filter.componentType"
                :model-value="filter.value.map(item => ({ text: item.text, date: {...item.date} }))"
                @update:model-value="(attributes) => removeDateTag(filter.name, attributes, formData)"
              />
            </iob-timeframe>
          </div>
        </div>
        <div class="ListBoardgetModal-createButton">
          <iob-button
            size="default"
            color="primary"
            type="filled"
            label="Create"
            @click="createList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  watch,
  reactive,
  watchEffect,
  toRaw
} from 'vue';
import { useStore } from 'vuex';
import TagsField from './TagsField/TagsField.vue';
import {
  addTitleToDropdownData,
  debounce,
  formatDatasetElements,
  transformFieldValue,
  isFieldValueEmpty,
  addOtherFilter,
  removeDateTag,
  getDateTag
} from '../modals-utils';
import RelatedElementsField from './RelatedElementsField/RelatedElementsField.vue';
import { getUserInitials } from '../../utils/collab-utils';

defineProps({});
const toggleFilterDropdown = ref(false);
const timeframeTypes = [{ id: 0, value: 'standard' }, { id: 1, value: 'custom' }];
const boardgetListTitle = ref('');
const relatedSearchQuery = ref('');
const ListBoardgetModalRef = ref(null);
const ListBoardgetModalFiltersRef = ref(null);
const filteredDatasetElements = ref([]);
const formData = reactive({
  name: '',
  dataType: '',
  fields: []
});
const relatedElements = ref([]);
const store = useStore();

const datasetElementsDropdownStyle = ref(
  'position: absolute; z-index: 1; width: 100%; max-width: 550px'
);

/** Computed */
const datasetElementsWithTitle = computed(() =>
  addTitleToDropdownData(filteredDatasetElements.value, dataTypeName.value)
);
const allUsers = computed(() => store.state.users.users);
const boardId = computed(() => store.state.board.id);
const viewTypeId = computed(() => store.state.dock.viewTypeId);
const dataTypeId = computed(() => store.state.dock.dataTypeId);
const datasetElements = computed(() => store.state.dock.datasetElements);
const levels = computed(() => store.state.app.levels);
const formattedDatasetElements = computed(() =>
  formatDatasetElements(datasetElements.value)
);
const dataTypeName = computed(() => dataTypes.value[selectedDatatypeId.value].name.toLowerCase()
);
const allDataTypes = computed(() => store.state.app.dataTypes);

const dataTypes = computed(() => {
  const filteredDataTypesArr = Object.values(allDataTypes.value).filter((dataType) => dataType.namespace === '/element-data-types/opex');
  return filteredDataTypesArr.reduce((acc, dataType) => {
    acc[dataType.id] = dataType;
    return acc;
  }, {});
});
const creationComponent = computed(() => store.state.dock.creationComponent);
const selectedDatatypeId = ref(Object.keys(dataTypes.value)[0]);
const closeComponent = (value) => store.dispatch('board/closeComponent', value);

const getDataTypes = computed(() =>
  Object.keys(dataTypes.value).map(
    (dataTypeId) => dataTypes.value[dataTypeId].name
  )
);
const otherFilters = ref(dataTypes.value[selectedDatatypeId.value].editorConfig?.filterConfig.other.attributes.slice() || []);

const filtersToAdd = computed(() => getFilterData(otherFilters.value).map((filter) => ({
  ...filter,
  text: filter.label,
  label: filter.label,
  name: filter.name,
  type: 'menu',
  state: 'default',
  iconSize: 'default'
})));

const filtersList = computed(() => {
  const attributes =
    dataTypes.value[selectedDatatypeId.value].editorConfig.filterConfig.default
      .attributes.slice();
  return getFilterData(attributes).map((filter) => ({
    ...filter,
    type: 'menu',
    state: 'default',
    iconSize: 'default'
  }));
});

const handleDropdownItemClick = ({ item }) => {
  addOtherFilter(item, formData.fields, otherFilters.value);
  toggleFilterDropdown.value = false;
};

const handleAddFilter = () => {
  toggleFilterDropdown.value = !toggleFilterDropdown.value;
};

const getToggleSwitchValue = (value, fieldName) => {
  const index = formData.fields.findIndex((field) => field.name === fieldName);
  formData.fields[index].value = [value.target.checked];
};

const getFilterData = (attributes, reset = false) => {
  const mainAttributesMap = dataTypes.value[
    selectedDatatypeId.value
  ].editorConfig.general.mainAttributesPanel.reduce((acc, attribute) => {
    acc[attribute.name] = attribute;
    return acc;
  }, {});
  const attrOptionsMap = dataTypes.value[
    selectedDatatypeId.value
  ].attributes.reduce((acc, attribute) => {
    acc[attribute.name] = attribute;
    return acc;
  }, {});
  for (const attr in attrOptionsMap) {
    if (attrOptionsMap[attr].type === 'user') {
      attrOptionsMap[attr].options =
      allUsers.value.map((user) => ({
        title: `${user.firstname} ${user.lastname}`,
        id: user.id,
        showIcon: false,
        avatar: {
          picture: user.picture,
          size: 'default',
          color: '#8AC3C5',
          altText: getUserInitials(user.firstname, user.lastname)
        },
        type: 'member'
      })) || [];
    } else if (attrOptionsMap[attr].type === 'level') {
      attrOptionsMap[attr].options = levels.value.map((level) => ({
        text: level.attributes['level-name'],
        id: level.id,
        iconName: '',
        type: 'badge',
        state: 'default',
        iconSize: 'default'
      }));

    } else {
      attrOptionsMap[attr].options =
      attrOptionsMap[attr].enum?.map((item) => ({
        text: item.value,
        iconName: '',
        type: 'menu',
        state: 'default',
        iconSize: 'default'
      })) || [];
    }
  }
  return attributes
    .filter((attr) => attr in mainAttributesMap || attr === 'type')
    .map((attr, index) => ({
      name: attr,
      label: attrOptionsMap[attr].friendlyName,
      componentType: mainAttributesMap[attr]?.componentType || attrOptionsMap[attr]?.componentType || 'inputDropdown',
      options: attrOptionsMap[attr]?.options || [],
      value: reset ? [] : formData.fields[index]?.value
    }));
};

const fetchDatasetElements = async () => {
  await store.dispatch('dock/fetchDatasetElements', { dataTypeId: null });
  filteredDatasetElements.value = formatDatasetElements(datasetElements.value);
};

const searchDatasetElements = debounce(async (value) => {
  relatedSearchQuery.value = value;
  await store.dispatch('dock/searchDatasetElements', {query: relatedSearchQuery.value, withTypeId: !value.length });
}, 500);

watch(
  selectedDatatypeId,
  async (newId) => {
    if (newId) {
      store.commit('dock/setDataTypeId', newId);
      await fetchDatasetElements();
      relatedElements.value = [];
      formData.fields = getFilterData(filtersList.value.map((filter) => filter.name), true);
      otherFilters.value = dataTypes.value[selectedDatatypeId.value].editorConfig?.filterConfig.other.attributes.slice() || [];
    }
  },
  { immediate: true, deep: true }
);

const setNewlyFilteredDatasetElements = () => {
  filteredDatasetElements.value = formattedDatasetElements.value.filter(
    (element) => !relatedElements.value.some(
      (relatedElement) => relatedElement.id === element.id
    )
  );
};

watchEffect(() => {
  filteredDatasetElements.value = formattedDatasetElements.value;
  setNewlyFilteredDatasetElements();
});

const getDataTypeIdByName = (name) =>
  Object.keys(dataTypes.value).find(
    (dataTypeId) => dataTypes.value[dataTypeId].name === name
  );

const onDataTypeSelect = (dataType) => {
  selectedDatatypeId.value = getDataTypeIdByName(dataType.text);
};

const closeModal = () => {
  closeComponent(creationComponent.value);
};

const getDatasetElement = (item) => {
  filteredDatasetElements.value = filteredDatasetElements.value.filter(
    (element) => element.id !== item.id
  );
  relatedElements.value.push({ id: item.id, text: item.title });
  relatedSearchQuery.value = '';
  searchDatasetElements('');
};

const handleRemoveRelatedTag = (id) => {
  relatedElements.value = relatedElements.value.filter(
    (element) => element.id !== id
  );
  setNewlyFilteredDatasetElements();
};

const createList = async () => {
  const filtersWithTheirValues = formData.fields.map((field) => {
    const fieldValue = transformFieldValue(field);
    if (isFieldValueEmpty(fieldValue)) {
      return null;
    }
    return { [field.name]: fieldValue };
  }).filter((item) => item !== null);

  const transformedAttributes = filtersWithTheirValues.reduce((acc, item) => {
    acc = { ...acc, ...item };
    return acc;
  }, {});
  const attributeSearches = {
    attributeSearches: transformedAttributes,
    relatedElementIds: toRaw(relatedElements.value).map(
      (element) => element.id),
    dataTypeId: selectedDatatypeId.value
  };

  await store.dispatch('board/createBoardgetElement', {
    attributeSearches,
    boardId: boardId.value,
    viewTypeId: viewTypeId.value,
    dataTypeId: dataTypeId.value,
    dataTypeName: 'List',
    title: boardgetListTitle.value
  });
  store.dispatch('board/closeComponent', 'ListBoardgetModal');
};

onMounted(() => {
  store.commit('dock/setDataTypeId', selectedDatatypeId.value);
});

</script>

<style src="./ListBoardgetModal.css" />
