
<template>
  <div class="IobDropdownButton">
    <iob-button
      :size="size"
      :type="buttonType"
      :color="color"
      :selected="toggleDropdown"
      :label="typeCheck ? currentItem : title"
      :left-icon="iconName? iconNameItem : ''"
      :show-left-icon="true"
      :show-right-icon="showRightIcon"
      :disabled="disabled"
      :button-style="buttonStyle"
      :right-icon="toggleDropdown && items.length > 1 ? 'ChevronUp' : 'ChevronDown'"
      :right-icon-size="rightIconSize"
      class="IobDropdownButton-button"
      @click="toggleDropdownClick"
    />
    <outside-click-listener @outside-click="handleClickOutsideDropdown">
      <iob-dropdown
        v-if="toggleDropdown && items.length "
        :items="itemsDropdown"
        :is-scrollable="isScrollable"
        :dropdown-style="dropdownStyle"
        class="IobDropdownButton-button-dropdown"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
    <div />
  </div>
</template>     
      
<script setup>
import { ref, defineProps, defineEmits, computed} from 'vue'; 
import IobButton from '../../Molecules/IobButton/IobButton.vue'; 
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue'; 
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'default'
  },
  rightIconSize: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: ''
  },
  items: {
    required: true,
    type: [Array, String]
  },
  typeCheck: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  buttonType: {
    type: String,
    default: 'filled'
  },
  color: {
    type: String,
    default: 'secondarySoft'
  },
  showRightIcon: {
    type: Boolean,
    default: true
  },
  buttonStyle:{
    type: String,
    default: ''
  },
  isScrollable:{
    type: Boolean,
    default: false
  },
  dropdownStyle: {
    type: String,
    default: ''
  }
});

const currentItem = ref(props.title);
const iconNameItem= ref(props.iconName);

const getIconName = (item) => {
  if(item.iconName){
    return item.iconName;
  }
  else {
    return item === currentItem.value && props.typeCheck ? 'Check' : '';
  }
};
const isSelected = (item) => {
  return item.iconName === iconNameItem.value && props.typeCheck ? true : false;
};
const itemsDropdown = computed(() => {
  return props.items.map(item => {
    return {
      text: `${item.text ? item.text : item}`,
      state: item.state || 'default',
      iconName: getIconName(item),
      iconSize: 'default',
      type: item.type ||'menu',
      size:'xs',
      subItemslist: item.subItemslist ? item.subItemslist : [],
      ...item,
      selected: isSelected(item)
    }
  })
});

const toggleDropdown = ref(false);
const emits = defineEmits(['dropdownElementClick']);

const toggleDropdownClick = (e)=> {
  e.stopPropagation();
  toggleDropdown.value = !toggleDropdown.value;
};

const handleDropdownItemClick = ({item}) => {
  if(item.subItemslist?.length && !item.action){
    return;
  }
  emits('dropdownElementClick', item);
  currentItem.value = item.text;
  toggleDropdown.value = false;
  iconNameItem.value = item.iconName;
};

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};
</script>
      
      <style lang="scss" src="./IobDropdownButton.scss" scoped />
