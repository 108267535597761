<template>
  <div
    class="pbi-viewer-wrapper level-2"
    data-target="board-element"
  >
    <div
      v-if="!accessToken"
      class="pbi-connect-container"
    >
      <div class="pbi-connect-wrapper">
        <img
          class="not-connected-image"
          :src="base64Pic"
          alt="PowerBI bretzel electric cord"
        >
        <div class="pbi-connect-bottom">
          <span class="not-logged-in">
            {{ $t('embed.powerbi.message.auth') }}
          </span>
          <PowerBIOAuthButton />
        </div>
      </div>
    </div>
    <div
      v-else
      class="pbi-viewer"
    >
      <PowerBIDashboardEmbed
        v-if="embedConfig?.type == 'dashboard'"
        class="pbi-viewer"
        :embed-config="embedConfig"
      />

      <PowerBIReportEmbed
        v-if="embedConfig?.type == 'report'"
        class="pbi-viewer"
        :embed-config="embedConfig"
      />

      <PowerBIVisualEmbed
        v-if="embedConfig?.type == 'visual'"
        class="pbi-viewer"
        :embed-config="embedConfig"
      />
      <div
        v-if="embedConfig == null || error"
        class="error-container"
      >
        <div class="error-container">
          <div class="error-message-wrapper">
            <IconLoader
              name="AlertCircle"
              color="#A43434"
            />
            <span class="error-message">{{ errorMsg }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PowerBIDashboardEmbed, PowerBIReportEmbed, PowerBIVisualEmbed } from 'powerbi-client-vue-js';
import { mapState } from 'vuex';
import embedConfig from './utils/embed-config';
import parse from './utils/parser';
import PowerBIOAuthButton from './PowerBIOAuthButton.vue';
import store from 'SRC/store';
import pbiBretzelCord from 'SRC/assets/images/PBI_Bretzel_Cord.png';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PowerBI',
  components: {
    PowerBIDashboardEmbed,
    PowerBIReportEmbed,
    PowerBIVisualEmbed,
    PowerBIOAuthButton
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data: () => ({
    t: useI18n().t,
    errorMsg: '',
    error: false,
    base64Pic: pbiBretzelCord
  }),
  computed: {
    ...mapState('powerbi', ['accessToken', 'settings']),
    embedConfig() {
      if (!this.accessToken) {
        return {};
      }
      return this.generateEmbedConfig();
    }
  },
  watch: {
    settings() {
      const infos = { settingsId: this.settings.id };
      if (!this.accessToken) {
        store.dispatch('powerbi/retrieveAccessToken', { code: null, infos });
      }
    },
    url: {
      handler() {
        this.generateEmbedConfig();
      },
      immediate: true
    }
  },
  created() {
    this.errorMsg = this.t('embed.powerbi.message.error');
    if (!this.settings) {
      store.dispatch('powerbi/retrieveSettings');
    }
  },
  methods: {
    generateEmbedConfig() {
      try {
        const config = embedConfig(parse(this.url), this.accessToken);
        this.error = false;
        return config;
      } catch (error) {
        this.error = true;
        return {};
      }
    }
  }
};
</script>

<style scoped src='./PowerBI.css' />
