<!-- eslint-disable max-len -->
<template>
  <outside-click-listener @outside-click="closeEditor">
    <div class="NoteEditor">
      <div class="NoteEditor-header">
        <h1 class="NoteEditor-header-title">
          {{ $t('note.edit.title') }}
        </h1>
        <iob-action-button
          icon="close"
          size="default"
          color="secondary"
          type="ghost"
          @click="closeEditor"
        />
      </div>
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-name">{{ $t('note.edit.label') }}</label>
        </div>
        <IobTextarea
          styles="max-height: 200px; border: 1px solid var(--text-area-color-border-default, #918EA6); border-radius: var(--text-area-radius-medium, 4px); padding: 0px var(--text-area-padding-horizontal-medium, 8px);"
          size="default"
          :placeholder="$t('note.edit.placeholder')"
          :value="title"
          :is-error="!titleIsValid"
          :min-rows="3"
          :max-caracters="2000"
          @update:modelValue="(value) => handleChange('title', value.trim())"
        />
      </div>
    </div>
  </outside-click-listener>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const titleIsValid = ref(true);
const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);
const title = computed(() => datasetElement.value ? datasetElement.value.attributes.title : '');
onMounted(() => {
  handleChange('title', title.value?.trim());
});
const emit = defineEmits(['close']);
const closeEditor = () => {
  emit('close');
};
const handleChange = (name, title) => {
  store.dispatch('board/updateDatasetElementAttributes', {
    datasetElementId: datasetElementId.value,
    attributes: {
      ...datasetElement.value.attributes,
      [name]: title
    }
  });
};
</script>

<style lang="scss" scoped src="./NoteEditor.scss" />
