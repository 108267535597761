import { get, put } from 'SRC/api/index.js';
import { FORMULA_API_URL } from 'SRC/config/api';
import { PAGE_SIZE, HIERARCHY } from 'GLOBALS/constants.js';
import utils from './utils';
import apiUtils from 'SRC/store/utils';
import { LINKS_TYPES, DATA_TYPES_NAMES_SPACE, DATA_TYPES_NAMES } from 'GLOBALS/constants';

const actions = {
  updateAttributeInParent({ rootState, commit }, elementId, attributes) {
    const parent = Object.values(rootState.board.datasetElements).find(
      (dsValue) =>
        dsValue.children &&
        dsValue.children.length > 0 &&
        dsValue.children.find((child) => child.id === elementId)
    );
    if (!parent) {
      return;
    }
    const datasetElements = {
      ...rootState.board.datasetElements,
      [parent.id]: {
        ...parent,
        children: parent.children.map((element) => {
          if ((element.id !== elementId)) {
            return element;
          }
          return {
            ...element,
            attributes: { ...element.attributes, ...attributes }
          };
        })
      }
    };
    commit('board/setDatasetElements', datasetElements, { root: true });
  },
  async addFormulasResultsToElements({ state, rootState, commit }) {
    if (!(state.formulas && Object.values(state.formulas).length > 0)) {
      return;
    }
    const datasetElementsValues = Object.values(rootState.board.datasetElements);
    const computedAttributesByType = Object.values(state.dataTypes).reduce(
      (acc, { attributes, id }) => ({
        ...acc,
        [id]: attributes.filter((attr) => attr.computedByFormula)
      }),
      {}
    );
    const dataTypes = state.dataTypes;
    const updatedDatasetElementsPromises = datasetElementsValues.map(
      async (dsValue) => {
        if (!dsValue.children && !dsValue.dependencies) {
          return {
            [dsValue.id]: dsValue
          };
        }
        const dataType = Object.values(state.dataTypes).find(
          (el) => el.id === dsValue.typeId
        );
        const attributes = { ...dsValue.attributes };
        const computedAttributes = computedAttributesByType[dataType.id];
        for (const attr of computedAttributes) {
          const formula = state.formulas[attr.computedByFormula];
          let formulaResult = await utils.evaluateFormula(
            dsValue,
            dataType,
            formula.expression,
            dataTypes
          );
          if (typeof formulaResult === 'number' && formulaResult.toString() === 'NaN') {
            formulaResult = 0;
          }
          attributes[attr.name] = formulaResult;
          const isEditorOpen = rootState.editor.isEditorOpen;
          const isSelectedDatasetElement = rootState.board.datasetElements[rootState.editor.datasetElementId] && rootState.editor.datasetElementId;
          if (isEditorOpen && isSelectedDatasetElement) {
            const attributes = rootState.board.datasetElements[rootState.editor.datasetElementId].attributes || {};
            const newAttributes = {...attributes, [attr.name]: formulaResult};
            commit('board/updateDatasetElement', {datasetElementId: rootState.editor.datasetElementId, updates: newAttributes}, {root: true});
          }
        }
        return {
          [dsValue.id]: { ...dsValue, attributes }
        };
      }
    );
    const updatedDatasetElementsArray = await Promise.all(
      updatedDatasetElementsPromises
    );
    const updatedDatasetElements = Object.assign(
      {},
      ...updatedDatasetElementsArray
    );
    commit('board/setDatasetElements', updatedDatasetElements, { root: true });
  },
  async fetchDatasetElements(_, dataTypeIds = []) {
    let response;
    if (dataTypeIds.length > 0) {
      const query = dataTypeIds.map((id) => `dataTypeIds=${id}`).join('&');
      response = await get(`/dataset-elements?${query}&page=1&size=${PAGE_SIZE}`);
    }
    if (!response) {
      return { error: 'Error occurred in fetchDatasetElements' };
    }
    const elements = response.data.slice();
    return elements;
  },
  async fetchViewTypes(context) {
    const elementViewTypes = await get(
      `/element-view-types?page=1&size=${PAGE_SIZE}`
    );
    if (elementViewTypes && elementViewTypes.data) {
      let elementViewTypesData = elementViewTypes.data;
      elementViewTypesData = await apiUtils.getNextPage(
        elementViewTypes,
        elementViewTypesData,
        get
      );
      const viewTypes = {};
      elementViewTypesData.forEach(
        ({ id, layouts, toolType, dataTypeId, dataTypeName, iconUri, hidden, config }) => {
          viewTypes[id] = {
            dataTypeId,
            dataTypeName,
            layouts,
            toolType,
            iconUri,
            hidden,
            ...(config?.contextualMenu && { contextualMenuItems: config.contextualMenu }),
            ...(config?.sizeLayoutMap && { sizeMapLayout: config.sizeLayoutMap }),
            ...(config?.resize ? { resize: config.resize } : {})
          };
        }
      );
      context.commit('setViewTypes', viewTypes);
      return elementViewTypes;
    }
    return { error: 'Error occured in fetchViewTypes'};
  },
  async fetchDataTypes(context) {
    const elementDataTypes = await get(
      `/element-data-types?page=1&size=${PAGE_SIZE}`
    );
    if (elementDataTypes && elementDataTypes.data) {
      let elementDataTypesData = elementDataTypes.data;
      elementDataTypesData = await apiUtils.getNextPage(
        elementDataTypes,
        elementDataTypesData,
        get
      );
      const newCardIndex = utils.generateNewCardIndex(elementDataTypesData);
      const dataTypes = elementDataTypesData.reduce((acc, dataType) => {
        acc[dataType.id] = dataType;
        return acc;
      }, {});
      const systemDataTypes = {};
      elementDataTypesData.forEach((dataType) => {
        if (dataType.namespace === DATA_TYPES_NAMES_SPACE.SYSTEM) {
          systemDataTypes[dataType.id] = dataType;
        }
      });
      context.commit('setDataTypes', dataTypes);
      context.commit('setSystemDataTypes', systemDataTypes);
      await context.dispatch('fetchLevels');
      await context.dispatch('fetchLevelsTree');
      if (newCardIndex > 0) {
        context.commit('toolsConfig/setCardName', `NewCard(${newCardIndex})`, {root: true});
      }
      return elementDataTypes;
    }
    return { error: 'Error occured in fetchDataTypes'};
  },
  async fetchLayouts(context) {
    const layouts = await get(
      `/element-view-types/layouts?page=1&size=${PAGE_SIZE}`
    );
    if (layouts && layouts.data) {
      let layoutsData = layouts.data;
      layoutsData = await apiUtils.getNextPage(
        layouts,
        layoutsData,
        get,
        true
      );
      layoutsData = layoutsData.map((e) => ({id: e.id, ...e.layouts.find((el) => el.levelOfDetail === 0)}));
      if (layoutsData && layoutsData.length > 0) {
        context.commit('setLayouts', layoutsData);
        context.commit('toolsConfig/setSelectedLayout', layoutsData[0].id, {root: true});
      }
    }
  },
  async fetchFormulas({ state, commit }) {
    const dataTypesValues = Object.values(state.dataTypes);
    if (dataTypesValues && dataTypesValues.length > 0) {
      let formulasIds = [];
      const FormulasDatatypes = {};
      dataTypesValues.forEach((dataType) => {
        dataType.attributes
          .filter((attribute) => attribute.computedByFormula)
          .map((el) => ({ name: el.name, id: el.computedByFormula }))
          .forEach(async ({ id }) => {
            formulasIds = [...formulasIds, id];
            FormulasDatatypes[id] = dataType.name;
          });
      });

      const maxSize = 100;
      let formulas = {};
      const formulasIdsArrayOfArrays = [];
      for (let i = 0; i < formulasIds.length; i += maxSize) {
        formulasIdsArrayOfArrays.push(formulasIds.slice(i, i + maxSize));
      }
      const queries = formulasIdsArrayOfArrays.map((arrayOfIds) => (
        utils.constructFormulasQuery(arrayOfIds)
      ));
      const results = await get(`${FORMULA_API_URL}/formulas?${queries}`, null, true, false,
        {isUsersAPI: false, isActivated: false, interrupts: false });
      const newFormulas = utils.setFormulas(results.data, FormulasDatatypes);
      formulas = {...formulas, ...newFormulas} ;
      commit('setFormulas', formulas);
    }
  },
  async updateViewTypeHiddenFlag({ state, commit }, { viewTypeId, hidden }) {
    const hiddenFlag = await put(`/element-view-types/${viewTypeId}/hidden`, hidden);
    const viewTypes = state.viewTypes;
    Object.keys(viewTypes).forEach(
      (id) => {
        if (id === viewTypeId) {
          viewTypes[viewTypeId].hidden = hiddenFlag;
        }
      }
    );
    commit('setViewTypes', viewTypes);
  },
  async fetchRelationTypes({ commit }) {
    const response = await get('/relation-types');
    if (response && response.data && response.data.length) {
      const relationTypes = response.data;
      relationTypes.forEach((relationType) => {
        relationType.label = relationType.name;
        relationType.type = LINKS_TYPES[relationType.kind];
      });
      commit('setRelationTypes', relationTypes);
    }
  },
  async fetchLevels({ state, commit, dispatch }) {
    const levelDataType = Object.values(state.systemDatatypes).find((element) => element.name === DATA_TYPES_NAMES.LEVEL);
    if (levelDataType) {
      const levelDataTypesId = levelDataType.id;
      const levels = await dispatch('fetchDatasetElements', [levelDataTypesId]);
      commit('setLevels', levels);
    }
  },
  async buildHierarchyRelationsCommon({ state, dispatch }, {levelsIds, callback}) {
    if (!levelsIds.length) {
      return;
    }
    const data = await dispatch('hierarchy/fetchRelations', levelsIds, { root: true });
    const elementsById = utils.buildElementsById(data);
    const rootElement = Object.values(state.levels).find((element) => !elementsById[element.id]);
    const rootElementClone = utils.createRootElementClone(rootElement, elementsById);

    utils.buildChildren(data.datasetElementsWithChildren, elementsById)
      .reduce((elementsByIdAcc, relation) => {
        elementsByIdAcc[relation.datasetElementId] = elementsByIdAcc[relation.datasetElementId] || { id: relation.datasetElementId, children: [] };
        elementsByIdAcc[relation.datasetElementId].children.push(...relation.children.filter(Boolean));
        return elementsByIdAcc;
      }, elementsById);

    return callback(elementsById, rootElementClone);
  },
  async fetchLevelsTree({ state, dispatch, commit }) {
    const levelsIds = Object.values(state.levels).map((level) => level.id);
    const callback = (elementsById, rootElementClone) => [elementsById[rootElementClone.id]];
    const levelsTree = await dispatch('buildHierarchyRelationsCommon', {levelsIds, callback});
    if (!levelsTree) {
      return;
    }

    levelsTree.forEach((level) => {
      utils.initializeHierarchyColors(level, HIERARCHY.LEVELS_COLORS);
    });
    commit('setLevelsTree', levelsTree);
  }
};

export default actions;
