<template>
  <div class="BoardSettings">
    <div class="BoardSettings-header">
      <h1 class="BoardSettings-header-title">
        Indicator Settings
      </h1>
      <iob-action-button
        icon="close"
        size="default"
        color="secondary"
        type="ghost"
        @click="closeSettings"
      />
    </div>
    <div class="form-group">
      <div class="form-group-label">
        <label for="board-name">Name</label>
      </div>
      <iob-label-field
        :model-value="name"
        placeholder="Type a name"
        has-clear-button
        @update:modelValue="(value) => handleChange('name', value.trim())"
      />
    </div>
    <div
      v-if="dependencies.length === 0"
      class="form-group"
    >
      <div class="form-group-label">
        <label for="board-name">Data</label>
      </div>
      <img
        src="../../assets/RectangleKPI.png"
        alt="KPI"
      >
      <div class="form-group-helper-text">
        Create or use and existing indicator to visualize its data and customize its representation on the board
      </div>
      <iob-dropdown-search-select
        :dropdown-menu-items="datasetElementsWithTitle"
        :dropdown-style="datasetElementsDropdownStyle"
        :input-style="'margin: 16px 16px 0 16px;'"
        :sliced-data="true"
        placeholder="Search or create a KPI"
        @outside-click="() => {}"
        @onClickDropdownSearchSelectMenu="({ item }) => createExistingElementsDependencies(item)"
        @update:modelValue="searchDatasetElements"
      />
    </div>
    <div v-else>
      <iobeya-fieldset
        id="fieldset-options"
        class="mt-4"
        :items="dependencies"
        has-checkbox="true"
        border-color="border-gray"
        mode="gray"
        shape="rounded"
        outlined
        @checked-item="manageDependencyCheck"
        @end-icon-click="executeEndIconAction"
      />
      <iob-separator />
      <div class="form-group">
        <div class="form-group-label">
          <label for="board-name">Visualization</label>
        </div>
        <div>
          <chart-type-selector />
        </div>
      </div>
      <horizontal-field-label type="select" />
      <horizontal-field-label type="select" />
      <horizontal-field-label type="toggle" />
      <horizontal-field-label type="toggle" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { addTitleToDropdownData, formatDatasetElements, debounce } from '../../modals/CardCreationModal/modals-utils';

const store = useStore();
const KPIDataType = computed(() => Object.entries(store.state.app.dataTypes).find(([, dataType]) => dataType.name === 'KPI'));
const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);
const name = computed(() => datasetElement.value ? datasetElement.value.attributes.name : '');
onMounted(async () => {
  await store.dispatch('relations/fetchDatasetElementDependencies', datasetElementId.value);
  await store.dispatch('relations/searchIndicatorDependencies', { query: '', dataTypeId: KPIDataType.value[0]});
});
const emit = defineEmits(['close']);
const closeSettings = () => {
  emit('close');
};
const datasetElementsDropdownStyle = ref('margin: auto; width: 95%');
const datasetElements = computed(() => store.state.relations.searchResults);
const formattedDatasetElements = computed(() => formatDatasetElements(datasetElements.value));
const dependencies = computed(() => {
  const dependencies = store.state.relations.createdDependencies;
  const data = dependencies.map(({relatedDatasetElement, id, kind, checked}) => {
    let dependency = {};
    const dependencyAttrs = {
      endIcon: 'X',
      endIconColor: 'lightgray',
      style: 'width: 100%',
      disabled: true,
      checkboxDisabled: false,
      startIcon: 'Square',
      kind,
      id,
      checked
    };
    dependency = {...dependency, ...dependencyAttrs};
    dependency.value = getRelatedDatasetElementValue(relatedDatasetElement);
    return dependency;
  });
  return data;
});

const datasetElementsWithTitle = computed(() =>
  addTitleToDropdownData(formattedDatasetElements.value, 'KPI')
);
const searchDatasetElements = debounce(async (value) => {
  await store.dispatch('relations/searchIndicatorDependencies', { query: value, dataTypeId: KPIDataType.value[0]});
}, 500);

const relatesToRelationType = computed(() => store.state.app.relationTypes.find((relationType) => relationType.name === 'Relates to'));
async function createExistingElementsDependencies(element) {
  await store.dispatch('relations/createDependenciesWithExistingElements', {
    elements: [element],
    relationTypeId: relatesToRelationType.value.id
  });
  await store.dispatch('board/fetchIndicatorsRelations');
}
const handleChange = (name, value) => {
  store.dispatch('board/updateDatasetElementAttributes', {
    datasetElementId: datasetElementId.value,
    attributes: {
      ...datasetElement.value.attributes,
      [name]: value
    }
  });
};

function getRelatedDatasetElementValue(relatedDatasetElement) {
  if (
    relatedDatasetElement.attributes['friendly-id'] &&
        relatedDatasetElement.title
  ) {
    return `${relatedDatasetElement.attributes['friendly-id']}     ${relatedDatasetElement.title}`;
  }
  if (!relatedDatasetElement.attributes['friendly-id']) {
    return `${relatedDatasetElement.title}`;
  }
  return `${relatedDatasetElement.attributes['friendly-id']}`;
}

</script>

<style lang="scss" scoped>
.BoardSettings {
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  z-index: 1000;
  background: #FFF;
  width: 480px;
  padding: 24px;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
            padding: 8px 0;
            margin-right: auto;
            color: var(--gray-900);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: -0.16px;
        }
    }

    &-content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &-footer {
        &-buttons {
            padding: 32px;
        }
    }
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 30px;
    color: var(--gray-900, #242230);
    /* text-s/font-semibold */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */

    &-label {
        &-characterCount {
            float: right;
            color: #B1AEC6;
            text-align: right;
            /* text-xs/font-regular */
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 166.667% */
            letter-spacing: 0.14px;
        }
    }
    &-helper-text {
        color: rgba(145, 145, 145, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
  }
}
</style>
