<template>
  <button class="IobElevationButton level-1 elevation-on-hover level-container focus-outside">
    <iob-icon-shape 
      :icon-name="iconName"
      :color="iconColor"
      :kanji-image="kanjiImage"
      :is-clickable="false"
    />
    <div
      v-if="label"
      class="IobElevationButton-text"
    >
      {{ label }}
    </div>
  </button>
</template>
  
<script setup>
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';

defineProps({
  label: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: 'Hand'
  },
  iconColor: {
    type: String,
    default: 'primary'
  },
  kanjiImage: {
    type: String,
    default: '',
    validator: (value) => {
      return ['01', '02', '03', '04'].includes(value);
    }
  }
});
  
</script>

<style lang="scss" scoped>
.IobElevationButton {
  display: flex;
  padding: var(--elevation-button-padding-vertical, 12px) var(--elevation-button-padding-horizontal, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--elevation-button-gap-default, 8px);
  align-self: stretch;
  width: 100%;
    
  border-radius: var(--elevation-button-radius-default, 8px);
  background: var(--elevation-color-background, #FFF);

  &:hover, &:focus { 
    cursor: pointer;
  }

  &-text {
    color: var(--elevation-button-color-text, #787391);
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.14px;
}
}
</style>
